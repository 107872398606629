:root {
    --light-white: #fff;
    --dark-black: #121212;
    --brand-primary-500: #F37020;
    --light-text: rgba(255, 255, 255, 0.45);
    --playfair-font: 'Playfair Display', serif;
    --inter-font: 'Inter', sans-serif;
}

/** reset styles **/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    outline: none;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;  
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

body, html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  box-sizing: border-box;
}  

/** font face include **/
@font-face {
    font-family: 'neue_kabelbook';
    src: url('./fonts/neuekabel-book-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelextrabold';
    src: url('./fonts/neuekabel-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelextralight';
    src: url('./fonts/neuekabel-extralight-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabellight';
    src: url('./fonts/neuekabel-light-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelmedium';
    src: url('./fonts/neuekabel-medium-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelthin';
    src: url('./fonts/neuekabel-thin-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelregular';
    src: url('./fonts/neuekabel-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelblack';
    src: url('./fonts/neuekabel-black-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_kabelbold';
    src: url('./fonts/neuekabel-bold-webfont.woff2') format('woff2'),
         url('./fonts/neuekabel-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }  

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');

body {margin: 0;font-size: 14px;font-family: 'Manrope', sans-serif;background: #050505;color: #132335;}

/* width */
::-webkit-scrollbar {width: 10px;}
  
/* Track */
::-webkit-scrollbar-track {background: #f1f1f1;}
  
/* Handle */
::-webkit-scrollbar-thumb {background: #888;}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {background: #555;}

.full_width{width: 100%;float: left;}

.float-left{float: left!important;}

.float-right{float: right!important;}

.text-left{text-align: left!important;}

.text-right{text-align: right!important;}

.text-gradient{background: linear-gradient(135deg, #FFF 9.61%, rgba(255, 255, 255, 0.55) 100%);background-clip: text;
    -webkit-background-clip: text;-webkit-text-fill-color: transparent;}

/* start of home page styles */

.header{height: 104px;padding: 24px 0px;width: 100%;position: absolute;top: 0px;left: 0px;z-index: 5;background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(6px);-webkit-animation: slideInDown 0.7s linear 1 normal forwards;
    animation: slideInDown 0.7s linear 1 normal forwards;transition: 1.2s opacity linear;opacity: 1;}

@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -104px, 0);
        transform: translate3d(0, -104px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -104px, 0);
        transform: translate3d(0, -104px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translate3d(0, 0, 0);
    }
}

.header .logo img{margin-left: -10px;}

.menu{float: right;display: flex;height: 100%;align-items: center;padding: 0px;position: relative;left: 15px;}

.menu ul{list-style: none;float: left;}

.menu ul li{float: left;margin-left: 32px;}

.menu ul li a{font-size: 16px;line-height: 24px;color: #fff;font-family: 'neue_kabelregular';text-decoration: none;
    transition: .3s all ease-in;}

.menu ul li a:hover{border-radius: 8px;background: rgba(255, 255, 255, 0.10);}

.menu ul li a.active{border-radius: 8px;background: rgba(255, 255, 255, 0.10);}

.menu.navbar-light .navbar-nav .nav-link{color: var(--light-white, #FFF);font-size: 16px;font-weight: 600;
    line-height: 24px;text-decoration: none;transition: .3s all ease-in;margin-left: 8px;padding: 8px 16px;
    border: 1px solid transparent;}

.menu.navbar-light .navbar-nav .nav-link:hover{border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.15);}

.menu.navbar-light .navbar-nav .nav-link.active{border-radius: 8px;background: rgba(255, 255, 255, 0.10);
    border: 1px solid rgba(255, 255, 255, 0.10);}

.member_link_wrap{float: left;}

.member_link_wrap a{width: 138px;height: 56px;background: var(--brand-primary-500);color: #F4F3E8;margin-right: 16px;border: 1px solid var(--brand-primary-500);
    float: left;text-align: center;line-height: 56px;text-decoration: none;transition: .3s all ease-in;border-radius: 8px;
    font-size: 16px;font-weight: 600;}

.member_link_wrap a:hover{background: #fff;color: var(--brand-primary-500);border-color: var(--brand-primary-500);}

.member_link_wrap a.login_link{background: none;border: 1px solid var(--light-white);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.member_link_wrap a.login_link:hover{border: 1px solid var(--brand-primary-500);}

.header_row{position: relative;}

.ripple_img{width: 1526px;position: absolute;left: 50%;top: -20px;transform: translateX(-50%);}

.ripple_img img{width: 100%;}

.header_row .blur_circle{width: 600px;height: 600px;border-radius: 1000px;background: var(--brand-primary-500);
        filter: blur(175px);position: absolute;left: 50%;top: 0px;margin-top: -300px;
        -webkit-transform-style: preserve-3d;-webkit-backface-visibility: hidden;-webkit-transform: translate3d(-50%, 0, 0);}

.circle-ripple {width: 600px;height: 600px;border-radius: 50%;background: rgba(243, 112, 32, 0.4);
    position: absolute;left: 50%;
    animation: ripple 5s linear infinite;
    transform: translate(-50%,-50%);
        

}
::-webkit-full-page-media, :future, :root .circle-ripple {
    animation: ripple 1s linear infinite;
   -webkit-animation: ripple 1s linear infinite;
 }

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
    0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1),
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1);
    -webkit-box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
    0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1),
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1), 
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1),
    0 0 0 1192px rgba(243, 112, 32, 0);
    -webkit-box-shadow:  0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1), 
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1),
    0 0 0 1192px rgba(243, 112, 32, 0);
    }
    }

    @-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
    0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1),
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1);
    -webkit-box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
    0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1),
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1), 
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1),
    0 0 0 1192px rgba(243, 112, 32, 0);
    -webkit-box-shadow:  0 0 0 120px rgba(243, 112, 32, 0.1), 
    0 0 0 264px rgba(243, 112, 32, 0.1), 
    0 0 0 436px rgba(243, 112, 32, 0.1), 
    0 0 0 644px rgba(243, 112, 32, 0.1),
    0 0 0 892px rgba(243, 112, 32, 0.1),
    0 0 0 1192px rgba(243, 112, 32, 0);
    }
    }

/* @-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1),
        0 0 0 1192px rgba(243, 112, 32, 0);
    }
}

@-moz-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1),
        0 0 0 1192px rgba(243, 112, 32, 0);
    }
}
@-ms-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1),
        0 0 0 1192px rgba(243, 112, 32, 0);
    }
}

@-o-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1),
        0 0 0 1192px rgba(243, 112, 32, 0);
    }
}

@-o-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0.1),
        0 0 0 1192px rgba(243, 112, 32, 0);
    }
} */

.banner_container{position: relative;z-index: 2;width: 100%;}

.banner_content{width: 100%;float: left;text-align: center;padding-top: 152px;position: relative;z-index: 3;padding-bottom: 58px;
    -webkit-animation: textBlur 1.5s linear 1 normal forwards;animation: textBlur 1.5s linear 1 normal forwards;
    transition: 1.2s opacity linear;opacity: 1;}

@-webkit-keyframes textBlur {
    0% {
        opacity: 0;
        filter: blur(20px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes textBlur {
    0% {
        opacity: 0;
        filter: blur(20px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}

.banner_content h1{margin-bottom: 16px;font-family: var(--playfair-font);font-size: 60px;
    font-weight: 400;line-height: 72px;background: linear-gradient(135deg, #FFF 9.61%, rgba(255, 255, 255, 0.55) 100%);
    background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}

.banner_content p{color: var(--light-text);text-align: center;font-size: 20px;font-weight: 400;line-height: 30px;
max-width: 860px;margin: 0px auto;}

.memberships_links_wrapper{width: 424px;margin: 24px auto 0px auto;}

.memberships_links_wrapper ul{width: 100%;list-style: none;display: flex;gap: 20px;}

.memberships_links_wrapper ul li{width: 50%;}

.memberships_links_wrapper ul li a{padding: 6px 12px;border-bottom: 1px solid rgba(244, 243, 232, 0.16);
    background: none;height: 48px;    display: flex;
    align-items: center;justify-content: center;color: #F4F3E8;text-align: center;font-size: 16px;
    font-weight: 500;text-decoration: none;margin-bottom: 12px;pointer-events: none;}

.memberships_links_wrapper ul li span{color: #F37020;font-size: 20px;line-height: 30px;}

.memberships_links_wrapper ul li small{color: var(--light-white);font-size: 14px;font-weight: 400;line-height: 20px;
margin-left: 3px;}

.banner_links{padding-top: 24px;}

.banner_links a{text-decoration: none;font-size: 16px;font-weight: normal;display: inline-block;
    text-align: center;line-height: 44px;transition: .3s all ease-in;}

.banner_links .member_link{width: 153px;height: 56px;background: var(--brand-primary-500);color: var(--light-white);
    border: 1px solid var(--brand-primary-500);text-align: center;line-height: 56px;text-decoration: none;transition: .3s all ease-in;
    border-radius: 8px;font-size: 16px;font-weight: 600;}

.banner_links .member_link:hover{background: #fff;color: #F37020;border-color: #F37020;}

.banner_links .learn_link{width: 113px;height: 44px;background:none;color: #E7DCC9;border: 1px solid #E7DCC9;}

.banner_links .learn_link:hover{background: #fff;color: #F37020;border-color: #F37020;}

.banner_image{width: 100%;padding-bottom: 140px;}

.banner_image img{width: 100%;height: 100%;object-fit: contain;}

.header_wrapper{padding: 24px 0px;}

.pagenot_found{padding-top: 90px;}

.pagenot_found h2{color: rgba(255, 255, 255, 0.95);font-size: 128px;font-weight: 400;line-height: 90px;letter-spacing: -2.56px;
    font-family: var(--playfair-font);margin-bottom: 60px;}

.pagenot_found h3{color: #FFF;text-align: center;font-family: var(--playfair-font);font-size: 46px;font-weight: 400;
    line-height: normal;margin-bottom: 12px;}

.pagenot_found p{color: #B0B0B0;text-align: center;font-size: 21px;font-weight: 400;line-height: normal;}

.video_container{padding-bottom: 60px;}

.video_container h3{font-size: 48px;font-weight: 400;line-height: 60px;font-family: var(--playfair-font);
margin-bottom: 48px;}

.video_embed_container{width: 1010px;margin: 0px auto;position: relative;z-index: 2;}

.youtube_player{width: 640px;margin: 0px auto;}

.video_embed_container iframe {width: 100%;}

.video_embed_container video{width: 100%;height: 100%;object-fit: cover; cursor: pointer;}

.video_preview_img{width: 100%;float: left;position: relative;}

.video_preview_img img{width: 100%;object-fit: cover;}

.video_embed_container .iframe_container{width: 740px;margin: 0px auto;}

.video_preview_img button{padding: 0px;position: absolute;top: 50%;left: 50%;border: none;
    background: linear-gradient(0deg, rgba(33, 33, 33, 0.15), rgba(33, 33, 33, 0.15)), linear-gradient(158.72deg, rgba(255, 255, 255, 0.16) 3.01%, rgba(255, 255, 255, 0) 103.3%);
    backdrop-filter: blur(23.1079px);border-radius: 15px;transform: translate(-50%, -50%);}

.our_horses_container{position: relative;z-index: 1;margin-bottom: 30px;}

.our_horses_carousel .carousel-button-group{display: flex;align-items: center;justify-content: center;}

.our_horses_carousel .carousel-button-group button{background: #121212;padding: 5px;border: none;border-radius: 50%;
    width: 36px;height: 36px;margin-right: 12px;}

.our_horses_content .heading h3{text-align: center;font-size: 48px;line-height: 60px;font-family: var(--playfair-font);
    margin-bottom: 24px;}

.our_horses_carousel .horse_item{width: 100%;float: left;height: 494px;perspective: 1400px;}

.horse_item_wrapper{position: absolute;width: 100%;height: 100%;transition: transform 1s;transform-style: preserve-3d;
    border-radius: 16px;background: #121212;}

.horse_item:hover .horse_item_wrapper {transform: rotateY( 180deg );transition: transform 1s;}

.front,.back {position: absolute;height: 100%;width: 100%;background: #121212;border-radius: 16px;
    backface-visibility: hidden;}

.back {transform: rotateY( 180deg );}

.horse_item_container .img_wrapper{background: #2A2A2A;border-radius: 16px 16px 0px 0px;overflow: hidden;}

.horse_item_container.front .img_wrapper{height: 372px;}

.horse_item_container.back .img_wrapper{height: 223px;}

.horse_item_container .img_wrapper img{width: 100%;float: left;height: 100%;object-fit: cover;}

.carousel-item-padding-40-px{padding:26px 12px 36px 12px;}

.our_horses_carousel .react-multiple-carousel__arrow--left{left: 0px;}

.our_horses_carousel .react-multiple-carousel__arrow--right{right: 0px;}

.our_horses_carousel .react-multiple-carousel__arrow{top: 210px;}

.horse_item_container{width: 100%;float: left;border-radius: 16px;background: #121212;overflow: hidden;
    transition: .3s all ease-in;}

.our_horses_carousel .horse_item .img_wrapper img{width: 100%;float: left;height: 100%;object-fit: cover;object-position: 30% 50%;}

.horse_item_content{padding: 16px;}

.horse_item_info h5{color: var(--light-white);font-size: 20px;font-weight: 500;line-height: 30px;margin-bottom: 7px;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.horse_item_info .horse_age_info{float: left;}

.horse_item_info .horse_age_info small{color: rgba(255, 255, 255, 0.45);font-size: 14px;font-weight: 700;
    line-height: 20px;float: left;margin-right: 5px;}

.horse_item_info .horse_age_info strong{float: left;color: var(--light-white);font-size: 14px;font-weight: 500;line-height: 20px;}

.horse_item_info .horse_gender_info{float: right;display: flex;align-items: center;}

.horse_item_info .horse_gender_info img{float: left;margin-right: 4px;}

.horse_item_info .horse_gender_info span{float: left;color: var(--light-white);font-family: var(--inter-font);font-size: 14px;
    font-weight: 500;line-height: 20px;}

.horse_loc_info{margin-top: 12px;margin-bottom: 12px;}

.horse_loc_info img{float: left;height: 16px;margin-right: 6px;}

.horse_loc_info span{float: left;color: var(--light-white);font-family: var(--inter-font);font-size: 14px;
    font-weight: 500;line-height: 16px;}

.horse_tr_sr_info ul{list-style: none;}

.horse_tr_sr_info ul li{float: left;}

.horse_tr_sr_info ul li span{width: 100%;float: left;color: var(--light-text);font-size: 12px;font-weight: 700;line-height: 18px;}

.horse_tr_sr_info ul li strong{width: 100%;float: left;color: var(--light-white);font-size: 14px;font-weight: 500;line-height: 20px;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.horse_tr_sr_info ul li.hr_tr_info{width: 130px;border-right: 1px solid rgba(255, 255, 255, 0.25);margin-right: 10px;}

.horse_tr_sr_info ul li.hr_sr_dm_info{width: calc(100% - 140px);}

.horse_history_info{margin-top: 12px;}

.horse_history_info ul{padding: 8px 16px;border-radius: 8px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);list-style: none;}

.horse_history_info ul li{width: 33.3%;float: left;border-right: 1px solid rgba(255, 255, 255, 0.15);text-align: center;}

.horse_history_info ul li:last-child{border-right: none;}

.horse_history_info ul li span{width: 100%;float: left;color: var(--light-text);font-family: var(--inter-font);
    font-size: 14px;font-weight: 400;line-height: 20px;}

.horse_history_info ul li strong{width: 100%;float: left;color: var(--light-white);font-family: var(--inter-font);
    font-size: 36px;font-weight: 400;line-height: 44px;}

.regular_events_content .heading h3{text-align: center;font-size: 40px;line-height: 60px;font-family: var(--playfair-font);
    margin-bottom: 40px;}

.regular_events_wrapper{background: #141414;border-radius: 16px;padding: 48px;}

.schedule_time_wrapper.regular_events_calendar{height: auto;min-height: 420px;padding-bottom: 30px;}

.events_calendar_content{position: relative;}

.regular_events_wrapper .view_events{width: calc(100% - 30px);height: 56px;background: var(--brand-primary-500);color: var(--light-white);
font-size: 14px;text-decoration: none;font-weight: 600;float: left;line-height: 56px;border-radius: 8px;text-align: center;
position: absolute;left: 15px;bottom: 0px;}

.monthly_events_wrapper{max-height: 464px;overflow: auto;}

.monlthy_events_list{margin-bottom: 30px;}

.monlthy_events_list:last-child{margin-bottom: 0px;}

.monlthy_events_list h5{color: var(--light-white);font-size: 14px;font-weight: 600;line-height: 20px;margin-bottom: 8px;}

.monthly_events_content{border-radius: 8px;background: rgba(255, 255, 255, 0.05);overflow: hidden;}

.monthly_events_content .img_wrapper{width: 124px;height: 106px;float: left;}

.monthly_events_content .img_wrapper img{width: 100%;height: 100%;object-fit: cover;}

.monthly_events_content .text_content{float: left;padding: 12px;width: calc(100% - 124px);}

.monthly_events_content .text_content h6{font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;}

.monthly_events_content .text_content p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);
    -webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;max-width: 100%;height: 50px;overflow: hidden;}

.types_of_membership_container{position: relative;margin-bottom: 140px;margin-top: -10px;}

.pricing_types_membership_container{margin-top: 48px;margin-bottom: 0px;}

.pricing_types_membership_container .container{padding: 0px;}

.types_of_membership_content .heading h4{color: var(--light-white);text-align: center;font-family: var(--playfair-font);
    font-size: 48px;font-weight: 400;line-height: 60px;margin-bottom: 40px;}

.membership_tabs_content .nav-tabs{border-bottom: none;border-radius: 12px;border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.02);display: inline-block;position: relative;left: 50%;transform: translateX(-50%);
padding: 4px;margin-bottom: 40px;}

.membership_tabs_content .nav-tabs .nav-item{display: inline-block;}

.membership_tabs_content .nav-tabs .nav-item button{background: none;border: none;display: flex;padding: 8px 0px;
    justify-content: center;align-items: center;gap: 10px;width: 192px;color: var(--light-text);font-size: 16px;
    font-weight: 400;line-height: 24px;}

.membership_tabs_content .nav-tabs .nav-item .nav-link.active{border-radius: 8px;background: var(--brand-primary-500);color: #fff;}

.member_tab_left_content{padding: 28px 0px 0px 0px;position: relative;height: 100%;}

.member_tab_left_content h5{color: #FFF;font-size: 24px;line-height: 30px;letter-spacing: 4.8px;margin-bottom: 28px;}

.member_tab_left_content p{padding: 24px 0px 20px 0px;border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);position: relative;}

.member_tab_left_content p.no_br_tp{border-top: none;}

.member_tab_left_content p span{padding: 0px 8px;border-radius: 100px;border: 1px solid rgba(255, 255, 255, 0.15);
        background: #F37020;color: var(--light-white);font-family: var(--inter-font);font-size: 10px;font-weight: 400;line-height: 18px;
        position: absolute;right: 0px;top: 6px;height: 18px;}

.member_tab_left_content p.discount_price{border-top: none;position: relative;}

.member_tab_left_content p.discount_price strong s{font-weight: 400;margin-right: 10px;}

.member_tab_left_content p.discount_price span{padding: 0px 8px;border-radius: 100px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: #F37020;color: var(--light-white);font-family: var(--inter-font);font-size: 10px;font-weight: 400;line-height: 18px;
    position: absolute;right: 0px;top: 16px;height: 18px;}

.member_tab_left_content p strong{color: var(--brand-primary-500);font-family: var(--inter-font);font-size: 24px;
    font-weight: 600;line-height: 28px;letter-spacing: -0.6px;}

.member_tab_left_content p strong b{font-weight: 700;}

.member_tab_left_content p small{font-size: 12px;line-height: 18px;color: var(--brand-primary-500);margin-right: 10px;}

.member_tab_left_content a{padding: 16px 48px;display: flex;border-radius: 8px;background: var(--brand-primary-500);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: var(--light-white);font-size: 16px;font-weight: 600;
    line-height: 24px;justify-content: center;text-decoration: none;width: 154px;position: absolute;left: 0px;bottom: 30px;}

.membership_tabs_content{border-radius: 16px;background: #141414;position: relative;padding: 48px;}

.member_tab_right_content{padding: 28px 0px 8px 40px;}

.member_tab_right_content .stable_list{margin-left: -13px;margin-right: -13px;}

.member_tab_right_content .stable_list .col-6{padding-left: 13px;padding-right: 13px;}

.member_tab_right_content h6{color: var(--light-white);font-size: 16px;font-weight: 600;line-height: 22px; letter-spacing: 1.92px;
    text-transform: capitalize;margin-bottom: 26px;}

.member_tab_right_content ul{margin-left: 16px;}

.member_tab_right_content ul li{color: #FFF;font-size: 16px;font-weight: 400;line-height: 22px;margin-bottom: 12px;}

.member_tab_right_content ul li b{font-weight: 700;}

.fractional_ownership{margin: 26px 0px 26px 0px;}

.fractional_ownership h4{color: var(--light-white);font-size: 16px;font-weight: 600;line-height: 21px;letter-spacing: 1.92px;
    text-transform: capitalize;}

.types_of_membership_text{padding-right: 0px;}

.types_of_membership_text .heading h3{font-size: 44px;font-weight: 400;line-height: 56px;font-family: var(--playfair-font);
margin-bottom: 44px;}

.types_of_membership_text p{color: #FFF;font-size: 20px;line-height: 30px;width: 425px;}

.types_of_membership_text a{display: inline-flex;padding: 16px 48px;justify-content: center;align-items: center;
    border-radius: 8px;background: var(--brand-primary-500);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--light-white);font-size: 16px;font-weight: 600;line-height: 24px;margin-top: 50px;text-decoration: none;
transition: .3s all ease-in;}

.types_of_membership_text a:hover{background: #fff;color: var(--brand-primary-500);border-color: var(--brand-primary-500);}

.membership_card_info{padding: 30px 24px;border-radius: 12.101px;border: 0.756px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.05);cursor: pointer;transition: .3s all ease-in;}

.membership_card_info:hover{border-radius: 16px;border: 1px solid rgba(243, 112, 32, 0.25);background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 10px 4px #F37020;}

.membership_card_info h6{color: var(--light-white);font-family: var(--inter-font);font-size: 11px;font-weight: 300;
    line-height: 17px; letter-spacing: 1.3px;text-transform: capitalize;}

.membership_card_info h6.text-orange{font-size: 16px;line-height: 24px;font-weight: 600;color: var(--brand-primary-500);}

.membership_card_info p b{color: var(--light-white);font-family: var(--inter-font);font-size: 26px;
    font-weight: 500;line-height: 33px;}

.membership_card_info p small{color: var(--light-white);font-size: 10px;font-weight: 400;line-height: 14px;}

.membership_card_info ul{margin-top: 10px;margin-left: 16px;}

.membership_card_info ul li{color: #FFF;font-size: 16px;font-weight: 400;line-height: 24px;}

.partner_content h2{margin-bottom: 24px;font-family: var(--playfair-font);font-size: 24px;
    font-weight: 500;line-height: 32px;background: linear-gradient(135deg, #FFF 9.61%, rgba(255, 255, 255, 0.55) 100%);
    background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;text-align: center;}

.partner_slider {width: 1216px;margin: auto;overflow: visible;}

.partner_slider ul {display: flex;padding: 0;animation: cambio 40s infinite linear;-webkit-animation: cambio 40s infinite linear;}

.partner_slider li {padding:0 32px;list-style: none;display: flex;align-items: center;}

.partner_slider img {max-width: fit-content !important;height: auto !important;}

@keyframes cambio {
    from {margin-left: 0%}
    to {margin-left: -100%}
}

@-webkit-keyframes cambio {
    from {margin-left: 0%}
    to {margin-left: -100%}
}

.partner_top_logo{width: 1216px;margin: 0px auto;}

.partner_logo_container{padding-top: 70px;}

.partner_top_logo img{margin-right: 90px;}

.partner_top_logo a:last-child img{margin-right: 0px;}

.partner_bottom_logo{width: 1216px;margin: 0px auto;}

.partner_bottom_logo img{margin-right: 85px;}

.partner_bottom_logo a:last-child img{margin-right: 0px;}

.bottom_partner_container{padding-bottom: 120px;}

.bottom_partner_container .partner_top_logo{width: 1216px;display: flex;align-items: center;}

.bottom_partner_container .partner_bottom_logo{width: 1026px;}

.bottom_partner_container .partner_top_logo img{margin-right: 85px;}

.bottom_partner_container .partner_bottom_logo img{margin-right: 96px;}

.services_container{padding: 60px 0px;}

.services_container h3{font-size: 48px;line-height: 60px;font-family: var(--playfair-font);}

.services_content .heading{position: relative;margin-bottom: 48px;}

.services_wrapper{display: flex;}

.service_item{min-width: 389px;float: left;margin-right: 24px;border-radius: 16px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.03) 100%);backdrop-filter: blur(24px);
transition: .3s all ease-in;cursor: pointer;margin-bottom: 24px;}

/* .service_item:hover{border: 1px solid #F37020;background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.03) 100%);
    box-shadow: 0px 0px 15px 0px #F37020;} */

/* .service_item:hover{border: 1px solid rgba(243, 112, 32, 0.25);background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.03) 100%);
    box-shadow: 0px 0px 55px 0px #F37020;} */

.service_image{width: 100%;float: left;border-radius: 16px 16px 0px 0px;overflow: hidden;height: 292px;}

.service_image img{width: 100%;float: left;transition: .3s all ease-in;transform: scale3d(1,1,1);}

.service_item:hover .service_image img{transform: scale3d(1.1,1.1,1.1);}

.service_item_large{width: 50%;min-width: 596px;}

.service_details{width: 100%;float: left;padding: 24px;}

.service_details h5{font-size: 24px;line-height: 32px;color: var(--light-white);font-family: var(--playfair-font);
    font-weight: 400;margin-bottom: 8px;}

.service_details p{font-size: 14px;line-height: 20px;color: var(--light-text);}

.service_item_large .service_details{width: 100%;padding: 14px 24px 24px 24px;}

.service_item_large .service_image{width: 100%;height: 253px;}

.service_item_ex_large .service_image{width: 100%;height: 352px;}

.app_experience_row{position: relative;}

.app_experience_row::after{content: '';width: 1200px;height: 1200px;border-radius: 2000px;background: var(--brand-primary-500);
    filter: blur(350px);
    /* transform: translate3d(350px, 350px, 350px); 
    -webkit-filter: blur(350px); */
    position: absolute;left: 50%;top: -312px;transform: translateX(-50%);}


::-webkit-full-page-media, :future, :root .app_experience_row::after {
    transform: translate3d(150px, 150px, 150px);
    left: 0% !important;
    }

.experience_ripple_img{width: 100%;position: absolute;left: 50%;top: 2740px;transform: translateX(-50%);height: 3400px;
    z-index: -1;}

.experience_ripple_img img{width: 100%;height: 100%;object-fit: cover;object-position: 50% 25%;}

.app_experience_wrapper{margin-bottom: 100px;position: relative;padding: 48px;border-radius: 16px;background: #141414;
    box-shadow: 0px 0px 160px 0px #DD631A;}

.app_experience_wrapper .right_space{padding-right: 0px;}

.app_experience_content{position: relative;z-index: 1;}

.app_experience_content .heading{position: relative;margin-bottom: 48px;}

.app_experience_content h3{text-align: center;font-size: 48px;line-height: 60px;font-family: var(--playfair-font); margin-bottom: 16px;;}

.app_experience_content .heading p{color: var(--light-text);text-align: center;font-size: 16px;line-height: 24px;width: 500px;
margin: 0px auto; }

.experience_accordion_container .accordion-item.active{background: rgba(255, 255, 255, 0.05);border-radius: 12px;
margin-bottom: 16px;}

.experience_accordion_container .accordion-header{color: var(--light-text);font-family: var(--playfair-font);
    font-size: 24px;font-weight: 400;line-height: 32px;}

.experience_accordion_container .accordion-header button{background: none;color: var(--light-text);border: none;width: 100%;
text-align: left;padding: 16px 24px;position: relative;}

.experience_accordion_container .action_icon{width: 32px;height: 32px;position: absolute;right: 16px;top: 16px;}

.experience_accordion_container .accordion-item.active .action_icon{border-radius: 8px;background: rgba(255, 255, 255, 0.05);
    align-content: center;display: flex;justify-content: center;align-items: center;float: left;text-align: center;}

.experience_accordion_container .minus_icon{display: none;}

.experience_accordion_container .accordion-button:not(.collapsed){color: var(--light-white);}

.experience_accordion_container .accordion-button:not(.collapsed) .plus_icon{display: none;}

.experience_accordion_container .accordion-button:not(.collapsed) .minus_icon{display: inline-flex;}

.experience_accordion_container .accordion-button:not(.collapsed) .minus_icon svg path{fill: var(--brand-primary-500);
fill-opacity: 1;}

.experience_accordion_container .accordion-button:not(.collapsed) .minus_icon img{
    filter: brightness(0) saturate(100%) invert(65%) sepia(42%) saturate(7015%) hue-rotate(350deg) brightness(100%) contrast(91%);
}

/* .experience_accordion_container .accordion-collapse.show{border-radius: 0px 0px 12px 12px;background: rgba(255, 255, 255, 0.05);} */

.experience_accordion_container .accordion-body{color: var(--light-text);font-size: 16px;font-weight: 400;line-height: 24px;
    padding: 0px 24px 24px 24px;}

.experience_left_content h6{font-size: 16px;line-height: 20px;color: #E7DCC9;font-family: 'neue_kabelregular';font-weight: normal;
    margin-bottom: 8px;}

.experience_left_content h4{font-size: 24px;line-height: 30px;font-weight: 600;font-family: 'neue_kabelthin';color: #F37020;
    margin-bottom: 24px;}

.experience_left_content .line_bar{width: 64px;height: 4px;background: #E7DCC9;display: block;margin-bottom: 30px;}

.experience_left_content p{color: var(--light-white);font-size: 20px;font-weight: 400;line-height: 30px;}

.experience_left_content ul{margin-left: 16px;}

.experience_left_content ul li{font-size: 16px;line-height: 24px;color: #E7DCC9;font-family: 'neue_kabelregular';font-weight: normal;}

.app_experience_container .cards_wrapper{width: 100%;float: left;padding-left: 32px;}

.app_experience_container .cards_wrapper .cards_list{width: 100%;float: left;padding:0px 0px 24px 0px;display: flex;}

.app_experience_container .cards_wrapper .cards_container{width: 100%;float: left;}

.app_experience_container .icon_wrap{display: flex;width: 40px;height: 40px;padding: 8px;justify-content: center;align-items: center;
    border-radius: 8px;background: rgba(255, 255, 255, 0.05);margin-bottom: 16px;}

.experience_left_content .cards_container p{margin: 0px;}

.app_experience_wrapper .left_space{padding-left: 350px;}

.stable_image{width: 100%;height: 100%;float: left;padding-left: 32px;}

.stable_image img{width: 100%;height: auto;}

.faq_heading{
    /* width: 610px; */
    margin-bottom: 48px;}

.faq_heading h3{font-size: 48px;font-weight: 400;line-height: 60px;font-family: var(--playfair-font);}

.faq_heading p{color: var(--light-text);font-size: 16px;font-weight: 400;line-height: 24px; }

.faq_heading p a{color: var(--brand-primary-500);}

.faq_content{margin-bottom: 100px;}

.faq_accordion .accordion-item{margin-bottom: 24px;}

.faq_accordion .accordion-header{border-radius: 8px 8px 0px 0px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.15);backdrop-filter: blur(12px);}

.faq_accordion .accordion-header button{background: none;border: none;width: 100%;text-align: left;color: var(--light-white);
    font-size: 20px;font-weight: 600;line-height: 30px;position: relative;padding: 16px 60px 16px 20px;}

.faq_accordion .accordion-button:not(.collapsed){border-radius: 8px 8px 0px 0px;border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.15);border-left: 1px solid rgba(255, 255, 255, 0.15);
    background: var(--brand-primary-500);}

.faq_accordion .action_icon{width: 40px;height: 40px;position: absolute;top: 10px;right: 20px;display: flex;align-items: center;
    justify-content: center;}

.faq_accordion .accordion-button:not(.collapsed) .action_icon{border-radius: 8px;background: rgba(255, 255, 255, 0.45);}

.faq_accordion .accordion-button:not(.collapsed) .plus_icon{display: none;}

.faq_accordion .accordion-button:not(.collapsed) .minus_icon{display: flex;}

.faq_accordion .action_icon img{width: 32px;height: 32px;}

.faq_accordion .action_icon.plus_icon svg{width: 32px;height: 32px;}

.faq_accordion .minus_icon{display: none;}

.faq_accordion .minus_icon svg path{fill-opacity: 1;}

.faq_accordion .accordion-body{padding: 16px 20px;color: var(--light-white);font-size: 18px;font-weight: 400;line-height: 28px;
    border: 1px solid rgba(255, 255, 255, 0.15);background: rgba(255, 255, 255, 0.05);}

.faq_accordion .accordion-body p{margin-bottom: 6px;}

.faq_accordion .accordion-body ul{margin-left: 16px;}

.faq_accordion .accordion-body ul li{margin-bottom: 6px;}

.faq_link{padding-top: 8px;}

.faq_link a{padding: 8px 12px;border-radius: 8px;border: 1px solid var(--brand-primary-500);color: var(--brand-primary-500);
    font-size: 20px;font-weight: 700;line-height: 30px;text-decoration: none;transition: .3s all ease-in;}

.faq_link a:hover{color: var(--light-white);border-color: var(--light-white);}

.gateway_container{background: rgba(255, 255, 255, 0.02);backdrop-filter: blur(32px);padding: 60px 0px;margin-bottom: 60px;
    border-top: 1px solid rgba(255,255,255,0.2);border-bottom: 1px solid rgba(255,255,255,0.2);}

.gateway_left_content h4{font-size: 48px;line-height: 60px;letter-spacing: -0.02em;color: #E7DCC9;font-weight: normal;
    font-family: 'neue_kabelthin';margin-bottom: 10px;}

.gateway_left_content p{font-size: 20px;line-height: 30px;font-weight: normal;color: #E7DCC9;font-family: 'neue_kabelregular';}

.gateway_right_content{float: right;}

.gateway_top_container{background: none;border-top: 1px solid rgba(244, 243, 232, 0.15);border-bottom: 1px solid rgba(244, 243, 232, 0.15);
    backdrop-filter: none;}

.footer_container{background: rgba(18, 18, 18, 0.45);padding: 48px 0px 0px 0px;border-top: 1px solid rgba(255, 255, 255, 0.15);
margin-top: 0px;}

.gateway_info h3{color: var(--light-white);font-family: var(--playfair-font);font-size: 48px;margin-bottom: 24px;
    font-weight: 400;line-height: 60px;width: 524px;}

.gateway_info a{border-radius: 8px;background: var(--brand-primary-500);backdrop-filter: blur(8px);display: flex;padding: 12px 24px;
    justify-content: center;align-items: center;color: var(--light-white);font-size: 16px;font-weight: 600;line-height: 24px;
width: 105px;height: 48px;text-decoration: none;transition: .3s all ease-in;}

.gateway_info a:hover{background: #fff;color: var(--brand-primary-500);border-color: var(--brand-primary-500);}

.footer_links h6{color: var(--light-white);font-size: 16px;font-weight: 600;line-height: 24px;margin-bottom: 32px;
    font-family: var(--inter-font);}

.footer_links a{color: rgba(255, 255, 255, 0.45);font-size: 16px;font-weight: 400;line-height: 24px;display: flex;
    text-decoration: none;font-family: var(--inter-font);}

.footer_links a.nolink{pointer-events: none;}

.footer_links a img{margin-right: 5px;}

.social_links{list-style: none;margin-top: 16px;display: flex;gap: 16px;}

.innerpages_links{list-style: none;}

.innerpages_links li{margin-bottom: 16px;}

.footer_top_container ul{list-style: none;display: none;margin-top: 30px;}

.footer_top_container ul li{float: left;margin-right: 32px;}

.footer_top_container ul li a{font-size: 16px;line-height: 24px;color: #E7DCC9;font-weight: normal;font-family: 'neue_kabelbold';
    text-decoration: none;pointer-events: none;cursor: default;}

.footer_bottom_container{margin: 32px 0px 30px 0px;border-top: 1px solid rgba(255, 255, 255, 0.15);padding-top: 30px;}

.footer_bottom_container span{float: left;color: var(--light-white);font-size: 16px;font-weight: 400;line-height: 40px;}

.footer_bottom_container a{float: right;}

.footer_bottom_container a img{width: 227px;}

.footer_bottom_container ul{float: right;list-style: none;display: none;}

.footer_bottom_container ul li{float: left;margin-right: 24px;}

.content_container{margin-top: 92px;}

.about_content_container{ overflow: hidden;}

.about_container{width: 100%;position: relative;}

.about_top_ripple{width: 100%;height: 2984px;position: absolute;left: 50%;transform: translateX(-50%);top: -92px;}

.about_top_ripple img{width: 100%;height: 100%;object-fit: cover;object-position: 0% 0%;}

.about_top_blur{width: 1200px;height: 1200px;border-radius: 2000px;opacity: 0.75;background: var(--brand-primary-500);
    filter: blur(350px);position: absolute;right: 0px;top: 550px;transform: translateX(600px);}

.about_gradient_img{width: 1080px;height: 1080px;position: absolute;right: 0px;top: 680px;transform: translateX(540px);}

.about_header{padding: 60px 0px;}

.about_header h2{font-family: var(--playfair-font);margin-bottom: 16px;font-size: 60px;font-weight: 400;
    line-height: 72px;}

.about_header p{color: var(--light-text);text-align: center;font-size: 20px;font-weight: 400;line-height: 30px;
    width: 596px;margin: 0px auto;}

.about_video_container{width: 100%;float: left;margin-bottom: 180px;}

.about_video_container video{width: 100%;height: 100%;border-radius: 16px;}

.about_youtube_player{width: 640px;margin: 0px auto;}

.about_content{padding-bottom: 140px;}

.about_left_content h5{color: var(--light-white);font-size: 20px;font-weight: 700;line-height: 30px;margin-bottom: 12px;}

.about_left_content h4{font-family: var(--playfair-font);margin-bottom: 16px;font-size: 48px;font-weight: 400;
    line-height: 60px;}

.about_right_content{padding-top: 25px;padding-right: 0px;}

.about_right_content p{color: var(--light-white);font-size: 20px;font-weight: 400;line-height: 30px;margin-bottom: 30px;}

.about_right_content .about_text_info{position: relative;margin-bottom: 50px;}

.about_right_content .about_text_info::after{content: '';width: 154px;height: 1px;background: #E7DCC9;position: absolute;
    left: 0px;bottom: -30px;}

.team_content{padding-bottom: 60px;}

.team_content .row{margin: 0px -24px;}

.team_content .col-lg-6,.team_content .col-md-6{padding-right: 24px;padding-left: 24px;}

.team_content h3{text-align: center;font-family: var(--playfair-font);font-size: 48px;font-weight: 400;line-height: 60px;
    margin-bottom: 64px;}

.team_list{width: 100%;float: left;padding-bottom: 48px;}

.team_list:hover .team_img img{transform: scale(1.05);}

.team_img{width: 100%;float: left;margin-bottom: 20px;height: 403px;overflow: hidden;border-radius: 8px;background: #2A2A2A;}

.team_img img{width: 100%;border-radius: 8px;height: auto;transition: .3s all ease-in;transform: scale(1);}

.team_info{width: 100%;float: left;}

.team_info h5{margin-bottom: 5px;color: var(--brand-primary-500);font-family: var(--playfair-font);font-size: 30px;font-weight: 400;
    line-height: 38px;}

.team_info h6{margin-bottom: 16px;color: var(--light-white);font-size: 20px;font-weight: 600;line-height: 30px;}

.team_info p{color: var(--light-text);font-size: 20px;font-weight: 400;line-height: 30px;}

.team_social_links{display: none;}

.team_info a{margin-left: 15px;display: inline-block;}

.about_bottom_ripple{width: 100%;height: 900px;position: absolute;left: 50%;transform: translateX(-50%);bottom: -439px;
z-index: -1;}

.about_bottom_ripple img{width: 100%;height: 100%;object-fit: cover;}

.about_bottom_blur{width: 400px;height: 400px;border-radius: 1000px;background: var(--brand-primary-500, #F37020);
    filter: blur(175px);position: absolute;left: 50%;transform: translateX(-50%);bottom: -440px;}

.contact_banner_container{padding-bottom: 60px;}

.contact_banner_container img{width: 100%;height: auto;}

.contact_content{padding-bottom: 60px;}

.contact_ripple{width: 100%;height: 1770px;position: absolute;left: 50%;top: 545px;transform: translateX(-88%);
z-index: -1;}

.contact_blur{width: 600px;height: 600px;border-radius: 1000px;opacity: 0.75;background: var(--brand-primary-500);
    position: absolute;right: 90%;transform: translateX(-180%);top: 1130px;
    filter:  blur(175px); 
    transform: translate3d(175px, 175px, 175px); 
    -webkit-filter: blur(175px);
    /* -webkit-backdrop-filter: blur(175px); 
    backdrop-filter: blur(175px);  */
}

.contact_ripple img{width: 100%;height: 100%;object-fit: cover;}

.contact_container .contact_content{margin: 0px -16px;}

.contact_container .contact_card_wrap{padding: 0px 16px;}

.contact_card{padding: 24px;border: 1px solid transparent;border-radius: 16px;background: #141414;backdrop-filter: blur(12px);
    transition: .3s all ease-in;}

.contact_card:hover{    border: 1px solid var(--brand-primary-500);box-shadow: 0px 0px 10px 0px #F37020;}

.contact_card .icon_wrap{margin-bottom: 60px;}

.contact_card h5{color: var(--light-white);font-family: var(--playfair-font);font-size: 24px;font-weight: 400;
    line-height: 32px;margin-bottom: 8px;}

.contact_card p{font-size: 16px;line-height: 24px;font-weight: 400;color: #F4F3E8;margin-bottom: 20px;font-family: var(--inter-font);}

.contact_card a,.contact_card span{color: var(--light-white);font-family: var(--inter-font);font-size: 16px;font-weight: 600;
    line-height: 24px;text-decoration: none;}

.contact_container .faq_content{margin-bottom: 40px;}

.founder_heading h3{font-family: var(--playfair-font);font-size: 36px;font-weight: 400;line-height: 44px;margin-bottom: 24px;}

.founder_info{margin-bottom: 24px;}

.founder_info .card_container{display: flex;padding: 24px;flex-direction: column;border-radius: 16px;background: #141414;
border: 1px solid transparent;transition: .3s all ease-in;}

.founder_info .card_container:hover{border: 1px solid var(--brand-primary-500);box-shadow: 0px 0px 10px 0px #F37020;}

.founder_info .card_container h3{font-family: var(--playfair-font);font-size: 30px;font-weight: 500;line-height: 38px;margin-bottom: 24px;}

.founder_info .card_container h5{color: var(--light-white);font-family: var(--playfair-font);font-size: 24px;
    font-weight: 400;line-height: 32px;margin-bottom: 16px;}

.founder_info .card_container p{color: var(--light-text);font-size: 20px;font-weight: 400;line-height: 30px;
    margin-bottom: 16px;border-bottom: 1px solid rgba(255, 255, 255, 0.10);padding-bottom: 16px;}

.founder_info .card_container h6{color: var(--light-white);font-size: 20px;font-weight: 600;line-height: 30px;margin-bottom: 4px;}

.founder_info .card_container span{color: var(--light-text);font-size: 20px;font-weight: 400;line-height: 30px;margin-bottom: 16px;}

.founder_info .card_container a{color: var(--light-text);font-size: 20px;font-weight: 400;line-height: 30px;text-decoration: none;}

/* end of home page styles */

/* start of terms and conditions styles */

.terms_conditions_header{padding-bottom: 24px;}

.terms_conditions_container{padding: 0px 0px 60px 0px;background: none;border-radius: 0px;}

.terms_conditions_content iframe{width: 820px;height: 900px;margin: 0px auto;display: flex;}

/* end of terms and conditions styles */

/** regsiter page styles start **/

.member_wrapper{padding-top: 60px;padding-bottom: 60px;}

.member_image img{width: 100%;height: auto;}

.member_right_container{position: relative;top: 50%;transform: translateY(-50%);}

.member_right_container h4{font-size: 48px;line-height: 60px;letter-spacing: -0.02em;color: #E7DCC9;font-weight: normal;
    font-family: 'neue_kabelthin';margin-bottom: 40px;}

.member_right_container .alert{margin-bottom: 30px;background-color: #ff0909;border-color: #f3a677;color: #fff;}

.form_field_wrapper .half_width{width: 50%;float: left;}

.pr-32{padding-right: 32px;}

.login_wrapper{padding: 64px 128px 0px 128px;}

.login_left_container{width: calc(100% - 400px);float: left;}

.login_logo{float: left;margin-bottom: 30px;}

.left_banner_container img{width: 100%;float: left;}

.login_right_container{width: 300px;float: right;margin-top: 95px;}

.login_form_container{width: 100%;float: left;}

.login_form_container h6{font-size: 16px;line-height: 20px;color: #E7DCC9;font-family: 'neue_kabelbold';
    margin: 24px 0px 20px 0px;}

.form_field{margin-bottom: 24px;position: relative;}

.form_field label{width: 100%;float: left;font-size: 14px;line-height: 21px;font-family: 'neue_kabelregular';
    font-weight: 400;color: #E7DCC9;margin-bottom: 5px;}

.form_field input{width: 100%;float: left;height: 44px;padding: 12px 16px;font-size: 16px;color: #fff;
    font-family: 'neue_kabelregular';font-weight: 400;transition: .3s border ease-in;
    background: linear-gradient(180deg, #101419 0%, #131820 100%);border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 2px;}

.form_field input:focus{border: 1px solid #fff;outline: none;}

.form_field input.error_input{border: 1px solid #E11D48;}

.error_field{font-size: 11px;color: #E11D48;position: absolute;width: 100%;right: 0px;bottom: -18px;text-align: right;}

.first_name_field{right: 33px;}

.spinner-border-sm.hide{display: none;}

.spinner-border-sm.show{display: inline-block;}

.buttons_wrapper a.next-btn{line-height: 36px;}

.buttons_wrapper .next-btn{background: #F37020;border-radius: 2px;width: 100%;height: 44px;color: #fff;font-size: 16px;
    transition: .3s all ease-in;font-family: 'neue_kabelregular';font-weight: 400;}

.buttons_wrapper .next-btn:focus{box-shadow: none;background: #eb5e08;}

.login_right_container .alert{font-size: 12px;line-height: 16px;font-weight: 500;padding: 6px 12px;}

.login_right_container .alert .next-btn{color: #0c13f1;font-size: 12px;padding: 0px;border: none;border-radius: 0px;
    outline: none;box-shadow: none;}

.login_right_container .alert-success{background-color: #96e9aa;border-color: #155724;color: #155724;}

.login_right_container .alert-danger{color: #f31415;background-color: #f5adb3;border-color: #f31415;}

.login_right_container .alert .external_link{color: #1022a6;}

.link_wrapper{padding: 7px 0px;}

.link_wrapper a{text-decoration: none;font-weight: 500;color: #F37020;}

.link_wrapper a:hover{color: #F37020;}

.success_message_wrapper{width: 100%;position: fixed;left: 0px;top: 0px;height: 100%;z-index: 100;}

.overlay_bg{width: 100%;position: fixed;left: 0px;top: 0px;height: 100%;background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);pointer-events: none;z-index: 100;}

.success_message_container{width: 640px;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);
    border-radius: 8px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: var(--dark-black);padding: 20px;z-index: 200;}

.close_arrow{width: 36px;height: 36px;position: absolute;right: 30px;top: 30px;}

.close_arrow a{display: block;}

.close_arrow button{display: block;background: none;border: none;width: 36px;height: 36px;}

.success_content{padding: 20px 0px 0px 0px;}

.success_content h5{font-size: 36px;line-height: 44px;color: #E7DCC9;font-weight: normal;font-family: var(--playfair-font);
    margin-bottom: 8px;}

.success_content p{font-size: 16px;line-height: 24px;font-weight: normal;color: rgba(255, 255, 255, 0.40);}

/** regsiter page styles end */

.wrapper{width: 100%;float: left;overflow-x: hidden;}

.heading{width: 100%;float: left;}

.heading img{float: left;margin-right: 15px;height: 32px;display: none;}

.heading h1{float: left;font-family: 'neue_kabelregular';color: #E7DCC9;font-size: 32px;font-weight: normal;}

.heading .text_information{color: #b5b5b5;margin-top: 10px;}

.success_link a{background: #F37020;border-radius: 2px;height: 50px;color: #060F1F;font-size: 14px;font-weight: 600;
    width: 100%;float: left;text-align: center;line-height: 50px;text-decoration: none;}

.message_right_container{width: 532px;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}

.home_link{width: 100%;height: 50px;float: left;margin-top: 30px;}

.home_link a{background: #F37020;height: 44px;width: 100%;float: left;font-size: 16px;line-height: 42px;
    color: #F4F3E8;font-family: 'neue_kabelregular';text-decoration: none;text-align: center;}

.captcha {margin: 0 auto;width: 65%;margin-bottom: 1rem;}

.middle_container{width: 360px;margin: 100px auto;}

.forgot_password_container{margin: 30px auto;}

.heading_content img{margin-bottom: 10px;}

.heading_content h2{margin-bottom: 10px;font-family: var(--playfair-font);font-size: 36px;font-weight: 600;line-height: 44px;}

.heading_content p{margin-bottom: 32px;color: var(--light-text);font-size: 16px;font-weight: 400;line-height: 24px;}

.back_login_link{padding-top: 20px;text-align: center;display: flex;justify-content: center;align-items: center;}

.back_login_link a{font-weight: 600;font-size: 14px;line-height: 20px;text-decoration: none;color: rgba(244, 243, 232, 0.45);
    display: flex;}

.back_login_link a img{margin-right: 5px;}

/* start of deposit flow */

.deposit_btn{background: #F37020;color: #fff;border:1px solid #f37020;}

.deposit_btn:hover,.deposit_btn:focus{background: #F37020;color: #fff;border:1px solid #f37020;
box-shadow: none;}

/* end of deposit flow */

/*  Resolutions above 1500px  */

@media (min-width: 1500px) {

.container, .container-lg, .container-md, .container-sm, .container-xl {max-width: 1216px;}

.navbar-nav{position: relative;left: -110px;}

.services_content{padding: 0px;}

.service_item_large .service_image{height: 343px;}

.service_item_medium .service_image,.service_item_small .service_image{height: 330px;}

.service_item_medium .service_image img, .service_item_small .service_image img{height: 100%;object-fit: cover;}

.service_item_small{width: 466px;min-width: 466px;}

}

/*  Resolutions above 1900px  */

@media (min-width: 1900px) {

.experience_ripple_img{top: 2750px;height: 3055px;}

}

/*  Resolutions above 1280px  */

@media (min-width: 1201px) and (max-width: 1499px)  {

/* .navbar-expand-md .navbar-nav{position: relative;left: -75px;} */

.navbar-expand-md .navbar-nav{position: relative;left: -140px;}

.service_item{min-width: initial;width: 100%;}

.service_item:last-child{margin-right: 0px;}

.service_item_large{position: relative;}

.service_item_small{width: 466px;min-width: 466px;}

.service_image{height: 264px;}

.service_item_large .service_image{height: 310px;}

.service_item_medium .service_image,.service_item_small .service_image{height: 330px;}

.service_item_medium .service_image img, .service_item_small .service_image img{height: 100%;object-fit: cover;}

.app_experience_content{padding: 0px;}

.app_experience_wrapper{margin-bottom: 100px;}

}

/*  Resolutions above 1280px  */

@media (max-width: 1280px) {

.faq_content{margin-bottom: 100px;}

}


/*  Resolutions below 1200px  */
@media (min-width: 992px) and (max-width: 1199px) {

.wrapper{overflow: hidden;}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{padding-left: 20px;padding-right: 20px;}

.row {margin-right: -20px;margin-left: -20px;}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 20px;padding-left: 20px;}

.member_link_wrap a{width: 100px;margin-right: 0px;}

.member_link_wrap a.login_link{margin-right: 16px;}

.navbar-expand-md .navbar-nav{position: relative;left: -30px;}

.banner_content{width: 90%;}

.banner_content h1{font-size: 50px;line-height: 62px;}

.video_embed_container{width: 100%;}

.partner_top_logo,.partner_bottom_logo{width: 100%;}

.partner_top_logo img {margin-right: 60px;height: 125px;width: auto;}

.partner_bottom_logo img {margin-right: 30px;height: 60px;width: auto;}

.services_wrapper{padding: 0px 0px 0px 15px;}

.services_content{padding: 0px;}

.service_item{min-width: 300px;}

.service_image{height: 223px;}

.service_item_large .service_image{height: 185px;}

.services_content .heading::after {width: calc(33% + 15px);}

.app_experience_content .heading::after {width: calc(20% + 15px);}

.app_experience_wrapper .right_space{padding-right: 15px;}

.app_experience_wrapper .left_space{padding-left: 15px;}

.types_of_membership_text p{width: 100%;}

.bottom_partner_container .partner_top_logo{width: 100%;}

.bottom_partner_container .partner_top_logo img {margin-right: 60px;}

.bottom_partner_container .partner_bottom_logo img {margin-right: 96px;height: 140px;}

.gateway_left_content h4{font-size: 38px;line-height: 50px;}

.gateway_left_content p{font-size: 16px;line-height: 24px;}

.gateway_right_content{float: right;}

.member_tab_left_content p{padding: 20px 0px 10px 0px;}

.member_tab_left_content p strong{font-size: 14px;}

.plan_features p{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.plan_info h4.digital_yearly, .plan_info h4.digital_monthly{font-size: 15px;}

}

/*  Resolutions below 991px  */

@media (max-width: 991px) {

.wrapper{overflow-x: hidden;}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{padding-left: 16px;padding-right: 16px;}

.row {margin-right: -16px;margin-left: -16px;}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 16px;padding-left: 16px;}

.header{height: 72px;padding: 15px 0px;position: fixed;animation: none;border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(4px);
    transform: translate3d(4px, 4px, 4px);
    -webkit-backdrop-filter: blur(4px); 
    top:-4px;

}

.header .logo a{display: inline-block;}

.header .logo img{width: 228px;height: 48px;margin-left: -5px;position: relative;z-index: 200;}

.menu_wrapper{margin-top: -32px;padding: 0px;}

.menu.navbar{padding: 0px;float: left;display: block;width: 100%;height: auto;left: 0px;}

.menu.navbar .navbar-toggler{padding: 0px;width: 40px;height: 40px;border: none;border-radius: 0px;float: right;
    margin-top: -15px;margin-right: 15px;}

.menu.navbar .navbar-toggler.collapsed .navbar-toggler-icon{
    background-image: url('https://static-web.fra1.digitaloceanspaces.com/royalarion/menu-icon.svg');
}

.menu.navbar .navbar-toggler .navbar-toggler-icon{
    background-image: url('https://static-web.fra1.digitaloceanspaces.com/royalarion/menu-close.svg');
}

.menu.navbar .navbar-toggler.collapsed .close-arrow{display: none;}

.menu.navbar .navbar-toggler .close-arrow{display: inline-block;position: absolute;right: 16px;top: -12px;}

.menu ul{width: 100%;text-align: center;}

.menu ul li{width: 100%;margin: 0px;}

.menu ul li a{line-height: 40px;width: 100%;float: left;}

.menu.navbar-light .navbar-nav .nav-link.active{background: transparent;color: var(--brand-primary-500);}

.menu.navbar-light .navbar-nav .nav-link{line-height: 40px;margin-left: 0px;text-align: center;padding: 4px 16px;}

.member_link_wrap{margin-left: 0px;padding-bottom: 15px;width: 100%;padding-top: 10px;}

.member_link_wrap a{width: 100%;border-radius: 8px;height: 48px;line-height: 48px;background: none;
    border: 1px solid var(--light-white);}

.member_link_wrap a.login_link{margin-bottom: 20px;background: var(--brand-primary-500);
    border: 1px solid var(--brand-primary-500);}

.navbar-collapse{width: 100%;float: left;padding: 20px 20px 10px 20px;background: #121212;margin-top: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);}

.banner_container{height: auto;margin-top: 72px;min-height: initial;}

.ripple_img{width: 100%;height: 460px;top: 0px;}

.ripple_img img{height: 100%;object-fit: cover;}

.header_row .blur_circle{width: 251px;height: 251px;margin-top: -200px;border-radius: 418.527px;background: var(--brand-primary-500);
    filter: blur(73.2421646118164px);}

.circle-ripple{width: 251px;height: 251px;transform: translate(-50%,-80%);}

/* ripple effect */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

.header_row::after{width: 340px;height: 340px;border-radius: 500px;filter: blur(90px);}

.banner_content{width: 100%;padding: 32px 0px 40px 0px;}

.banner_content h1{font-size: 32px;line-height: 40px;margin-bottom: 12px;}

.banner_content p{font-size: 16px;line-height: 24px;max-width: initial;}

.banner_links{padding-top: 16px;}

.banner_image{height: auto;padding: 0px 20px 40px 20px;float: left;}

.banner_image img{height: auto;}

.pagenot_found_container{margin-top: 24px;}

.header_wrapper{display: flex;justify-content: center;padding: 0px 0px 140px 0px;position: relative;z-index: 10;}

.pagenot_found h2{font-size: 40px;line-height: 44px;letter-spacing: -0.8px;margin-bottom: 28px;}

.pagenot_found h3{font-size: 28px;line-height: 44px;letter-spacing: -0.5px;margin-bottom: 8px;}

.video_container{padding-bottom: 50px;}

.video_container h3{font-size: 24px;line-height: 32px;margin-bottom: 24px;font-weight: 500;}

.video_embed_container{width: 100%;}

.partner_content h2{font-size: 24px;line-height: 32px;letter-spacing: initial;}

.partner_content h2::after{width: 150px;}

.partner_logo_container{padding-top: 30px;}

.partner_top_logo{width: 100%;}

.partner_top_logo img{height: 28px;width: auto;margin-right: 30px;}

.partner_bottom_logo img{height: 22px;width: auto;margin-right: 20px;}

.partner_mobile_logos{width: 100%;float: left;}

.partner_mobile_logos a{width: 50%;float: left;margin-bottom: 15px;}

.partner_mobile_logos img{width: 100%;height: 100px;object-fit: contain;}

.services_container{padding: 0px 0px 0px 0px;}

.services_container h3{font-size: 24px;line-height: 32px;font-weight: 500;}

.services_content .heading::after {width: calc(20% + 20px);left: -20px;bottom: 20px;}

.services_content .heading{margin-bottom: 24px;}

.services_wrapper{flex-direction: column;}

.service_item{min-width: initial;margin-right: 0px;}

.service_item_large{display: flex;flex-direction: column;width: 100%;}

.service_image{height: auto;margin-bottom: 8px;}

.service_image img{height: auto;}

.service_item_large .service_details{width: 100%;padding: 16px;display: flex;flex-direction: column;order: 2;}

.service_item_large .service_image{width: 100%;height: auto;order: 1;display: flex;flex-direction: column;}

.our_horses_carousel .carousel-button-group button{background: #333;}

.our_horses_content .heading h3{font-size: 24px;line-height: 32px;font-weight: 500;}

.app_experience_container{display: none;}

.experience_ripple_img{height: 5940px;top: 1253px;}

.experience_ripple_img img{object-position: 0% 0%;}

.app_experience_wrapper{margin-bottom: 30px;padding: 16px;}

.experience_accordion_container .accordion-header{font-size: 20px;line-height: 28px;}

.experience_accordion_container .accordion-header button{padding: 16px;}

.experience_accordion_container .accordion-body{padding: 0px 16px 16px 16px;}

.stable_image{padding-left: 0px;}

.app_experience_row::after{width: 340px;height: 340px;border-radius: 600px;filter: blur(120px);}

.app_experience_content h3{font-size: 28px;line-height: 36px;text-align: left;margin-bottom: 12px;}

.app_experience_content .heading p{width: 100%;text-align: left;}

.app_experience_content .heading::after {width: calc(45% + 20px);right: -20px;bottom: 35px;}

.app_experience_content .heading{margin-bottom: 30px;}

.app_experience_wrapper .right_space{padding: 20px 30px 0px 30px;}

.app_experience_container .cards_wrapper{padding-left: 0px;}

.experience_left_content .cards_container p{font-size: 16px;line-height: 24px;}

.experience_left_content h6{font-size: 14px;line-height: 18px;}

.experience_left_content h4{font-size: 20px;line-height: 25px;}

.app_experience_wrapper .left_space{padding-left: 20px;padding-top: 30px;}

.contact_container .faq_content{margin-bottom: 80px;}

.our_horses_container{margin-bottom: 30px;}

.horse_item_info .horse_age_info{position: static;}

.our_horses_carousel .horse_item{height: 454px;}

.horse_item_container.front .img_wrapper{height: 316px;}

.horse_item_container.back .img_wrapper{height: 192px;}

.our_horses_carousel .horse_item .horse_item_container.back .img_wrapper img{object-fit: fill;height: auto;}

.carousel-item-padding-40-px {padding: 26px 12px 26px 12px;}

.our_horses_carousel .horse_item .img_wrapper img{width: 100%;height: 100%;object-fit: cover;
object-position: 50% 50%;}

.regular_events_content .heading h3{font-size: 24px;line-height: 32px;font-weight: 500;margin-bottom: 16px;}

.regular_events_wrapper{padding: 0px;border-radius: 0px;background: none;}

.schedule_time_wrapper.regular_events_calendar{padding-bottom: 20px;}

.monthly_events_wrapper{padding-top: 30px;overflow: visible;max-height: none;padding-bottom: 20px;}

.monlthy_events_list {margin-bottom: 20px;}

.monthly_events_content{background: rgba(255, 255, 255, 0.10);}

.monthly_events_content .text_content{padding: 8px;}

.monthly_events_content .text_content h6{font-size: 14px;line-height: 20px;margin-bottom: 2px;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.monthly_events_content .text_content p{font-size: 14px;line-height: 20px;-webkit-line-clamp: 3;
    -webkit-box-orient: vertical;display: -webkit-box;max-width: 100%;height: 60px;overflow: hidden;}

.types_of_membership_text .heading h3{font-size: 24px;line-height: 32px;font-weight: 500;margin-bottom: 16px;}

.types_of_membership_content{padding: 24px 16px 0px 16px;background: #141414;border-radius: 16px;}

.membership_tabs_content{padding: 0px;border-radius: 0px;background: none;}

.types_of_membership_text p{width: 100%;font-size: 14px;line-height: 24px;margin-bottom: 24px;}

.types_of_membership_text a{margin-top: 0px;margin-bottom: 24px;}

.membership_card_info{padding: 24px 16px;margin-bottom: 24px;}

.our_horses_carousel .react-multiple-carousel__arrow{top: 200px;}

.types_of_membership_container{padding: 0px;margin-bottom: 60px;}

.pricing_types_membership_container{margin-bottom: 0px;}

.types_of_membership_content .heading h4{font-size: 24px;line-height: 32px;font-weight: 500;margin-bottom: 20px;}

.membership_tabs_content .nav-tabs .nav-item button{width: 140px;}

.membership_tabs_content .nav-tabs{margin-bottom: 20px;}

.member_tab_left_content a{position: static;width: 153px;margin-top: 40px;}

.member_tab_right_content{padding: 34px 0px 16px 0px;}

.faq_heading{width: 100%;margin-top: 16px;}

.faq_heading h3{margin-bottom: 11px;font-size: 36px;line-height: 48px;}

.faq_content{margin-bottom: 30px;}

.faq_accordion .accordion-header button{padding: 12px 50px 12px 12px;font-size: 18px;line-height: 28px;}

.faq_accordion .accordion-body{padding: 12px;font-size: 16px;line-height: 26px;}

.faq_accordion .action_icon{right: 12px;top: 6px;}

.faq_link a{font-size: 16px;line-height: 24px;}

.faq_link{padding-top: 16px;}

.bottom_partner_container {margin-bottom: 60px;margin-top: 0px;padding-bottom: 0px;}

.bottom_partner_container .partner_top_logo,.bottom_partner_container .partner_bottom_logo{width: 100%;}

.bottom_partner_container .partner_top_logo img{height: 30px;margin-right: 20px;}

.bottom_partner_container .partner_bottom_logo img {margin-right: 40px;height: 85px;}

.gateway_container{padding: 40px 0px;margin-bottom: 30px;backdrop-filter: none;}

.gateway_left_content h4{font-size: 32px;line-height: 38px;letter-spacing: 0em;width: 90%;}

.gateway_left_content p{font-size: 16px;}

.gateway_right_content{margin-top: 30px;width: 100%;}

.gateway_right_content .banner_links a{width: 47%;border-radius: 8px;}

.footer_container{padding: 28px 0px 0px 0px;border-top: none;background: rgba(18, 18, 18, 0.15);}

.gateway_info h3{width: 100%;margin-bottom: 24px;text-align: center;}

.gateway_info a{margin: 0px auto;width: 153px;}

.footer_links{margin-top: 40px;text-align: center;}

.footer_links_bottom{margin-top: 32px;}

.footer_links h6{margin-bottom: 16px;}

.footer_links h6.company-text{display: none;}

.footer_links a{justify-content: center;}

.social_links{justify-content: center;gap: 64px;}

.innerpages_links li{display: inline-block;margin-right: 16px;}

.footer_top_container ul li{margin-right: 22px;}

.footer_top_container ul li a{font-size: 14px;line-height: 22px;}

.footer_bottom_container{margin: 10px 0px 32px 0px;border-top: none;padding-top: 20px;
text-align: center;display: flex;flex-direction: column;}

.footer_bottom_container span{width: 100%;text-align: center;order: 2;line-height: 24px;}

.footer_bottom_container ul{float: left;position: relative;left: 50%;transform: translateX(-50%);}

.footer_bottom_container a{float: none;padding-bottom: 15px;order: 1;}

.footer_container .container-fluid{padding: 0px;}

.content_container{margin-top: 72px;}

.content_container.about_content_container{padding-bottom: 0px;padding-top: 0px;}

.about_content{padding-bottom: 0px;}

.about_header{padding: 30px 0px 40px 0px;text-align: left!important;}

.about_header h2{font-size: 36px;line-height: 44px;text-align: center;margin-bottom: 8px;}

.about_header p{font-size: 16px;line-height: 24px;text-align: left;width: 100%;}

.about_video_container{padding-bottom: 30px;margin-bottom: 0px;}

.about_video_container video{border-radius: 5px;}

.about_top_blur{width: 600px;height: 600px;transform: translateX(300px);top: 925px;}

.about_left_content h5{font-size: 12px;line-height: 24px;font-weight: 600;margin-bottom: 4px;}

.about_left_content h4{font-size: 24px;line-height: 32px;font-weight: 500;letter-spacing: initial;margin-bottom: 10px;}

.about_right_content{padding-top: 20px;}

.about_right_content p{font-size: 14px;line-height: 20px;margin-bottom: 24px;}

.about_youtube_player{width: 100%;}

.about_youtube_player iframe{width: 100%;height: 200px;}

.team_info{position: relative;}

.team_img{height: auto;border-radius: 8px;min-height: 262px;}

.team_img img{border-radius: 0px;}

.team_social_links{position: absolute;right: 0px;top: 0px;}

.team_content{padding-bottom: 8px;}

.team_content h3{text-align: left;margin-bottom: 17px;font-size: 24px;line-height: 32px;font-weight: 500;}

.team_list{padding-bottom: 38px;}

.team_info h5{font-size: 24px;line-height: 32px;margin-bottom: 4px;}

.team_info h6{font-size: 14px;line-height: 20px;font-weight: 400;text-transform: initial!important;}

.team_info p{font-size: 14px;line-height: 20px;}

.about_bottom_ripple,.about_bottom_blur{bottom: -658px;}

.about_bottom_blur{width: 600px;height: 600px;border-radius: 1000px;filter: blur(175px);}

.contact_content{padding-bottom: 0px;}

.contact_ripple{top: 275px;height: 2036px;transform: translateX(-50%);}

.contact_blur{transform: translateX(-600px);}

.contact_container .about_header p{font-size: 16px;line-height: 24px;text-align: center;}

.founder_info{margin-bottom: 0px;}

.founder_info .card_container{margin-bottom: 24px;}

.founder_heading h3{font-size: 24px;line-height: 32px;}

.founder_info .card_container h3{font-size: 24px;line-height: 32px;margin-bottom: 16px;}

.founder_info .card_container h5{font-size: 22px;line-height: 30px;margin-bottom: 14px;}

.founder_info .card_container p{font-size: 16px;line-height: 24px;}

.founder_info .card_container h6{font-size: 16px;line-height: 24px;}

.founder_info .card_container span{font-size: 16px;line-height: 24px;}

.founder_info .card_container a{font-size: 16px;line-height: 24px;}

.contact_container .contact_card_wrap{margin-bottom: 32px;}

.contact_card h5{font-size: 20px;line-height: 28px;}

.contact_card .icon_wrap{margin-bottom: 16px;}

.contact_container .contact_content{display: none;}

.member_wrapper{padding-top: 30px;padding-bottom: 30px;}

.member_left_wrapper{display: none;}

.member_right_container h4{font-size: 32px;line-height: 48px;text-transform: uppercase;width: 90%;margin-bottom: 24px;}

.form_field_wrapper .half_width{width: 100%;}

.first_name_field{right: 0px;}

.pr-32 {padding-right: 0px;}

.youtube_player{width: 100%;}

.video_embed_container iframe{height: 200px;}

.terms_conditions_content iframe{width: 100%;height: 400px;}

}


/*  Resolutions below 990px  */
@media (min-width: 768px) and (max-width: 990px) {

.menu_wrapper{margin-top: 6px;}

.navbar-expand-md .navbar-toggler{display: block;}

.navbar-expand-md .collapse:not(.show){display: none!important;}

.navbar-expand-md .navbar-collapse{display: block!important;}

.partner_top_logo img{height: 64px;}

.partner_bottom_logo{width: 100%;}

.partner_bottom_logo img {height: 45px;margin-right: 30px;}

.app_experience_wrapper .left_space{padding-top: 0px;}

.bottom_partner_container .partner_top_logo img {height: 96px;margin-right: 40px;}

.bottom_partner_container .partner_bottom_logo{width: 100%;}

.bottom_partner_container .partner_bottom_logo img {margin-right: 30px;height: 120px;}

.gateway_left_content h4{font-size: 26px;line-height: 30px;}

.gateway_left_content p {font-size: 14px;}

.gateway_right_content{width: 100%;float: left;margin: 0px;}

.banner_links a{font-size: 14px;}

.gateway_right_content .banner_links a{width: 45%;}

}

/*  Resolutions below 699px  */
@media (min-width: 480px) and (max-width: 699px) {
 
.success_message_container{width: calc(100% - 40px);}

.success_message_container .img_container img{width: 100%;height: auto;}

.success_content h5{font-size: 26px;line-height: 34px;}

.success_content p{font-size: 14px;line-height: 20px;}

.home_link{margin-top: 30px;}

.app_experience_container .cards_wrapper .cards_list:last-child{padding-right: 0px;}

}

/*  Resolutions below 479px  */
@media (max-width: 479px) {	

.success_message_container{width: calc(100% - 40px);}

.success_message_container .img_container img{width: 100%;height: auto;}

.success_content h5{font-size: 26px;line-height: 34px;}

.success_content p{font-size: 14px;line-height: 20px;}

.home_link{margin-top: 30px;}

.app_experience_container .cards_wrapper .cards_list:last-child{padding-right: 0px;}

.memberships_links_wrapper{width: 100%;}

.memberships_links_wrapper ul li a{font-size: 14px;}

.memberships_links_wrapper ul li span{font-size: 14px;line-height: 24px;}

.memberships_links_wrapper ul li small{font-size: 12px;line-height: 18px;}

.our_horses_carousel .horse_item {height: 484px;}

.horse_item_container.front .img_wrapper{height: 352px;}

.horse_item_container.back .img_wrapper{height: 216px;}

}

/*  Resolutions below 400px  */
@media (max-width: 400px) {	

.partner_top_logo img{margin-right: 20px;}

.partner_bottom_logo img{height: 20px;margin-right: 20px;}

.bottom_partner_container .partner_top_logo img{height: 28px;}

.bottom_partner_container .partner_bottom_logo img{height: 75px;}

.our_horses_carousel .horse_item {height: 454px;}

.horse_item_container.front .img_wrapper {height: 316px;}

.horse_item_container.back .img_wrapper {height: auto;background: transparent;}

}

/*  Resolutions below 360px  */
@media (max-width: 360px) {	

.team_img{min-height: 248px;}

.partner_top_logo img{margin-right: 15px;}

.partner_bottom_logo img{margin-right: 15px;}

.bottom_partner_container .partner_top_logo img {height: 26px;margin-right: 15px;}

.bottom_partner_container .partner_bottom_logo img {height: 65px;margin-right: 30px;}

}

.padding_bottom40{ margin-bottom: 10px; }

.padding_top40{  margin-top: 10px; margin-bottom: 60px;}

.faqpaddtop{ padding-top: 20px;background: #050505;position: relative;}

.card_contentnomargin{ margin-bottom: 0px !important;}

.card_contentnomargin .giveaway_list{ margin-bottom: 30px;}