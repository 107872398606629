.hide_block{display: none!important;}

.show_block{display: block!important;}

.no-margin{margin: 0px!important;}

.signup_container{width: 100%;min-height: 100vh;background: #121212;position: absolute;left: 0px;top: 0px;z-index:99;display: flex;    
     overflow: hidden;
    }

.login_ripple{width: 1096px;height: 100%;position: absolute;top: 0px;right: 0px;}

.login_ripple img{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;object-fit: cover;}

.login_ripple_effect{width: 600px;height: 600px;border-radius: 50%;background: rgba(243, 112, 32, 0.4);position: fixed;right:-18%;
    top: 50%;transform: translateY(-50%);animation: loginripple 10s linear infinite;-webkit-animation: loginripple 10s linear infinite;}

/* ripple effect */
@keyframes loginripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0);
    }
}

@-webkit-keyframes loginripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1),
        0 0 0 644px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 120px rgba(243, 112, 32, 0.1), 
        0 0 0 264px rgba(243, 112, 32, 0.1), 
        0 0 0 436px rgba(243, 112, 32, 0.1), 
        0 0 0 644px rgba(243, 112, 32, 0.1),
        0 0 0 892px rgba(243, 112, 32, 0);
    }
}

.login_blur{width: 600px;height: 600px;border-radius: 1000px;background: var(--brand-primary-500);filter: blur(175px);
position: absolute;top: 50%;right: 40px;
transform: translate(50%,  50%);
/* transform: translate3d(175px, 175px, 175px); */
}

.signupformview{ display: flex;width: 100%;}

::-webkit-full-page-media, :future, :root .login_blur {
    transform: translate3d(175px, 175px, 175px);
 }

 ::-webkit-full-page-media, :future, :root .password_blur {
    transform: translate3d(175px, 175px, 175px);
 }

.signup_left_container{width: 50%;float: left;padding: 30px 112px;display: flex;flex-direction: column;justify-content: center;
    min-height: 100vh;background: var(--dark-black);}

.signup_left_container .logo{position: relative;z-index: 8;left: 0px;top: 0px;}

.signup_left_container .logo .back_link_arrow{display: none;}

.signup_left_container h3{margin: 14px 0px 30px 0px;width: 100%;float: left;font-family: var(--playfair-font);font-size: 36px;
    font-weight: 600;line-height: 44px;}

.signup_left_container h5{color: var(--light-white);font-size: 12px;font-weight: 400;line-height: 17px;margin-bottom: 32px;
margin-top: 10px;}

.signup_left_container h5 strong{color: var(--brand-primary-500);font-weight: 600;padding-right: 5px;}

.signup_left_container h5 span{padding-right: 5px;}

.signup_left_container h5 br{display: none;}

.signup_left_container h6{margin-top: 10px;margin-bottom: 25px;width: 100%;float: left;color: var(--light-text);font-size: 16px;
    font-weight: 400;line-height: 24px;}

.remember-wrap label input{width: 16px;height: 16px;margin-right: 8px;}

.remember-wrap label span{color: var(--light-text);font-size: 14px;font-weight: 500;line-height: 20px;}

.remember-wrap a{float: right;text-decoration: none;color: var(--brand-primary-500);font-size: 14px;font-weight: 600;
    line-height: 20px;}

.login_content .form-group.half-col{width: 50%;padding-right: 20px;}

.login_content .form-group.first-name-field .error-msg{right: 22px;}

.overlay_bg{width: 100%;height: 100%;position: fixed;top: 0px;left: 0px;background: #000;opacity: 0.8;z-index: 100;}

.login_content .form-group{position: relative;margin-bottom: 20px;width: 100%;float: left;}

.login_content .form-group.email-field{margin-bottom: 20px;}

.login_content .form-group label{margin-bottom: 8px;color: var(--light-white);font-size: 14px;font-weight: 600;line-height: 20px;
width: 100%;float: left;}

.login_content .form-group label .password_info{display: inline-block;height: 20px;position: absolute;top: 0px;
    margin-left: 6px;cursor: pointer;}

.login_content .form-group .passwodicon{position: absolute;height: 42px;right: 0px;top: 29px;width: 40px;display: flex;
    justify-content: center;z-index: 1;cursor: pointer;align-items: center;}

.login_content .form-group .passwodicon svg path{fill: var(--light-text);}

.password_hint_info{border-radius: 10px;background: #353533;width: 222px;padding: 10px 24px 15px 14px;position: absolute;
    bottom: 30px;left: -67px;opacity: 0;transition: .3s ease-in all;visibility: hidden;}

.password_hint_info small{color: var(--light-white);font-size: 12px;font-weight: 400;line-height: normal;}

.login_content .form-group label .password_info:hover .password_hint_info{opacity: 1;visibility: visible;}

.password_hint_info svg{position: absolute;bottom: -6px;left: 70px;}

.login_content .form-group .select_country_code{width: 120px;float: left;margin-right: 6px;}

.login_content .form-group .phone_field_wrap{float: left;width: calc(100% - 126px);}

.form_container .select_country_code select{height: 44px;padding: 10px 14px;border-radius: 8px;border: 1px solid rgba(244, 243, 232, 0.15);
    background: var(--dark-black, #121212);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: var(--light-white);
    font-size: 16px;font-weight: 400;line-height: 24px;transition: .3s all ease-in;}

.form_container .select_country_code select{appearance: none;background-color: var(--dark-black, #121212);padding-left: 15px;
    text-transform: capitalize;
    background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/select-arrow-down.svg) !important;
    background-size: 18px !important;background-position: 90% 14px !important;background-repeat: no-repeat !important;
    height: 44px;padding: 10px 14px;border-radius: 8px;border: 1px solid rgba(244, 243, 232, 0.15);
    background: var(--dark-black, #121212);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: #E7DCC9;
    font-size: 16px;font-weight: 400;line-height: 24px;transition: .3s all ease-in;}

.form_container .form-control{height: 44px;padding: 10px 14px;border-radius: 8px;border: 1px solid rgba(244, 243, 232, 0.15);
    background: var(--dark-black, #121212);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: var(--light-white);
    font-size: 16px;font-weight: 400;line-height: 24px;transition: .3s all ease-in;}

.buttons-wrap{margin-top: 30px;}

.buttons-wrap button{width: 100%;height: 44px;font-weight: 700;color: var(--light-white);font-size: 16px;text-align: center;
    display: flex;align-items: center;justify-content: center;border-radius: 8px;border: 1px solid var(--brand-primary-500);
    background: var(--brand-primary-500);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.buttons-wrap button:disabled,.buttons-wrap button[disabled]{opacity: 0.7;background: #f39053;cursor: not-allowed;}

.social-buttons-wrap{margin-top: 20px;}

.social-buttons-wrap button{width: 100%;height: 44px;background: #fff;border: 1px solid #D0D5DD;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    font-weight: 600;font-size: 16px;color: #060F1F;display: flex;justify-content: center;align-items: center;margin-bottom: 20px;border-radius: 8px;}

.social-buttons-wrap button img{margin-right: 10px;}

.allready_account p{color: var(--light-text);font-size: 16px;font-weight: 400;line-height: 24px;}

.allready_account p a{margin-left: 5px;text-decoration: none;color: var(--brand-primary-500);font-size: 16px;
    font-weight: 600;line-height: 24px;}

.login_content .form-group .error-msg{font-size: 12px;color: #F04438;position: absolute;right: 0px;bottom: -18px;line-height: 13px;}

.form_container .form-control.error-field,.form_container .form-control.input_error{border: 1px solid #F04438 !important;}

.form_container .form-control:focus{box-shadow: none;border: 1px solid #fff;}

.forgot-password{margin:20px 0px 20px 0px;text-align: center;}

.sign-up{margin-top: 20px;}

.login-btn .btn{width: 100%;background: #38ab50;color: #fff;font-family: 'Graphik-Medium',Arial, sans-serif;font-size: 16px;padding: 9px 15px;}

.error-message{font-size: 12px;color: #F04438;margin: 10px 0px;}

.sign-up span{font-size: 13px;width: 55%;display: inline-block;color: #212529;}

.sign-up button{border: 1px solid #00c389;border-radius: 5px;color: #00c389;font-size: 16px;width: 45%;display: inline-block;background: #fff;padding: 9px 15px;}

.sign-up button:focus{outline: none;}

select.form-control{padding:0px 5px !important;}

.form_container select {width: 100%;display: block;border: 1px solid #a6abae;height: 36px;padding-left: 4px;border-radius: .25rem;}

.terms-agree{margin: 0px 0px 10px 0px;}

.terms-agree .input-checkbox{width: 25px;display: inline-block;}

.terms-agree .input-checkbox input{width: 14px;height: 14px;}

.terms-agree p{display: inline-block;width: calc(100% - 25px);font-size: 12px;color: #445058;line-height: 16px;
padding: 0px;}

.terms-agree p a{color: #00c389;font-weight: 500;}

.terms-agree.error-msg p {color: #FF0000;}
.terms-agree.error-msg input {border: 1px solid #FF0000;}

.spinner-border-sm.show{display: inline-block;margin-right: 8px;color: #fff;margin-top: 5px;}

.spinner-border-sm.hide{display: none;}

.form_container{position: relative;}

.form-group .react-datepicker-wrapper{display: block;}

.form-group .react-datepicker__input-container input{width: 100%;border: 1px solid #a6abae;font-size: 12px;
color: #041e3b;height: 36px;padding: 6px 12px;border-radius: 5px;}

.error-wrapper{margin: 0px;padding: 5px 10px;background-color: #f8d7da;border: 1px solid #f5c6cb;position: absolute;
    width: calc(100% - 80px);top: 0px;float: left;}

.error-wrapper p{color: #f31415;font-size: 11px;line-height: 20px;float: left;overflow: hidden;white-space: break-spaces;
    text-overflow: ellipsis;width: calc(100% - 30px);padding: 0px;font-weight: 500;}

.error-wrapper .icon-wrap{float: left;color: #f31415;margin-right: 8px;font-size: 12px;}

.error-wrapper .icon-wrap img{filter: invert(14%) sepia(88%) saturate(4204%) hue-rotate(349deg) brightness(107%) contrast(98%);}

.success-wrapper{margin: 0px;padding: 5px 10px;background-color: #d4edda;border: 1px solid #d4edda;position: absolute;
    width: calc(100% - 80px);top: 0px;float: left;}

.success-wrapper p{color: #155724;font-size: 12px;line-height: 20px;float: left;overflow: hidden;white-space: nowrap;
    text-overflow: ellipsis;width: calc(100% - 30px);padding: 0px;font-weight: 500;}

.success-wrapper .icon-wrap{float: left;color: #155724;margin-right: 8px;font-size: 12px;}

.login_left_wrapper .alert{margin-top: 30px;margin-bottom: 0px;}

.signup_container .alert{padding: 8px;border-radius: 0px;display: flex;align-items: center;margin: 16px 0px;}

.signup_container .alert img{margin-right: 7px;display: flex;flex-basis: 24px;}

.signup_container .alert p{display: flex;align-items: center;font-weight: 500;font-size: 12px;
    line-height: 16px;flex-basis: 100%;text-align: left;margin-right: 6px;}

.signup_container .alert button{padding: 4px 12px;border: none;height: 32px;font-weight: 500;font-size: 14px;display: flex;
    flex-basis: 98px;justify-content: center;align-items: center;}

.signup_container .alert-danger{border: 1px solid #F04438;color: #fff;background: rgba(255, 255, 255, 0.05);}

.signup_container .alert-danger .success_fill_icon{display: none;}

.signup_container .alert-danger button{background: #F04438;color: #FFFBFA;}

.signup_container .alert-success{border: 1px solid #12B76A;background: rgba(255, 255, 255, 0.05);color: #fff;}

.signup_container .alert-success button{background: #12B76A;color: #F6FEF9;}

.signup_container .alert-success .error_fill_icon{display: none;}

p.error-field{color: #f31415;}

.error-field input[type=checkbox] {outline: 1px solid #f31415;}

.login_content .register_success_container h2{margin-bottom: 12px;}

.success_img{margin-bottom: 15px;}

.success_img img{filter: invert(60%) sepia(49%) saturate(2856%) hue-rotate(120deg) brightness(92%) contrast(102%);}

.success_text h5{color: #00c389;font-size: 22px;}

.password_form_container .error-wrapper{border-radius: 3px;top:-45px;width: 100%;}

.signup_right_container{width: 50%;float: left;padding: 30px 112px;
    display: flex;flex-direction: column;justify-content: center;min-height: 100vh;
    position: relative;
    border-radius: 12.8px 0px 0px 12.8px;background: rgba(255, 255, 255, 0.05);
    /* overflow: hidden; */
}

.signup_right_container::after{content: '';width: 600px;height: 600px;position: absolute;top: 50%;right: -300px;
    border-radius: 1000px;background: var(--brand-primary-500);filter: blur(175px);transform: translateY(-50%);z-index: -1;}

.signup_carousel{width: 100%;min-height: 602px;}

.top_line_pattern{width: 298px;position: absolute;top: 0px;right: 0px;}

.top_line_pattern img{width: 100%;height: auto;}

.bottom_line_pattern{width: 298px;position: absolute;bottom: 0px;left: 0px;}

.bottom_line_pattern img{width: 100%;height: auto;display: none;}

.signup_carousel .carousel-item img{width: 214px;height: 426px;margin-bottom: 162px;object-fit: cover;}

.signup_carousel .carousel-inner{z-index: 1;}

.signup_carousel .carousel_img_wrap{width: 214px;margin: 0px auto;}

.signup_carousel .carousel-caption{right: auto;width: 100%;left: 50%;transform: translateX(-50%);padding-bottom: 30px;}

.signup_carousel .carousel-caption h5{font-weight: 400;font-size: 24px;line-height: 32px;color: #fff;margin-bottom: 8px;
    font-family: var(--playfair-font);}

.signup_carousel .carousel-caption p{font-size: 14px;line-height: 20px;color: var(--light-text);}

.signup_carousel .carousel-indicators li,.signup_carousel .carousel-indicators [data-bs-target]{width: 10px;height: 10px;
    border-radius: 50%;margin-left: 6px;margin-right: 6px;background: #723B19;opacity: 1;box-sizing: border-box;border: none;}

.signup_carousel .carousel-indicators li.active,.signup_carousel .carousel-indicators .active{background: #fff;}

.signup_carousel .carousel-control-prev,.signup_carousel .carousel-control-next{top: auto;}

.signup_carousel .carousel-control-prev-icon{background-image: url('https://static-web.fra1.digitaloceanspaces.com/royalarion/chevron-left-icon.svg');}

.signup_carousel .carousel-control-next-icon{background-image: url('https://static-web.fra1.digitaloceanspaces.com/royalarion/chevron-right-icon.svg');}

.carousel .visually-hidden{display: none;}

.pricing_container{ padding: 30px;}

.membership_container{margin-top: 40px;}

.back_link_btn button{border-radius: 8px;border: 1px solid #fff;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;height: 56px;padding: 16px 48px;justify-content: center;align-items: center;background: none;}

.back_link_btn button svg{width: 24px;height: 24px;}
    
.back_link_btn button span{color: #fff;font-size: 16px;font-weight: 600;line-height: 24px;margin-left: 8px;}

.membership_heading h4{font-size: 48px;line-height: 60px;font-weight: 200;margin-bottom: 20px;text-align: center;
    font-family: var(--playfair-font);color: #fff;position: relative;}

.plan_price_info .plan_info{position: relative;}

.plan_price_info .plan_info span{padding: 0px 12px;border-radius: 100px;border: 1px solid rgba(255, 255, 255, 0.15);background: #F37020;
    color: var(--light-white);font-family: var(--inter-font);font-size: 16px;font-weight: 400;line-height: 24px;position: absolute;
    right: 0px;top: 0px;height: 24px;display: flex;align-items: center;}

.membership_heading p{font-size: 20px;line-height: 30px;font-weight: 300;color: var(--light-text);margin: 0px;text-align: center;}

.membership_content{padding: 64px 0px;}

.plan_left_content{border-radius: 16px 0px 0px 16px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.05);padding: 32px 32px 34px 32px;}

.plan_price{margin-bottom: 157px;}

.plan_price_single{margin-bottom: 105px;}

.plan_price h5{color: var(--brand-primary-500);font-family: var(--inter-font);font-size: 20px;font-weight: 300;
    line-height: 30px; letter-spacing: 2.4px;}

.plan_price h3{color: var(--light-white);font-family: var(--inter-font);font-size: 48px;font-weight: 500;
    line-height: 60px;letter-spacing: -0.96px;}

.plan_features p{padding: 19px 0px;border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);color: var(--light-white);
    font-size: 17px;font-weight: 400;line-height: initial;height: 62px;}

.plan_features p:last-child{border-bottom: none;}

.plan_features p.text_nowrap{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.plan_price_info{padding: 32px 20px;border: 1px solid rgba(255, 255, 255, 0.15);transition: .3s ease-in all;
    background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);cursor: pointer;}

.plan_platinum_info{border-radius: 0px 16px 16px 0px;}

.plan_platinum_info:hover{border: 1px solid rgba(243, 112, 32, 0.25);box-shadow: 0px 0px 10px 5px #F37020;
    background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border-radius: 16px;}

.plan_info h6{color: var(--light-white);font-family: var(--inter-font);font-size: 20px;font-weight: 300;
    line-height: 30px; letter-spacing: 2.4px;text-transform: capitalize;margin-bottom: 12px;}

.plan_info_list{border-top: 1px solid rgba(255, 255, 255, 0.20);border-bottom: 1px solid rgba(255, 255, 255, 0.20);}

.plan_info_list.no_tp_br{border-top: none;}

.plan_info h4{color: var(--light-white);font-family: var(--inter-font);font-size: 24px;font-weight: 600;
    line-height: 54px;letter-spacing: -0.5px;}

.plan_info h4 small{color: var(--light-white);font-size: 13px;font-weight: 400;line-height: 21px;}

.plan_info h4.club_plan_ind{font-size: 32px;}

.plan_info h4.digital_yearly,.plan_info h4.digital_monthly{font-size: 22px;line-height: 54px;letter-spacing: -0.5px;
float: left;}

.plan_info h4.digital_yearly_ind,.plan_info h4.digital_monthly_ind{font-size: 21px;}

.plan_info strong.slash_sym{font-size: 23px;}

.plan_info h4.digital_yearly_login, .plan_info h4.digital_monthly_login{font-size: 30px;}

.plan_info strong.hash_sym_login{font-size: 30px;}

.plan_info h4.digital_yearly_login small,.plan_info h4.digital_monthly_login small{font-size: 16px;line-height: 24px;}

.plan_info h4.club_login{font-size: 30px;}

.plan_info h4.club_login small{font-size: 16px;line-height: 24px;}

.plan_info strong{font-size: 24px;line-height: 54px;letter-spacing: -0.5px;float: left;color: var(--light-white);
    font-family: var(--inter-font);font-weight: 400;margin: 0px 3px;}

.plan_info button{margin: 30px 0px 20px 0px;padding: 10px 18px;border-radius: 8px;border: 1px solid #626262;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);background: none;width: 100%;transition: .3s ease-in all;
    text-align: center;color: var(--light-white);font-size: 16px;font-weight: 700;line-height: 24px;}

.plan_price_info:hover .plan_info button{border-radius: 8px;border: 1px solid var(--brand-primary-500);
    background: var(--brand-primary-500);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.plan_access{display: flex;padding: 13px 0px;justify-content: center;align-items: center;height: 62px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);}

.plan_access:last-child{border-bottom: none;}

.plan_access img{width: 35px;height: 35px;}

.plan_access strong{color: var(--warning-400, #FDB022);font-size: 17;font-weight: 700;line-height: 21px;}

.upgrade_membership_wrapper .membership_plan_container{width: 368px;padding: 20px;border-radius: 16px;transition: .3s ease-in all;
    border: 1px solid rgba(255, 255, 255, 0.15);cursor: pointer;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.05) 0%, rgba(26, 26, 26, 0.05) 100%);}

.upgrade_membership_wrapper .membership_plan_container:hover{border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.05) 0%, rgba(26, 26, 26, 0.05) 100%);
    box-shadow: 0px 0px 5px 3px rgba(243, 112, 32, 0.75);}

.upgrade_membership_wrapper .membership_plan_container:hover .plan_heading .get_started_btn_wrap button{border: 1px solid var(--brand-primary-500);background: var(--brand-primary-500);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.upgrade_membership_wrapper .membership_plan_wrapper{display: flex;}

.membership_plan_container{width: 384px;margin-right: 32px;padding: 32px;border: 1px solid rgba(244, 243, 232, 0.15);float: left;
border-radius: 8px;}

/* .platinum_plan_container{background: linear-gradient(180deg, #101419 0%, #131820 100%);} */

.membership_plan_container:last-child{margin-right: 0px;}

.membership_plan_container.active{border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.05) 0%, rgba(26, 26, 26, 0.05) 100%);
    box-shadow: 0px 0px 5px 3px rgba(243, 112, 32, 0.75);}

.membership_plan_container.active .plan_heading .get_started_btn_wrap button{border: 1px solid var(--brand-primary-500);background: var(--brand-primary-500);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.plan_heading{position: relative;}

.plan_heading h5{color: var(--light-white);font-family: var(--inter-font);font-size: 20px;font-weight: 300;line-height: 30px;
    letter-spacing: 2.4px;text-transform: capitalize;}

.plan_heading strong{font-weight: 300;font-size: 16px;line-height: 24px;text-transform: uppercase;color: rgba(255, 255, 255, 0.45);
    letter-spacing: 0.12em;position: absolute;right: 0px;top: 0px;}

.plan_heading .price_info{float: left;}

.plan_heading .price_info span{color: var(--light-white);font-family: var(--inter-font);font-size: 48px;font-weight: 500;
    line-height: 60px;letter-spacing: -0.96px;}

.plan_heading .price_info small{color: var(--light-white);font-family: 'Manrope', sans-serif;font-size: 16px;font-weight: 400;
    line-height: 24px;}

.plan_heading .billed_info{float: right;width: 121px;height: 32px;background: rgba(244, 243, 232, 0.06);border: 1px solid rgba(244, 243, 232, 0.16);
    text-align: center;margin-top: 14px;border-radius: 8px;}

.plan_heading .billed_info span{width: 100%;float: left;font-size: 14px;line-height: 30px;color: #F4F3E8;}

.plan_heading .get_started_btn_wrap{margin-top: 20px;}

.plan_heading .get_started_btn_wrap button{padding: 10px 18px;border-radius: 8px;border: 1px solid #626262;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);background: none;width: 100%;transition: .3s ease-in all;
    text-align: center;color: var(--light-white);font-size: 16px;font-weight: 700;line-height: 24px;}

.plan_heading .get_started_btn_wrap button:hover{border: 1px solid var(--brand-primary-500);background: var(--brand-primary-500);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.plan_details{padding-top: 20px;}

.plan_details p{font-size: 14px;line-height: 20px;color: rgba(244, 243, 232, 0.45);margin-bottom: 24px;}

.plan_details ul{list-style: none;padding-bottom: 12px;}

.plan_details ul li{padding: 14px 0px;border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);width: 100%;float: left;
display: flex;align-items: center;}

.plan_details ul li:last-child{border-bottom: none;padding-bottom: 0px;}

.plan_details ul li img{float: right;}

.plan_details ul li span{color: var(--light-white);font-size: 16px;line-height: 22px;width: calc(100% - 26px);}

.plan_details .plan_btn button{width: 100%;height: 44px;border: 1px solid #F4F3E8;filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    text-align: center;font-size: 16px;line-height: 24px;font-weight: 600;color: #F4F3E8;background: none;border-radius: 8px;}

.plan_details .plan_btn.upgrade_plan_btn button{background: #F37020;border: 1px solid #F37020;box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);}

.membership_plan_container.active .plan_details .plan_btn button{background: #F37020;border: 1px solid #F37020;}

.password_ripple{width: 100%;position: absolute;left: 0px;top: 50%;height: 100vh;overflow: hidden;transform: translateY(-50%);}

.password_ripple img{width: 100%;height: 100%;object-fit: cover;object-position: 50% 100%;}

.password_ripple_effect{width: 600px;height: 600px;border-radius: 50%;background: rgba(243, 112, 32, 0.4);position: absolute;
    left: 50%;transform: translate(-50%,-50%);animation: ripple 10s linear infinite;-webkit-animation: ripple 10s linear infinite;}

/* .password_ripple_effect{width: 1200px;height: 1200px;border-radius: 50%;background: rgba(243, 112, 32, 0.4);position: absolute;
    left: 50%;top: 50%;transform: translate(-50%,-139%);animation: passwordripple 10s linear infinite;-webkit-animation: passwordripple 10s linear infinite;} */

/* ripple effect */
@keyframes passwordripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 240px rgba(243, 112, 32, 0.1), 
        0 0 0 528px rgba(243, 112, 32, 0.1), 
        0 0 0 872px rgba(243, 112, 32, 0.1),
        0 0 0 1288px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 240px rgba(243, 112, 32, 0.1), 
        0 0 0 528px rgba(243, 112, 32, 0.1), 
        0 0 0 872px rgba(243, 112, 32, 0.1), 
        0 0 0 1288px rgba(243, 112, 32, 0.1),
        0 0 0 1784px rgba(243, 112, 32, 0);
    }
}

@-webkit-keyframes passwordripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 240px rgba(243, 112, 32, 0.1), 
        0 0 0 528px rgba(243, 112, 32, 0.1), 
        0 0 0 872px rgba(243, 112, 32, 0.1),
        0 0 0 1288px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 240px rgba(243, 112, 32, 0.1), 
        0 0 0 528px rgba(243, 112, 32, 0.1), 
        0 0 0 872px rgba(243, 112, 32, 0.1), 
        0 0 0 1288px rgba(243, 112, 32, 0.1),
        0 0 0 1784px rgba(243, 112, 32, 0);
    }
}

/* .password_blur{width: 1200px;height: 1200px;border-radius: 2000px;opacity: 0.75;background: var(--brand-primary-500);
    filter: blur(350px);position: absolute;left: 50%;top: -920px;transform: translateX(-50%);} */

.password_blur{width: 600px;height: 600px;border-radius: 1000px;background: var(--brand-primary-500);filter: blur(175px);
    position: absolute;left: 50%;top: 0px;margin-top: -300px;-webkit-transform-style: preserve-3d;-webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-50%, 0, 0);opacity: 0.75;}

.logo-center{position: relative;z-index: 10;margin-bottom: 48px;}

.landingright{ position: relative;}

.cardcarousel{height: 380px;width: 280px;text-align: center;margin-top: -140px;}

.cardcarousel img{width: 100%;}

.cardcarousel h5{font-weight: 400;font-size: 18px;line-height: 24px;color: #fff;margin-bottom: 8px;
    font-family: var(--playfair-font);}

.cardcarousel p{font-size: 12px;line-height: 18px;color: var(--light-text);}

.cardcarousel_text{width: calc(100% - 20px);position: absolute;left: 50%;transform: translateX(-50%);background: #121212;
    padding: 20px 15px;bottom: -71%;border-radius: 0px 0px 46px 46px;}

.cardcarousel_text h5,.cardcarousel_text p{width: 100%;text-align: center;}

.upgrade_membership_wrapper{padding-bottom: 48px;}

.upgrade_membership_wrapper .col-lg-6{display: flex;}

.plan_card_container{border-radius: 8px;border: 1px solid rgba(244, 243, 232, 0.15);
    background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);padding: 23px 16px 40px 16px;}

.plan_card_container .plan_name_info{color: #F4F3E8;font-size: 16px;font-weight: 400;line-height: 24px;float: left;}

.plan_card_container .plan_current_info{float: right;color: var(--light-text);font-size: 16px;line-height: 24px;}

.plan_card_container .price_info_text{float: left;}

.plan_card_container .price_info_text span{color: #F37020;font-size: 40px;line-height: 28px;}

.plan_card_container .price_info_text small{color: #F4F3E8;font-family: var(--inter-font);font-size: 12px;
    font-weight: 400;line-height: 18px;}

.plan_card_container .billed_annually{float: right;}

.plan_card_container .billed_annually span{border-radius: 100px;border: 1px solid rgba(244, 243, 232, 0.16);
    background: rgba(244, 243, 232, 0.06);float: left;padding: 6px 12px;color: #F4F3E8;font-size: 12px;
    font-weight: 500;line-height: 18px;}

.plan_benefits_info{margin-top: 18px;border-top: 1px solid rgba(255, 255, 255, 0.45);padding-top: 18px;}

.plan_benefits_info ul{margin-left: 16px;}

.plan_benefits_info ul li{color: var(--light-text);font-size: 14px;line-height: 20px;}

.plan_benefits_info ul.club_benefits_info li{font-weight: 700;}

.upgrade_member_btn{width: 100%;margin-top: 42px;}

.upgrade_member_btn a{width: 100%;display: block;}

.upgrade_member_btn a button{border-radius: 8px;border: 1px solid var(--brand-primary-500, #F37020);padding: 10px 18px;
    background: var(--brand-primary-500, #F37020);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);width: 100%;
    color: #FDE2D2;font-size: 16px;font-weight: 500;line-height: 24px;}

.digital_plan_popup .modal-content{border-radius: 8px;padding: 30px;border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(18, 18, 18, 0.90);backdrop-filter: blur(20px);}

.digital_plan_popup .modal-header{padding: 0px 0px 20px 0px;border:none;}

.digital_plan_popup .modal-header .modal-title{color: var(--light-white);font-size: 32px;font-weight: 500;line-height: 30px;}

.digital_plan_popup .modal-body{padding: 0px;}

.select_plan_info label{width: 100%;float: left;height: 126px;border-radius: 8px;border: 1px solid rgba(255, 255, 255, 0.15);
margin-bottom: 14px;padding: 32px 28px;cursor: pointer;}

.select_plan_info label strong{color: var(--light-white);font-family: var(--inter-font);font-size: 48px;font-weight: 500;
    line-height: 60px;letter-spacing: -0.96px;}

.select_plan_info label small{color: var(--light-white);font-size: 16px;font-weight: 400;line-height: 24px;}

.select_plan_info label .radio_input{position: relative;float: right;padding-right: 28px;margin-top: 13px;}

.select_plan_info label .radio_input input{position: absolute;opacity: 0;}

.select_plan_info label .radio_input .radiomark{position: absolute;top: 0;left: 0;height: 30px;width: 30px;
    border: 1px solid rgba(255, 255, 255, 0.15);border-radius: 50%;}

.radio_input input:checked ~ .checkmark {background-color: #2196F3;}
  
.radiomark:after {content: "";position: absolute;display: none;}
  
.radio_input input:checked ~ .radiomark:after {display: block;}
  
.radio_input .radiomark:after{top: 6px;left: 6px;width: 16px;height: 16px;border-radius: 50%;background: var(--brand-primary-500);}

.digital_plan_popup .modal-footer{border: none;padding: 4px 0px 0px 0px;}

.digital_plan_popup .modal-footer .plan_get_started{border-radius: 8px;background: var(--brand-primary-500);padding: 16px 10px;
    display: flex;justify-content: center;align-items: center;width: 100%;border: none;color: var(--light-white);
    font-size: 20px;font-weight: 500;margin: 0px;}

/*  Resolutions above 1200px  */
@media (min-width: 1200px) {	

.overlay_container.ticket_overlay_container{width: 500px;}

.membership_plans_mobile{display: none;}

.pricing_tp_sp{margin-top: 30px;}

}

/*  Resolutions below 1400px  */
@media (min-width: 1200px) and (max-width: 1400px) {	

.plan_info h4.digital_yearly_ind, .plan_info h4.digital_monthly_ind{font-size: 20px;}

.plan_info h4.club_plan_ind{font-size: 30px;}

}

/*  Resolutions below 1200px  */
@media (min-width: 992px) and (max-width: 1199px) {

.signup_carousel{min-height: 532px;}

.signup_carousel .carousel::after{height: 260px;top: 60px;}

.signup_carousel .carousel-caption p{font-size: 14px;line-height: 22px;}

.error-wrapper,.success-wrapper{top: 0px;width: calc(100% - 80px);}

.signup_left_container,.signup_right_container{padding: 30px;}

.upgrade_membership_wrapper .membership_plan_container{padding: 16px;}

.plan_heading .price_info span{font-size: 38px;}

.membership_plans_mobile{display: none;}

.plan_info h4.digital_yearly_login, .plan_info h4.digital_monthly_login{font-size: 20px!important;}

.plan_info strong.hash_sym_login {font-size: 20px;}

.plan_info h4.club_login{font-size: 20px;}

}

/*  Resolutions below 991px  */
@media (max-width: 991px) {	

.login_ripple{display: none;}

.password_ripple,.password_blur{display: none;}

.logo.logo-center{left: 0px;top: 0px;margin-bottom: 32px;}

.logo.logo-center img{width: 176px;height: 32px;}

.signup_container{overflow-x: hidden;}

.signup_left_container .logo .back_link_arrow{display: inline;position: absolute;left: 0px;top: 0px;}

.login_blur{width: 251px;height: 251px;border-radius: 418.527px;background: var(--brand-primary-500);filter: blur(73.2421646118164px);
    top: -170px;right: -125px;transform: translateY(0%);}

.login_ripple_effect{width: 251px;height: 251px;right: -125px;top: -170px;transform: translateY(0%);display: none;}

/* ripple effect */
@keyframes loginripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

@-webkit-keyframes loginripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

.password_ripple_effect{width: 251px;height: 251px;right: -125px;top: -170px;transform: translateY(0%);display: none;}

.pricing_container .password_ripple_effect{ transform: translate(-50%, 0%) !important;}

/* ripple effect */
@keyframes passwordripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

@-webkit-keyframes passwordripple {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 112, 32, 0.1), 
        0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1),
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(243, 112, 32, 0.1), 
        0 0 0 111px rgba(243, 112, 32, 0.1), 
        0 0 0 183px rgba(243, 112, 32, 0.1), 
        0 0 0 270px rgba(243, 112, 32, 0.1),
        0 0 0 373px rgba(243, 112, 32, 0.1),
        0 0 0 499px rgba(243, 112, 32, 0);
    }
}

.password_blur{width: 251px;height: 251px;border-radius: 418.527px;background: var(--brand-primary-500);filter: blur(73.2421646118164px);
    top: -170px;right: -125px;transform: translateY(0%);display: block;margin: 0px;}

.membership_plans_desktop{display: none;}

.back_link_btn button{padding: 8px 16px;height: 48px;margin-bottom: 20px;}

.back_link_btn button span{font-size: 14px;line-height: 22px;}

.membership_plans_mobile{display: flex;overflow: auto;gap: 20px;padding: 10px 16px;width: calc(100% + 32px)!important;
    margin-left: -16px;-ms-overflow-style: none;scrollbar-width: none;}

.membership_plans_mobile::-webkit-scrollbar {display: none;}

.membership_card_wrapper{min-width: 260px;padding: 20px;border-radius: 16px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.05) 0%, rgba(26, 26, 26, 0.05) 100%);float: left;
    transition: .3s ease-in all;}

.membership_card_wrapper:hover{border-radius: 16px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.05) 0%, rgba(26, 26, 26, 0.05) 100%);
    box-shadow: 0px 0px 5px 3px rgba(243, 112, 32, 0.75);}

.membership_card_heading{position: relative;}

.membership_card_heading span{padding: 0px 8px;border-radius: 100px;border: 1px solid rgba(255, 255, 255, 0.15);background: #F37020;
    color: var(--light-white);font-family: var(--inter-font);font-size: 12px;font-weight: 400;line-height: 20px;position: absolute;
    right: 0px;top: 5px;height: 20px;display: flex;align-items: center;}

.membership_card_heading h6{color: var(--light-white);font-family: var(--inter-font);font-size: 16px;font-weight: 300;
    line-height: 30px; letter-spacing: 1.92px;text-transform: capitalize;margin-bottom: 8px;}

.membership_card_heading h4{color: var(--light-white);font-size: 14px;font-weight: 600;line-height: 40px;}

.membership_card_heading h4.digital_yearly,.membership_card_heading h4.digital_monthly{font-size: 14px;float: left;}

.membership_card_heading h4.digital_yearly_ind,.membership_card_heading h4.digital_monthly_ind{font-size: 14px;}

.membership_card_heading h4.digital_yearly_ind_login,.membership_card_heading h4.digital_monthly_ind_login{font-size: 16px;}

.membership_card_heading strong.slash_ind_sym{font-size: 16px;}

.membership_card_heading h4.club_yearly_ind{font-size: 24px;}

.membership_card_heading h4.digital_yearly_login, .membership_card_heading h4.digital_monthly_login{font-size: 24px;}

.membership_card_heading strong.hash_sym_login{font-size: 24px;}

.membership_card_heading h4.club_login{font-size: 24px;}

.membership_card_heading strong{float: left;font-size: 14px;color: var(--light-white);line-height: 40px;
font-weight: 600;margin: 0px 3px;}

.membership_card_heading h4 small{font-size: 13px;font-weight: 400;line-height: 21px;}

.membership_card_heading button{margin: 20px 0px;padding: 10px 18px;text-align: center;width: 100%;border-radius: 8px;
    border: 1px solid #626262;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);background: none;
    font-size: 16px;font-weight: 700;line-height: 24px;color: #FFF;transition: .3s ease-in all;}

.membership_card_heading button:hover{background: #F37020;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.membership_card_wrapper:hover .membership_card_heading button{background: #F37020;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}

.membership_card_list{padding: 12px 0px;border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
width: 100%;float: left;}

.membership_card_list p{float: left;color: var(--light-white);font-size: 12px;line-height: 19px;width: calc(100% - 52px);}

.membership_card_list strong{color: var(--warning-400, #FDB022);font-size: 12px;font-weight: 700;line-height: 18px;
float: right;}

.membership_card_list img{width: 26px;height: 26px;float: right;}

.pricing_container{width: 100%;float: left;padding: 0px;margin-top: 72px;position: relative;}

.plan_card_container{margin-bottom: 16px;}

.digital_plan_popup .modal-content{padding: 20px;}

.digital_plan_popup .modal-header .modal-title{font-size: 28px;line-height: 30px;}

.select_plan_info label{padding: 24px 28px;height: 110px;}

.select_plan_info label strong{font-size: 34px;}

}

/*  Resolutions below 991px  */
@media (min-width: 768px) and (max-width: 991px) {

.error-wrapper,.success-wrapper{top: 0px;width: calc(100% - 45px);}

.signup_left_container{width: 100%;padding: 30px;}

.signup_left_container .logo .back_link_arrow{top: 50%;transform: translateY(-50%);}

.signup_right_container{display: none;}

.membership_container{width: 100%;padding: 0px 20px;}

.membership_plan_wrapper{display: flex;}

.upgrade_membership_wrapper .membership_container{padding: 0px;}

.upgrade_membership_wrapper .membership_plan_container{padding: 16px;}

.plan_heading .price_info span{font-size: 38px;}

}

/*  Resolutions below 767px  */
@media (min-width: 480px) and (max-width: 767px) {
 
.error-wrapper,.success-wrapper{top: 0px;width: calc(100% - 45px);}

.membership_plan_wrapper{display: flex;flex-direction: column;justify-content: center;align-items: center;}

.membership_plan_container{margin-right: 0px;margin-bottom: 32px;}

}

/*  Resolutions below 767px  */
@media (min-width: 319px) and (max-width: 767px) {

.signup_left_container .logo img{width: 176px;height: auto;margin-bottom: 20px;}

.signup_left_container{width: 100%;padding: 40px 20px;justify-content: flex-start;}

.login_left_wrapper{background: url('https://static-web.fra1.digitaloceanspaces.com/royalarion/login-ripple-bg.png') no-repeat top center;}

.signup_right_container{display: none !important;}

.login_content .form-group.half-col{padding-right: 15px;}

.login_content .form-group.first-name-field .error-msg{right: 17px;}

.signup_container .alert p{font-size: 10px;line-height: 14px;}

.signup_container .alert button{margin-left: 5px;font-size: 12px;padding: 4px 8px;height: 30px;}

.signup_left_container h3{font-size: 24px;line-height: 32px;margin-top: 0px;}

.signup_left_container h5{margin-bottom: 16px;}

.signup_left_container h5 br{display: block;}

.form_container .heading_content h2{font-size: 24px;line-height: 32px;letter-spacing: initial;}

.signup_left_container h6{margin-top: 5px;}

.signup_container .alert{margin: 0px 0px 20px 0px;}

.membership_container{width: 100%;padding: 0px 20px;margin: 30px auto 0px auto;}

.membership_heading h4{font-size: 24px;line-height: 32px;font-weight: 600;}

.membership_heading p{font-size: 15px;line-height: 20px;}

.membership_content{padding: 32px 0px;}

.plan_heading h5{font-size: 16px;line-height: 24px;margin-bottom: 16px;}

.plan_heading .price_info span{font-size: 30px;line-height: 38px;}

.plan_heading .price_info small{font-size: 12px;line-height: 18px;margin: 15px 0px 0px 5px;}

.plan_heading .billed_info{width: 107px;height: 30px;margin-top: 5px;}

.plan_heading .billed_info span{font-size: 12px;line-height: 28px;}

.plan_heading{padding-bottom: 32px;}

.plan_details{padding-top: 32px;}

.plan_details ul li img{width: 24px;height: 24px;}

.plan_details ul li span{font-size: 16px;line-height: 24px;}

.upgrade_membership_wrapper .membership_container{padding: 0px;}

.upgrade_membership_wrapper .membership_plan_wrapper{gap: 20px;padding: 10px 10px;overflow: auto;-ms-overflow-style: none;
    scrollbar-width: none; }

.upgrade_membership_wrapper .membership_plan_wrapper::-webkit-scrollbar {display: none;}

.upgrade_membership_wrapper .membership_plan_container{width: 100%;padding: 16px;min-width: 260px;}

.upgrade_membership_wrapper .membership_content{padding-bottom: 10px!important;}

.upgrade_membership_wrapper .plan_heading h5{margin-bottom: 8px;}

.upgrade_membership_wrapper .plan_heading .price_info span{font-size: 40px;line-height: 28px;font-weight: 600;}

.upgrade_membership_wrapper .plan_heading .price_info small{font-size: 16px;line-height: 24px;}

.upgrade_membership_wrapper .plan_heading{padding-bottom: 20px;}

.upgrade_membership_wrapper .plan_details{padding-top: 0px;}

}

/*  Resolutions above 480px  */
@media (min-width: 480px) {	
    
.overlay_container{width: 400px;position: fixed;left: 50%;top: 50%;background: #fff;border-radius: 12px;transform: translate(-50%,-50%);z-index: 200;}  
    
.activation_wrapper .overlay_container{z-index: 10;}    
    
.activation_wrapper .overlay_bg{display: none;}    
    
}

/*  Resolutions below 479px  */
@media (max-width: 479px) {	
    
.overlay_wrapper{width: 100%;height: 100%;position: fixed;top: 20px;left: 0px;z-index: 200;}    
    
.overlay_container{width: 100%;height: 100%;position: fixed;left: 0px;bottom: 0px;background: #fff;transform: translate(0%,0%);z-index: 200;border-radius: 12px 12px 0px 0px;}    
    
.overlay_container.login_overlay_container,.login_overlay_container .overlay_container{height: 100%;}    

.middle_container{width: 100%;padding: 0px 20px;margin: 40px auto;}

.signup_container .middle_container{width: 100%;margin: 40px auto;}

.membership_plan_container{width: 100%;margin-right: 0px;margin-bottom: 16px;padding: 16px;}

.membership_plan_container.active{background: linear-gradient(180deg, #101419 0%, #131820 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);box-shadow: 0px 0px 16px 2px rgba(255, 94, 0, 0.65);}
    
}

.membership_card_content{
    float: left;
}
/*  Resolutions below 400px  */
@media (max-width: 400px) {	

.terms-agree p{font-size: 10px;line-height: 14px;}

.middle_container{width: 100%;padding: 0px 20px;margin: 40px auto;}

}

/*  Resolutions below 374px  */
@media (max-width: 374px) {	
    
/* .login_content{padding: 20px;}     */
    
.login_content.register_content h2{font-size: 20px;margin-bottom: 4px;}
    
.login_content.register_content h6{font-size: 13px;line-height: 14px;}
    
.login_content.register_content .form-group{margin-bottom: 10px;}    
    
.login_content.register_content .form-group label{margin-bottom: 4px;}    
    
.login_content.register_content .login-btn .btn{padding: 7px 15px;}
    
 .login_content.register_content .terms-agree{margin: 10px 0px 10px 0px;}    
    
.login_content.register_content .sign-up button{padding: 7px 15px;}
    
.login_content.register_content .sign-up{margin-top: 14px!important;}
    
.login_content.register_content .login-btn{margin-top: 0px !important;}    
    
.login_content h6{margin-bottom: 15px;}    
    
/* .form_container .form-control{height: 32px;}     */
    
.login_content .form-group .icon-wrap{font-size: 12px;bottom: 7px;}    
    
.sign-up span{width: 60%;}    
    
.sign-up button{width: 40%;}    
    
.error-wrapper,.success-wrapper{top: -32px;}  
    
.register_content .error-wrapper{top: 45px;width: calc(100% - 40px);padding: 3px 10px;}    
    
}

/*  Resolutions below 320px  */
@media (max-width: 320px) {	
    
.login_content.register_success_container h2{font-size: 20px;}  
    
.login_content.register_success_container h6{font-size: 12px;}      
    
}