/** start of loader styles **/

.loader_wrapper{width: 100%;height: 100%;position: fixed;top: 0px;left: 0px;background: #121212;z-index: 100;}

.loader_container{width: 120px;background: #fff;position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);
    border-radius: 8px;padding: 15px;text-align: center;}

.loader_container .site_logo{width: 50px;height: 50px;}

.loader_container .loading_img{width: 50px;}

.loader_container h6{font-size: 14px;color: #121212;font-weight: 600;margin-top: 10px;width: 100%;float: left;}

/** end of loader styles **/

/** start of layout styles **/

.left_container{width: 312px;float: left;background: #121212;border-right: 1px solid rgba(255, 255, 255, 0.20);
    min-height: 100vh;padding: 24px 0px;position: fixed;left: 0px;top: 0px;z-index: 1;}

.left_logo{width: 100%;float: left;margin-bottom: 30px;padding: 0px 24px;}

.left_menu{width: 100%;float: left;}

.left_menu ul{width: 100%;float: left;list-style: none;}

.left_menu ul li{width: 100%;float: left;margin-bottom: 5px;}

.left_menu ul li a{width: 100%;height: 40px;float: left;font-size: 16px;font-weight: 500;text-decoration: none;display: flex;
    align-items: center;padding: 0px 24px;color: #595959;}

.left_menu ul li a.active{color: #F37020;}

.left_menu ul li a img{float: left;margin-right: 10px;}

.left_menu ul li a svg{float: left;margin-right: 10px;}

.left_menu ul li a.active svg path{fill: #F37020;fill-opacity: 1;}

.left_menu ul li a.active img{filter: brightness(0) invert(1);}

.left_menu ul li a span{float: left;}

.bottom_nav_wrapper{width: 100%;position: absolute;left: 0px;bottom: 0px;background: #121212;}

.bottom_nav_content{border-top: 1px solid rgba(255, 255, 255, 0.15);padding-top: 12px;padding-bottom: 12px;}

.bottom_nav_content a{width: 100%;color: rgba(255, 255, 255, 0.3);text-decoration: none;float: left;
    padding: 8px 24px; font-size: 16px; font-weight: 500;     margin-bottom: 5px;}

.bottom_nav_content a.active{color: #F37020;}

.bottom_nav_content a img,.bottom_nav_content a svg{float: left;margin-right: 10px;}

.bottom_nav_content a.active svg path{fill: #F37020;fill-opacity: 1;}

.bottom_nav_content a span{float: left;line-height: 24px;}

.bottom_nav_content .logout_link{float: right;background: none;color: #fff;border: none;padding: 17px 20px 17px 0px;}

.bottom_nav_content .logout_link img{float: left;margin-right: 7px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(49deg) brightness(103%) contrast(103%);}

.bottom_nav_content .logout_link span{float: left;line-height: 20px;}

.right_container{width: calc(100% - 312px);float: left;padding: 40px 60px 0px 60px;margin-left: 312px;position: relative;
    background: #121212;min-height: 100vh;}

.right_content{width: 768px;margin: 0px auto;}

.right_content .heading img{float: left;margin-right: 15px;height: 32px;}

.right_content .heading h1{float: left;color: #fff;font-weight: 400;font-size: 30px;line-height: 38px;font-family: var(--playfair-font);}

.right_content .heading .back_home{float: left;width: 24px;height: 24px;margin-right: 8px;margin-top: 10px;}

.right_content .heading .back_home a{width: 24px;height: 24px;float: left;}

.right_content .heading .back_home a img{display: inline-block;height: 24px;margin: 0px;filter: none;}

.profile_container .back_home a img{filter: invert(45%) sepia(74%) saturate(644%) hue-rotate(342deg) brightness(101%) contrast(95%);}

.more_info_dropdown{float: right;margin-top: 3px;}

.right_content .heading .more_info_dropdown img{height: 24px;margin: 0px;display: inline-block;}

.more_info_dropdown .btn-success{background: none;border: none;padding: 0px;outline: none;box-shadow: none;}

.more_info_dropdown .dropdown-toggle::after{display: none;}

.more_info_dropdown .dropdown-menu{background: #121212;border: 1px solid rgba(255, 255, 255, 0.25);box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: 140px;border-radius: 8px;padding: 0px;left: auto!important;right: 0px;transform: translate3d(-136px, 42.4px, 0px)!important;}

.more_info_dropdown .dropdown-item{padding: 8px 12px;font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);}

.more_info_dropdown .dropdown-item:focus, .more_info_dropdown .dropdown-item:hover{background-color: transparent;color: #fff;}

.more_info_dropdown .btn-success:not(:disabled):not(.disabled).active, 
.more_info_dropdown .btn-success:not(:disabled):not(.disabled):active, 
.more_info_dropdown .show>.btn-success.dropdown-toggle{background-color: transparent;border-color: transparent;box-shadow: none;
    outline: none;}

.profile_container .alert-success .error_fill_icon{display: none;}

.profile_container .alert-success .success_fill_icon{display: inline;}

.profile_container .alert p{display: inline-block;margin-left: 5px;font-weight: 500;}

.profile_container .alert{position: absolute;width: 768px;display: flex;align-items: center;top: 40px;}

/** end of layout styles **/

/** start of home blog event pages styles **/

.home_tabs_container{padding: 16px 0px;}

.home_tabs_container .nav-tabs{background: rgba(255, 255, 255, 0.02);border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;}

.home_tabs_container .nav-tabs .nav-item{width: 50%;text-align: center;padding: 8px;}

.home_tabs_container .nav-tabs .nav-link{padding: 12px 0px;text-align: center;width: 100%;border-radius: 0px;font-size: 16px;
    line-height: 24px;border: none;background: none;color: var(--light-text);}

.home_tabs_container .nav-tabs .nav-link.active, .home_tabs_container .nav-tabs .nav-item.show .nav-link{background: #F37020;color: #fff;
    border-radius: 8px;}

.search_field_wrapper .btn-success:not(:disabled):not(.disabled).active, 
.search_field_wrapper .btn-success:not(:disabled):not(.disabled):active, 
.search_field_wrapper .show>.btn-success.dropdown-toggle{background-color: rgba(255, 255, 255, 0.02);border-color: rgba(255, 255, 255, 0.25);
    box-shadow: none;}

.search_field_wrapper{padding: 24px 0px;}

.search_field_wrapper .search_input{width: 650px;height: 40px;float: left;padding: 7px 10px;position: relative;}

.search_field_wrapper .search_input img{float: left;}

.search_field_wrapper .search_input input{width: 100%;height: 40px;position: absolute;left: 0px;top: 0px;background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(244, 243, 232, 0.25);padding: 0px 45px;transition: .3s ease-in border;font-size: 16px;line-height: 24px;
    color: rgba(244, 243, 232, 0.45);border-radius: 8px;}

.search_field_wrapper .search_input input:focus{outline: none;border: 1px solid rgba(255, 255, 255, 0.5);}

.search_field_wrapper .search_input .close_icon{background: none;border: none;position: absolute;right: 8px;top: 8px;}

.search_field_wrapper .search_input .close_icon svg path{fill: rgba(244, 243, 232, 0.45);}

.search_field_wrapper .dropdown {float: left;width: 94px;height: 40px;padding-left: 20px;}

.search_field_wrapper .dropdown .dropdown-toggle{border-radius: 8px;padding: 0px;width: 94px;float: left;height: 40px;font-size: 16px;
    line-height: 24px;font-weight: 400;display: flex;justify-content: center;align-items: center;
    background: rgba(255, 255, 255, 0.02);border: 1px solid rgba(244, 243, 232, 0.25);}

.search_field_wrapper .dropdown .dropdown-toggle:focus{box-shadow: none;outline: none;}

.search_field_wrapper .dropdown .btn-secondary:not(:disabled):not(.disabled):active, 
.search_field_wrapper .dropdown .btn-secondary:not(:disabled):not(.disabled).active, 
.search_field_wrapper .dropdown.show > .btn-secondary.dropdown-toggle{color: #fff;background-color: #121212;border-color: rgba(255, 255, 255, 0.25);
    box-shadow: none;}

.search_field_wrapper .dropdown-toggle::after{display: none;}

.search_field_wrapper .dropdown .dropdown-toggle span{padding-right: 7px;}

.search_field_wrapper .dropdown-menu{background: #121212;border: 1px solid rgba(255, 255, 255, 0.25);box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: 140px;border-radius: 8px;padding: 0px;left: auto!important;right: 0px;transform: translate3d(-66px, 42.4px, 0px)!important;}

.search_field_wrapper .dropdown-item{padding: 8px 12px;font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);background: rgba(255, 255, 255, 0.02);}

.search_field_wrapper .dropdown-item.active{ color: rgba(243, 112, 32, 0.80);}
.search_field_wrapper .dropdown-item.active:hover{ color: rgba(243, 112, 32, 0.80);}

.search_field_wrapper .dropdown-item:hover{background: rgba(255, 255, 255, 0.02);color: #fff;}

.blog_list_wrapper{padding-top: 16px;}

.blog_list{padding-bottom: 40px;position: relative;}

.blog_list h4{float: left;font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;}

.more_line_wrap{float: right;background: none;border: none;display: none;}

.blog_img{margin: 20px 0px;}

.blog_img img{border-radius: 8px;}

.like_wrap button{background: none;border: none;float: left;position: relative;}

.like_wrap button.fav_static_btn{cursor: default;}

.like_wrap .day_text{float: right;font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.like_wrap_btn .like_active_img{display: none;}

.like_wrap_btn.active .like_active_img{display: inline-block;position: absolute;left: 0px;top: 0px;}

.short_text{padding-top: 20px;}

.short_text p{color: #fff;font-weight: 400;font-size: 14px;line-height: 20px;margin-bottom: 5px;}

.short_text a{font-weight: 500;font-size: 16px;line-height: 24px;color: #F37020;}

.textdecoration:hover{ text-decoration: none; }

.short_text a p{ color: #fff; text-decoration: none; }

.giveaway_list{padding-bottom: 0px;margin-bottom: 40px;border-radius: 12px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.05);}

.giveaway_heading{padding: 16px;}

.giveaway_heading svg{float: left;margin-right: 5px;}

.giveaway_heading strong{float: left;font-weight: 600;font-size: 14px;line-height: 24px;color: #FFFFFF;}

.giveaway_heading .close_icon{float: right;background: none;border: none;width: 24px;height: 24px;}

.giveaway_img{position: relative;}

.giveaway_img img{width: 100%;height: auto;}

.giveaway_img .prize_draw{width: 122px;height: 32px;position: absolute;right: 10px;top: 10px;}

.giveaway_list .short_text{padding: 16px;}

.giveaway_list .short_text p{color: rgba(255, 255, 255, 0.45);margin-bottom: 16px;width: 100%;float: left;}

.giveaway_list .short_text .enter_draw_link{border: 1px solid #FFFFFF;height: 40px;padding: 8px 10px;color: #fff;
    float: left;font-weight: 400;font-size: 16px;line-height: 24px;text-decoration: none;text-align: center;}

.giveaway_list .short_text .check_now_link{background: #F37020;color: #fff;padding: 8px 16px;font-weight: 400;float: left;
    text-decoration: none;text-align: center;border-radius: 8px;}

.giveaway_img_wrap{margin: 24px 0px;}

.form_heading{margin-bottom: 24px;}

.form_heading h6{font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;margin-bottom: 4px;}

.form_heading p{font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.giveaway_results_content .btn-field{border-top: 1px solid rgba(255, 255, 255, 0.25);padding: 16px;}

.giveaway_results_content .alert{display: flex;align-items: center;padding: 10px;}

.giveaway_results_content .alert p{display: inline-block;margin-left: 6px;font-size: 14px;font-weight: 500;}

.giveaway_results_content .alert-danger .success_fill_icon{display: none;}

.giveaway_results_content .alert-success .error_fill_icon{display: none;}

.losser_card_container{min-height: calc(100vh - 250px);}

.losser_card_container h4{font-weight: 400;font-size: 24px;line-height: 32px;color: #fff;border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 8px;margin-bottom: 24px;}

.losser_card_container p{font-weight: 600;font-size: 16px;line-height: 24px;color: rgba(255, 255, 255, 0.45);margin-bottom: 16px;}

.platinum_short_info h5{font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;margin-bottom: 4px;}

.platinum_short_info p{font-size: 16px;line-height: 24px;color: rgba(255, 255, 255, 0.45);display: -webkit-box;
    -webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}

.platinum_action_wrap{margin-top: 16px;}

.platinum_action_wrap .day_text{display: flex;align-items: center;float: left;justify-content: center;height: 34px;}

.platinum_action_wrap .day_text small{font-weight: 500;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.platinum_action_wrap .day_text .circle{width: 4px;height: 4px;background: rgba(255, 255, 255, 0.45);display: inline-block;
    margin:0px 8px;}

.platinum_action_wrap .act_btns{float: right;}

.platinum_action_wrap .act_btns button{background: none;border: none;margin-left: 12px;position: relative;}

.platinum_action_wrap .act_btns button svg{width: 32px;height: 32px;}

.platinum_action_wrap .act_btns button span{width: 32px;height: 32px;float: left;}

.platinum_action_wrap .act_btns button .saved_bookmark{display: none;}

.platinum_action_wrap .act_btns button.active .saved_bookmark{display: inline-block;position: absolute;left: 0px;top: 0px;}

/* .right_container .carousel-item{transition: all 0.5s;}

.right_container .carousel-item li{transition: all 0.5s;} */

.right_container .carousel-item img{border-radius: 8px;max-width: 100%;}

.blog_carousel{position: relative;}

.blog_carousel .carousel.carousel-slider{margin-top: 56px;}

.blog_carousel .carousel-control-next, .blog_image_carousel .carousel-control-next,
.blog_carousel .carousel-control-prev, .blog_image_carousel .carousel-control-prev{display: none;}

.blog_carousel .carousel-indicators,.blog_image_carousel .carousel-indicators{bottom: 15px;background: rgba(20, 20, 20, 0.3);
    backdrop-filter: blur(20px);border-radius: 100px;padding: 4px 0px 4px 10px;margin: 0px;max-width: fit-content;
    left: 50%;transform: translateX(-50%);height: 30px;align-items: center;}

.blog_carousel .carousel-indicators li,.blog_carousel .carousel-indicators [data-bs-target],.blog_image_carousel .carousel-indicators li,
.blog_image_carousel .carousel-indicators [data-bs-target]{width: 12px;height: 12px;border-radius: 50%;background-color: rgba(255,255,255,0.25);
    opacity: 1;border: none;margin-right: 10px;transition: 1s all;}

.blog_carousel .carousel-indicators li.active,.blog_image_carousel .carousel-indicators li.active,
.blog_carousel .carousel-indicators .active,.blog_image_carousel .carousel-indicators .active{background-color: #F58D4D;}

.blog_carousel .carousel .thumbs-wrapper,.blog_carousel .carousel .carousel-status{display: none;}

.blog_carousel .carousel .control-dots{background: rgba(20, 20, 20, 0.3);backdrop-filter: blur(20px);border-radius: 100px;
    padding: 4px 2px 4px 10px;margin: 0px;max-width: fit-content;left: 50%;transform: translateX(-50%);height: 30px;
    align-items: center;display: flex;bottom: 15px;justify-content: center;}

.blog_carousel .carousel .control-dots .dot{width: 12px;height: 12px;border-radius: 50%;background-color: rgba(255,255,255,0.25);
    opacity: 1;border: none;margin-right: 10px;transition: 1s all;box-shadow: none;margin-left: 0px;}

.blog_carousel .carousel .control-dots .dot.selected, .blog_carousel .carousel .control-dots .dot:hover{background-color: #F58D4D;}

.stable_carousel .carousel-indicators [data-bs-target]{width: 16px;height: 16px;}

.stable_carousel  .carousel-indicators li,.stable_carousel .carousel-indicators [data-bs-target]{background-color: rgba(255,255,255,0.25);}

.stable_carousel .carousel-indicators .active{background-color: #F58D4D;}

.stable_carousel{min-height: 550px;}

.carousel .slider-wrapper{height: 480px;background: rgba(255, 255, 255, 0.05);}

.partners_details_container .carousel .slider-wrapper{height: 450px;}

.stable_carousel .carousel.carousel-slider{min-height: 480px;background: rgba(255, 255, 255, 0.05);}

.stable_carousel .carousel .slide{height: 480px;background: rgba(255, 255, 255, 0.05);}

.stable_carousel .carousel .slide div{width: 100%;height: 100%;background: rgba(255, 255, 255, 0.05);}

.stable_carousel .carousel .slide img{height: 100%;}

.partner_details_carousel .carousel-indicators{display: none;}

.stable_carousel.home_feed_carousel{min-height: 510px;}

.blog_carousel.home_feed_carousel .carousel.carousel-slider{margin-top: 20px;}

.top_action_links{position: absolute;left: 0px;top: 0px;height: 56px;background: #121212;z-index: 10;
    padding: 12px 16px;}

.top_action_links .back_link{float: left;}

.top_action_links ul{float: right;list-style: none;}

.top_action_links ul li{float: left;margin-left: 10px;}

.top_action_links ul li button{background: none;float: left;border: none;position: relative;}

.eventdetailsview .save_action.active svg path{ fill:#4A8CE8; stroke: #4A8CE8;}

.eventdetailsview .save_action svg path{
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}

.top_action_links ul li button .heart_fill_icon{display: none;position: absolute;left: 0px;top: 0px;}

.top_action_links ul li button:hover .heart_fill_icon,
.top_action_links ul li button.active .heart_fill_icon{display: inline-block;}

.top_action_links ul li button span{width: 24px;height: 24px;float: left;margin-top: 3px;}

.top_action_links ul li button span.active_bookmark{position: absolute;left: 0px;top: 0px;display: none;}

.top_action_links ul li button:hover span.active_bookmark,
.top_action_links ul li button.active span.active_bookmark{display: inline-block;}

.top_action_links ul li button.save_action span.active_bookmark{display: none!important;}

.top_action_links ul li button.save_action.active span.active_bookmark{display: inline-block!important;}

.blog_heading{padding: 16px 0px;}

.blog_heading h3{font-weight: 400;font-size: 30px;line-height: 38px;color: #fff;}

.blog_heading p{font-weight: 400;font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.platinum_event{margin-top: 12px;width: 100px;height: 35px;background: linear-gradient(180deg, #101419 0%, #131820 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);display: flex;justify-content: center;align-items: center;}

.platinum_event svg path{fill: #F37020;fill-opacity: 1;}

.platinum_event span{padding-left: 5px;font-size: 14px;line-height: 20px;color: #F37020;}

.giveaway_btn{width: 110px;margin-bottom: 8px;}

.blog_subheading{border-bottom: 1px solid rgba(255, 255, 255, 0.25);padding-bottom: 32px;}

.blog_subheading h6{color: #fff;font-weight: 600;font-size: 16px;line-height: 24px;}

.blog_subheading p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.content_editor p{margin-bottom: 16px;}

.content_editor .text_info{border-radius: 8px;background: rgba(255, 255, 255, 0.05);padding: 16px;margin-bottom: 24px;}

.content_editor .text_info p:last-child{margin-bottom: 0px;}

.blog_image_carousel{padding-top: 24px;}

.blog_image_carousel h6{color: #fff;font-weight: 600;font-size: 16px;line-height: 24px;margin-bottom: 8px;}

.carousel_subheading{padding-top: 16px;}

.carousel_subheading p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.blog_final_subheading{border-top: 1px solid rgba(255, 255, 255, 0.25);margin-top: 32px;padding-top: 32px;
    padding-bottom: 20px;}

.blog_final_subheading h6{color: #fff;font-weight: 600;font-size: 16px;line-height: 24px;margin-bottom: 8px; }

.blog_final_subheading p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);margin-bottom: 12px;}

.favourites_alert{float: left;padding: 12px;background: #121212;border: 1px solid rgba(255, 255, 255, 0.25);position: fixed;
left: 60%;transform: translateX(-60%);bottom: 50px;min-width: 160px;z-index: 100;}

.favourites_alert p{font-size: 14px;line-height: 20px;color: #fff;white-space: nowrap;}

.partners_alert{min-width: 80px;}

.partners_alert p{text-align: center;}

.event_info{padding-top: 8px;margin-bottom: 32px;}

.event_heading{float: left;}

.event_heading label{font-weight: 500;font-size: 16px;line-height: 24px;width: 100%;float: left;color: var(--light-text);display: flex;
height: 24px;}

.event_heading label svg{float: left;margin-top: 3px;}

.event_heading label strong{float: left;font-weight: 500;}

.event_heading span{width: 100%;float: left;font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;}

.event_heading.event_location svg{position: relative;top: 2px;}

.event_heading.event_location label strong{margin-left: -5px;}

.event_heading.event_full_desc label{color: #fff;font-weight: 700;}

.event_heading  .content_editor p{font-weight: 400;font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.event_heading  .content_editor ul{margin-left: 16px;margin-bottom: 16px;}

.event_heading  .content_editor ul li{font-weight: 400;font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.booknow_btn{padding: 0px 0px 32px 0px;background: rgba(18, 18, 18, 0.9);}

.booknow_btn button{width: 100%;height: 56px;float: left;background: #F37020;color: #fff;font-weight: 400;font-size: 16px;
    line-height: 24px;text-align: center;border: none;border-radius: 8px;}

.booknow_btn a{width: 100%;height: 56px;float: left;background: #F37020;color: #fff;font-weight: 400;font-size: 16px;
        line-height: 56px;text-align: center;border: none;text-decoration: none;border-radius: 8px;}

.event_details_container .blog_carousel{min-height: 480px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;}

/** end of home blog event pages styles **/

/** start of event styles **/

.events_filter_wrapper{margin: 16px 0px 0px 0px;}

.cal_link{float: left;margin-right: 16px;}

.cal_link a{width: 48px;height: 44px;background: rgba(255, 255, 255, 0.02);border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(4px);border-radius: 4px;display: flex;justify-content: center;align-items: center;}

.events_filter_wrapper ul{float: left;width: calc(100% - 64px);list-style: none;display: flex;overflow: auto;
margin-top: 6px;}

.events_filter_wrapper ul{-ms-overflow-style: none;scrollbar-width: none;overflow: -moz-scrollbars-none;}

.events_filter_wrapper ul::-webkit-scrollbar { display: none; }

.events_filter_wrapper ul li{float: left;margin-right: 12px;}

.events_filter_wrapper ul li button{float: left;padding: 8px 13.6px;background: rgba(255, 255, 255, 0.05);box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;border: none;font-size: 12px;line-height: 18px;color: #fff;transition: .3s all;}

.events_filter_wrapper ul li button:hover,.events_filter_wrapper ul li button:focus{background: #F37020;}

.events_filter_wrapper ul li.selected button{background: #F37020;}

.event_list_wrapper h2{font-weight: 400;font-size: 20px;line-height: 30px;color: rgba(255, 255, 255, 0.45);margin-bottom: 16px;}

.event_list{padding-bottom: 0px;margin-bottom: 40px;}

.event_content{border-bottom: 2px solid rgba(255, 255, 255, 0.15);margin-bottom: 16px;}

.event_content:last-child{border-bottom: none;margin-bottom: 0px;}

.no_events_wrapper{padding: 12px;border-radius: 8px;display: flex;align-items: center;margin: 16px 0px;
    border: 1px solid #F04438;color: #fff;background: rgba(255, 255, 255, 0.05);}

.no_events_wrapper img{margin-right: 10px;}

.event_img .event-post-img{border-radius: 8px;height: 100%;object-fit: cover;object-position: top center;}

.event_img_wrap .cal_event_platinum{position: absolute;top: 10px;right: 10px;}

.event_loc_date{padding-top: 16px;margin-top: 16px;border-top: 1px solid #3C3C3C;}

.event_short_info{background: rgba(255, 255, 255, 0.05);border-radius: 8px;padding: 12px;}

.event_short_info p{font-weight: 400;font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.event_full_desc{background: rgba(255, 255, 255, 0.05);border-radius: 8px;padding: 12px;margin-top: 16px;}

.calendar_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;padding-top: 40px;}

/** end of event styles **/

/** start of calender styles **/

.cal_events_wrapper{padding: 64px 0px 0px 0px;}

.cal_events_list{border-bottom: 1px solid rgba(255, 255, 255, 0.25);padding-bottom: 24px;margin-bottom: 24px;position: relative;}

.cal_events_list .img_wrap{float: left;margin-right: 24px;}

.cal_events_list .img_wrap img{object-fit: cover;}

.cal_events_info{width: calc(100% - 308px);height: 288px;float: left;position: relative;}

.cal_events_info h5{font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;margin-bottom: 5px;}

.cal_events_info p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.cal_events_bottom{position: absolute;left: 0px;bottom: 0px;}

.cal_event_date_time{float: left;}

.cal_event_date_time span{font-weight: 500;font-size: 14px;line-height: 20px;color: rgba(244, 243, 232, 0.45);float: left;}

.cal_event_date_time .circle{float: left;width: 4px;height: 4px;background: rgba(244, 243, 232, 0.45);border-radius: 50%;
    margin: 7px 8px 0px 8px;}

.cal_event_platinum{float: right;}

.cal_event_platinum svg{float: left;margin-right: 8px;}

.cal_event_platinum svg path{fill: #F37020;fill-opacity: 1;}

.cal_event_platinum span{float: left;font-size: 14px;line-height: 25px;color: #F37020;}

.event_details_link{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;}

/** end of calender styles **/

/** start of notification styles **/

.notification_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;padding-top: 16px;}

.notification_wrapper h5{color: #fff;font-size: 20px;line-height: 30px;margin-bottom: 16px;width: 100%;float: left;}

.notification_wrapper h5.older_notification{border-bottom: 1px solid rgba(255, 255, 255, 0.15);padding-bottom: 12px;}

.notification_list{width: 100%;float: left;padding: 16px 10px;margin-bottom: 16px;}

.notification_list_recent{background: rgba(255, 255, 255, 0.08);border-radius: 8px;}

.notification_list .img_wrap{float: left;margin-right: 47px;height: 155px;}

.notification_text_info{float: left;width: calc(100% - 211px);height: 155px;position: relative;}

.notification_text_info h6{color: #fff;font-weight: 600;font-size: 18px;line-height: 28px;}

.notification_text_info p{font-size: 16px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.notification_bottom_info{position: absolute;left: 0px;bottom: 0px;}

.notification_date_time{float: left;}

.notification_date_time span{float: left;font-weight: 500;font-size: 14px;line-height: 20px;
    color: rgba(244, 243, 232, 0.45);}

.notification_date_time .circle{float: left;width: 4px;height: 4px;background: rgba(244, 243, 232, 0.45);border-radius: 50%;
    margin: 7px 8px 0px 8px;}

.empty_notification_container{width: 100%;height: calc(100vh - 105px);display: flex;flex-direction: column;
    justify-content: center;align-items: center;}

.empty_notification_content{width: 219px;height: 224px;padding: 32px;border: 2px dashed rgba(244, 243, 232, 0.15);
    display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 8px;}

.empty_notification_content img{margin-bottom: 32px;}

.empty_notification_content p{font-size: 16px;line-height: 24px;color: #FFFFFF;text-align: center;}

/** end of notification styles **/

/* start of profile styles */

.profile_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;padding-top: 40px;}

.userinfo_details{padding-bottom: 48px;}

.profile_img{float: left;margin-right: 16px;}

.profile_text{float: left;}

.profile_text h3{font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;margin-bottom: 6px;text-transform: capitalize;}

.profile_text h4{font-weight: 400;font-size: 14px;line-height: 20px;color: #fff;margin-bottom: 18px;}

.profile_text .edit_photo{width: 85px;height: 28px;border: 1px solid rgba(244, 243, 232, 0.25);filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    background: none;color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;border-radius: 100px;}

.usersettings_details{margin-bottom: 32px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;padding: 16px 16px 0px 16px;}

.settings_heading img{float: left;margin-right: 8px;}

.settings_heading h6{font-weight: 500;font-size: 14px;line-height: 24px;color: #F37020;float: left;}

.settings_list ul{width: 100%;float: left;list-style: none;}

.settings_list ul li{width: 100%;float: left;border-bottom: 1px solid rgba(255, 255, 255, 0.15);}

.settings_list ul li:last-child{border-bottom: none;}

.settings_list ul li button{background: none;width: 100%;float: left;padding: 16px 0px;border: none;color: #fff;font-weight: 400;
    font-size: 16px;line-height: 24px;text-align: left;}

.settings_list ul li a{background: none;width: 100%;float: left;padding: 16px 0px;border: none;color: #fff;font-weight: 400;
    font-size: 16px;line-height: 24px;text-align: left;text-decoration: none;}

.userlogout_link{border-top: 1px solid rgba(255, 255, 255, 0.25);padding-top: 16px;margin-top: 32px;padding-bottom: 40px;}

.userlogout_link button{background: #121212;border: 1px solid rgba(255, 255, 255, 0.15);width: 100%;float: left;height: 56px;
    color: #fff;font-weight: 400;font-size: 16px;line-height: 24px;border-radius: 8px;}

.profile_heading{margin-bottom: 32px;}

.profile_heading h5{color: #fff;font-weight: 500;font-size: 20px;line-height: 30px;margin-bottom: 8px;}

.profile_heading p{color: rgba(255, 255, 255, 0.45);font-weight: 500;font-size: 16px;line-height: 24px;}

.form-field{margin-bottom: 20px;position: relative;}

.form-field label{margin-bottom: 5px;font-weight: 500;font-size: 14px;line-height: 20px;color: #F4F3E8;}

.form-field input{width: 100%;float: left;font-size: 16px;height: 44px;padding: 6px 15px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);
    border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 8px;color: #fff;
    outline: none;transition: .3s all;}

.form-field input:focus{border: 1px solid #fff;}

.form-field .readonly_field{width: 100%;float: left;font-size: 16px;height: 44px;padding: 0px 15px;background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 8px;color: #fff;line-height: 42px;}

.form-field .postcode-field{width: 25%;float: left;padding-right: 16px;}

.form-field .select-country-field{width: 75%;float: left;}

.form-field .select-country-field select{width: 100%;float: left;font-size: 16px;height: 44px;padding: 6px 14px!important;
    background-color: var(--dark-black, #121212);border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 8px;color: var(--light-text);
    outline: none;transition: .3s all;appearance: none;background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/select-arrow-down.svg) !important;
    background-size: 18px !important;background-position: 98% 14px !important;background-repeat: no-repeat !important;}

.form-field .select-country-field select:focus{border: 1px solid #fff;}

.form-field .select-country-code{width: 25%;float: left;padding-right: 16px;}

.form-field .select-country-code select{width: 100%;float: left;font-size: 16px;height: 44px;padding: 6px 12px;background: var(--dark-black, #121212);
    border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 8px;color: var(--light-text);outline: none;transition: .3s all;appearance: none;
    background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/select-arrow-down.svg) !important;
    background-size: 18px !important;background-position: 94% 14px !important;background-repeat: no-repeat !important;}

.form-field.select-country-code select:focus{border: 1px solid #fff;}

.form-field.select-field select{width: 100%;float: left;font-size: 16px;height: 44px;padding: 6px 12px;background: var(--dark-black, #121212);
    border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 8px;color: var(--light-text);outline: none;transition: .3s all;appearance: none;
    background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/select-arrow-down.svg) !important;
    background-size: 18px !important;background-position: 98% 12px !important;background-repeat: no-repeat !important;}

.form-field.select-field select:focus{border: 1px solid #fff;}

.form-field .phone-field-input{width: 75%;float: left;}

.form-field .edit_link{font-weight: 500;font-size: 14px;line-height: 20px;color: #F37020;text-decoration: none;position: absolute;
    right: 12px;bottom: 12px;}

.form-field .icon_img{position: absolute;right: 15px;bottom: 12px;background: none;border: none;}

.form-field input.input_error{border: 1px solid #f31415 !important;}

.form-field .error-msg{font-size: 11px;color: #f31415;position: absolute;right: 0px;bottom: -15px;line-height: 12px;}

.btn-field{margin-top: 30px;}

.btn-field button{width: 100%;float: left;height: 56px;background: #F37020;border: 1px solid rgba(255, 255, 255, 0.15);font-weight: 400;
    font-size: 16px;line-height: 24px;color: #fff;border-radius: 8px;}

.btn-field a{width: 100%;float: left;height: 56px;background: #F37020;border: 1px solid rgba(255, 255, 255, 0.15);font-weight: 400;
    font-size: 16px;line-height: 54px;color: #fff;text-align: center;text-decoration: none;}

.info_form_container .form-field label{color: rgba(255, 255, 255, 0.45);margin-bottom: 8px;}

.info_form_container .form-field .readonly_field{background: transparent;height: 54px;line-height: 52px;font-size: 20px;}

.form-field .upgrade_btn{padding: 0px 24px;background: #F37020;border: none;position: absolute;right: 12px;bottom: 12px;color: #F4F3E8;
    font-weight: 600;font-size: 12px;height: 30px;line-height: 28px;text-decoration: none;border-radius: 8px;}

.form-field .status_text{font-size: 20px;color: #32D583;}

.form-field .field-valid{position: absolute;right: 12px;bottom: 16px;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.card_details_field{margin-bottom: 20px;}

.card_details_field label{font-weight: 500;font-size: 14px;line-height: 20px;color: #F4F3E8;margin-bottom: 16px;}

.card_details_info{height: 56px;border: 1px solid rgba(255, 255, 255, 0.15);padding: 15px 12px;border-radius: 8px;}

.card_details_info img{float: left;margin-right: 5px;}

.card_details_info span{float: left;font-size: 16px;line-height: 24px;color: #fff;}

.card_details_info button{float: right;background: none;border: none;color: #F04438;font-weight: 500;font-size: 14px;line-height: 30px;}

.new_payment_btn img{margin-right: 5px;}

.new_payment_btn button{width: 100%;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(244, 243, 232, 0.25);
    height: 56px;font-weight: 400;font-size: 16px;line-height: 54px;color: #F4F3E8;display: flex;justify-content: center;
    align-items: center;border-radius: 8px;}

.checkbox-field{width: 100%;float: left;}

.checkbox_type {display: block;position: relative;padding-left: 35px;margin-bottom: 20px;cursor: pointer;-webkit-user-select: none;
    -moz-user-select: none;-ms-user-select: none;user-select: none;font-weight: 500;font-size: 14px;line-height: 20px;color: #fff;}
  
.checkbox_type input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
  
.checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;background-color: transparent;border: 2px solid #F37020;}
  
.checkbox_type input:checked ~ .checkmark {background-color: #F37020;}
  
.checkmark:after {content: "";position: absolute;display: none;}
  
.checkbox_type input:checked ~ .checkmark:after {display: block;}

.checkbox_type .checkmark:after {left: 6px;top: 1px;width: 6px;height: 12px;border: solid #121212;border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}

.favourites_container .search_field_wrapper{padding:0px;}

.favourites_container .search_field_wrapper .search_input{width: 100%;}

/* end of profile styles */

/* start of horse racing styles */

/* .racing_tab_wrapper{padding: 30px 0px 0px 0px;} */

.racing_tab_wrapper .row{margin-left: -15px;margin-right: -15px;}

.horse_info_container{border: 1px solid rgba(255, 255, 255, 0.15);padding: 16px;position: relative;border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);}

.horse_info_container .horse_link,.audio_update_container .horse_link,.video_update_container .horse_link{width: 100%;height: 85px;position: absolute;left: 0px;top: 0px;}

.racing_tab_wrapper .card_content{display: flex;margin-bottom: 30px;padding-left: 15px;padding-right: 15px;}

.card_head .horse_name{float: left;}

.card_head .horse_name span{float: left;color: #fff;font-size: 16px;line-height: 24px;padding-right: 12px;}

.card_head .horse_name img{float: left;height: auto;margin-top: 3px;}

.entry_btn{height: 22px;background: #4D8293;float: right;color: #EDF8FB;font-weight: 500;font-size: 12px;
    line-height: 18px;border: none;border-radius: 100px;padding: 0px 10px;}

.declared_btn{background: #669F2A;}

.race_result_btn{background: #222;}

.card_head{padding-bottom: 16px;}

.card_subhead{border-bottom: 1px solid rgba(255, 255, 255, 0.25);padding-bottom: 16px;margin-bottom: 16px;}

.loc_info{float: left;}

.loc_info img{float: left;margin-right: 6px;}

.loc_info span{float: left;color: #fff;font-weight: 500;font-size: 12px;line-height: 16px;}

.date_info{width: 100%;position: absolute;top: 30px;right: -50px;}

.date_info img,.date_info svg{float: left;margin-right: 6px;}

.date_info svg path{fill: rgba(255, 255, 255, 0.45);}

.date_info span{float: left;color: rgba(255, 255, 255, 0.45);font-size: 12px;font-weight: 500;line-height: 18px;}

.horse_list_info{min-height: 140px;}

.horse_pos_info{min-height: 180px;}

.horse_list_info ul{list-style: none;margin-bottom: 12px;}

.horse_list_info li span{width: 100%;float: left;font-weight: 700;font-size: 12px;line-height: 15px;
    color: rgba(244, 243, 232, 0.45); padding-bottom: 4px;}

.race_info_list li.race_jockey_list{width: 125px;}

.horse_list_info li strong{width: 100%;float: left;color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.postion_info{padding: 4px 10px;color: #fff;font-size: 14px;line-height: 20px;float: left;margin-bottom: 15px;font-weight: 500;
    border-radius: 4px;background: #FDB022;backdrop-filter: blur(66px);border: 1px solid transparent;}

.second_position{background: linear-gradient(0deg, #797979 0%, #797979 100%), linear-gradient(159deg, rgba(255, 255, 255, 0.16) 3.01%, rgba(255, 255, 255, 0.00) 103.3%);}

.other_position{background: none;border: 1px solid #fff;}

.horse_list_race_info{height: 165px;}

.race_info_list{border-bottom: 1px dashed rgba(255, 255, 255, 0.15);padding-bottom: 12px;}

.race_info_list li{float: left;border-right: 1px solid rgba(255, 255, 255, 0.25);padding-right: 12px;
    margin-right: 12px;}

.race_info_list li:last-child{border-right: none;}

.trnr_jcky_info_list{padding-bottom: 12px;}

.trnr_jcky_info_list li{float: left;border-right: 1px solid rgba(255, 255, 255, 0.25);padding-right: 12px;margin-right: 12px;}

.trnr_jcky_info_list li.dis_type{width: 128px;}

.trnr_jcky_info_list li.purse_type{width: 115px;}

.trnr_jcky_info_list li:last-child{border-right: none;}

.card_content  .like_info{position: absolute;left: 0px;bottom: 16px;padding: 0px 16px;}

.like_info .like{float: left;}

.like_info .like button{background: none;border: none;float: left;width: 24px;height: 24px;}

.like_info .day_text{font-size: 14px;line-height: 24px;color: rgba(244, 243, 232, 0.45);}

.video_update_container{padding: 16px;position: relative;border-radius: 12px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.05);}

.video_update_container .horse_text{border-bottom: none;}

.horse_text{margin-bottom: 12px;position: relative;border-bottom: 1px solid rgba(255, 255, 255, 0.25);padding-bottom: 12px;}

.horse_status_info{float: right;position: absolute;right: 0px;top: 6px;width: 135px;}

.horse_thumb_img{width: 54px;height: 54px;float: left;margin-right: 10px;}

.horse_thumb_img img{object-fit: cover;border-radius: 8px;}

.horse_short_info{width: calc(100% - 72px);float: left;height: 54px;display: flex;flex-direction: column;justify-content: space-evenly;}

.horse_short_info h5{width: 100%;float: left;color: #fff;font-weight: 500;font-size: 16px;line-height: 24px;margin-bottom: 6px;
    display: flex;align-items: center;}

.horse_short_info h5 img{margin-right: 6px;}

.horse_short_info .loc_info svg{float: left;margin-right: 6px;}

.horse_short_info .loc_info svg path{fill: rgba(255, 255, 255, 0.45);}

.horse_short_info .loc_info span{color: rgba(255, 255, 255, 0.45);}

.video_update_container h4{color: #fff;font-weight: 400;font-size: 16px;line-height: 24px;margin-bottom: 16px;}

.video_update_container .video_update_text h4{margin: 0px;padding: 16px;}

.video_update_container .img_wrap{width: calc(100% + 32px);height: 416px;float: left;margin-left: -16px;margin-bottom: 16px;
    position: relative;}

.video_update_container .img_wrap img{width: 100%;float: left;height: 100%;object-fit: cover;}

.video_update_container .video_play_btn{background: none;border: none;position: absolute;left: 50%;top: 50%;
    transform: translate(-50%,-50%);}

.video_update_container .text_info{min-height: 60px;padding-bottom: 40px;}

.video_update_container .text_info p{font-size: 14px;line-height: 20px;color: rgba(244, 243, 232, 0.45);}

.status_update_container{background: rgba(255, 255, 255, 0.05);border: 1px solid rgba(255, 255, 255, 0.15);padding: 16px 0px;
    position: relative;border-radius: 12px;}

.status_update_container h4{color: #fff;font-weight: 400;font-size: 16px;line-height: 24px;margin-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);padding: 0px 16px 16px 16px;}

.status_update_container .text_info{margin-bottom: 16px;height: 60px;padding: 0px 16px;}

.status_update_container .text_info p{font-size: 14px;line-height: 20px;color: rgba(244, 243, 232, 0.45);}

.status_update_container .horse_text{padding: 0px 16px 12px 16px;}

.audio_update_container .text_info{height: auto;}

.audio_player_container{height: 90px;padding: 0px 16px;}

.audio_player_container img{border-radius: 100px;}

.rhap_container {box-sizing: border-box;display: flex;flex-direction: column;line-height: 1;font-family: inherit;width: 100%;
    padding: 10px 15px;background-color: #fff;box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);}

.rhap_container:focus:not(:focus-visible) {outline: 0;}

.rhap_container svg {vertical-align: initial;}
  
.rhap_header {margin-bottom: 10px;}
  
.rhap_footer {margin-top: 5px;}
  
.rhap_main {display: flex;flex-direction: column;flex: 1 1 auto;}
  
.rhap_stacked .rhap_controls-section {margin-top: 8px;}
  
.rhap_horizontal {flex-direction: row;}

.rhap_horizontal .rhap_controls-section {margin-left: 8px;}
  
.rhap_horizontal-reverse {flex-direction: row-reverse;}

.rhap_horizontal-reverse .rhap_controls-section {margin-right: 8px;}
  
.rhap_stacked-reverse {flex-direction: column-reverse;}

.rhap_stacked-reverse .rhap_controls-section {margin-bottom: 8px;}
  
.rhap_progress-section {display: flex;flex: 3 1 auto;align-items: center;}
  
.rhap_progress-container {display: flex;align-items: center;height: 20px;flex: 1 0 auto;align-self: center;
    margin: 0 calc(10px + 1%);cursor: pointer;user-select: none;-webkit-user-select: none;}

.rhap_progress-container:focus:not(:focus-visible) {outline: 0;}
  
.rhap_time {color: #333;font-size: 16px;user-select: none;-webkit-user-select: none;}
  
.rhap_progress-bar {box-sizing: border-box;position: relative;z-index: 0;width: 100%;height: 5px;background-color: #dddddd;
    border-radius: 2px;}
  
.rhap_progress-filled {height: 100%;position: absolute;z-index: 2;background-color: #868686;border-radius: 2px;}
  
.rhap_progress-bar-show-download {background-color: rgba(221, 221, 221, 0.5);}
  
.rhap_download-progress {height: 100%;position: absolute;z-index: 1;background-color: #dddddd;border-radius: 2px;}
  
.rhap_progress-indicator {box-sizing: border-box;position: absolute;z-index: 3;width: 20px;height: 20px;margin-left: -10px;
    top: -8px;background: #868686;border-radius: 50px;box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;}
  
.rhap_controls-section {display: flex;flex: 1 1 auto;justify-content: space-between;align-items: center;}
  
.rhap_additional-controls {display: flex;flex: 1 0 auto;align-items: center;}
  
.rhap_repeat-button {font-size: 26px;width: 26px;height: 26px;color: #868686;margin-right: 6px;}
  
.rhap_main-controls {flex: 0 1 auto;display: flex;justify-content: center;align-items: center;}
  
.rhap_main-controls-button {margin: 0 3px;color: #868686;font-size: 35px;width: 35px;height: 35px;}
  
.rhap_play-pause-button {font-size: 40px;width: 40px;height: 40px;}
  
.rhap_volume-controls {display: flex;flex: 1 0 auto;justify-content: flex-end;align-items: center;}
  
.rhap_volume-button {flex: 0 0 26px;font-size: 26px;width: 26px;height: 26px;color: #868686;margin-right: 6px;}
  
.rhap_volume-container {display: flex;align-items: center;flex: 0 1 100px;user-select: none;-webkit-user-select: none;}
  
.rhap_volume-bar-area {display: flex;align-items: center;width: 100%;height: 14px;cursor: pointer;}

.rhap_volume-bar-area:focus:not(:focus-visible) {outline: 0;}
  
.rhap_volume-bar {box-sizing: border-box;position: relative;width: 100%;height: 4px;background: #dddddd;border-radius: 2px;}
  
.rhap_volume-indicator {box-sizing: border-box;position: absolute;width: 12px;height: 12px;margin-left: -6px;left: 0;
    top: -4px;background: #868686;opacity: 0.9;border-radius: 50px;box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;cursor: pointer;}

.rhap_volume-indicator:hover {opacity: 0.9;}
  
.rhap_volume-filled {height: 100%;position: absolute;z-index: 2;background-color: #868686;border-radius: 2px;}
  
  /* Utils */
.rhap_button-clear {background-color: transparent;border: none;padding: 0;overflow: hidden;cursor: pointer;}

.rhap_button-clear:hover {opacity: 0.9;transition-duration: 0.2s;}

.rhap_button-clear:active {opacity: 0.95;}

.rhap_button-clear:focus:not(:focus-visible) {outline: 0;}
  
.audio_player_container .rhap_container{width: 100%;border-radius: 100px;background: rgba(255, 255, 255, 0.04);
    padding: 12px;box-shadow: none;}

.audio_player_container .rhap_additional-controls,.audio_player_container .rhap_volume-controls{display: none;}

.audio_player_container .rhap_rewind-button,.audio_player_container .rhap_forward-button{display: none;}

.audio_player_container .rhap_time{display: none;}

.audio_player_container .rhap_stacked .rhap_controls-section{margin-top: -22px;}

.audio_player_container .rhap_play-pause-button{width: 30px;height: 30px;font-size: 30px;}

.audio_player_container .rhap_play-pause-button svg path{fill: #fff;}

.audio_player_container .rhap_progress-section{width: calc(100% - 30px);margin-left: 30px;position: relative;top: 3px;
    display: block;}

.audio_player_container .rhap_download-progress{background: rgba(255, 255, 255, 0.25);}

.audio_player_container .rhap_progress-filled{background: #F37020;}

.audio_player_container .rhap_progress-bar{height: 4px;}

.audio_player_container .rhap_progress-indicator{background: rgb(255, 255, 255);width: 4px;height: 12px;border-radius: 0px;
    top: -4px;margin-left: 0px;}

/* end of horse racing styles */

/* start of platinum styles */

.join_platinum_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);padding-top: 64px;margin-top: 16px;}

.join_platinum_content h3{font-size: 24px;line-height: 32px;color: #fff;font-weight: 400;margin-bottom: 8px;}

.multiline_bar{margin-bottom: 16px;}

.join_platinum_content p{font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.platinum_video{margin: 24px 0px;}

.platinum_benefits h4{font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;margin-bottom: 4px;}

.platinum_benefits p{font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.benefits_wrapper{margin-top: 24px;margin-left: -12px;margin-right: -12px;}

.benefits_wrapper .col-lg-6{padding-left: 12px;padding-right: 12px;}

.benefit_list{background: #121212;border: 1px solid rgba(255, 255, 255, 0.15);margin-bottom: 24px;position: relative;}

.benefit_list img{width: 100%;float: left;}

.benefit_list h6{width: 100%;float: left;text-align: center;padding: 16px 0px;color: #fff;font-weight: 400;font-size: 16px;
    line-height: 24px;}

.benefit_list a{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;color: transparent;}

.experience_plans_wrapper h4{font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;margin-top: 10px;margin-bottom: 24px;}

.experience_plans_wrapper ul{list-style: none;}

.experience_plans_wrapper ul li{width: 100%;float: left;padding: 24px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);min-height: 158px;margin-bottom: 24px;}

.experience_plans_wrapper ul li.active{border: 1px solid #F37020;}

.experience_name{border-bottom: 1px solid rgba(244, 243, 232, 0.15);padding-bottom: 24px;margin-bottom: 24px;}

.experience_name h5{float: left;font-weight: 300;font-size: 16px;line-height: 24px;letter-spacing: 0.12em;color: #F4F3E8;}

.experience_name  .radiobtn{float: right;}

.radiobtn {display: block;position: relative;padding-left: 16px;margin-bottom: 0px;cursor: pointer;font-size: 20px;-webkit-user-select: none;
    -moz-user-select: none;-ms-user-select: none;user-select: none;}
  
.radiobtn input {position: absolute;opacity: 0;cursor: pointer;}

.radiobtn .checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;background-color: transparent;border-radius: 50%;
    border: 2px solid #F37020;}

.radiobtn:hover input ~ .checkmark {background-color: transparent;}

.radiobtn input:checked ~ .checkmark {background-color: transparent;}

.checkmark:after {content: "";position: absolute;display: none;}

.radiobtn input:checked ~ .checkmark:after {display: block;}

.radiobtn .checkmark::after {content: '';top: 50%;left: 50%;transform: translate(-50%,-50%);width: 14px;height: 14px;
    border-radius: 50%;background: #F37020;border: 2px solid #121212;}

.experience_price strong{float: left;font-weight: 300;font-size: 30px;line-height: 38px;color: #F4F3E8;}

.experience_price strong small{font-size: 12px;line-height: 18px;}

.experience_price span{float: right;padding: 6px 12px;background: rgba(244, 243, 232, 0.06);border: 1px solid rgba(244, 243, 232, 0.16);
    font-size: 14px;line-height: 20px;color: #F4F3E8;}

.platinum_btn_wrapper{padding: 16px;border-top: 1px solid rgba(255, 255, 255, 0.25);}

.platinum_btn_wrapper button{width: 100%;height: 56px;float: left;background: #F37020;color: #fff;border: none;}

.platinum_btn_wrapper a{width: 100%;height: 56px;float: left;background: #F37020;color: #fff;border: none;line-height: 56px;
    text-decoration: none;text-align: center;}

.video_wrapper{height: 410px;background: #272727;position: relative;margin-bottom: 24px;}

.video_wrapper .video_play_btn{position: absolute;left: 50%;top: 50%;background: none;border: none;transform: translate(-50%,-50%);}

.video_wrapper video{width: 100%;height: 100%;object-fit: cover;object-position: 50% 50%; cursor: pointer;}

.video_wrapper.video_portrait video{object-fit: contain;}

.video_portrait video{object-fit: contain;}

.horse_purchase_container{padding-bottom: 24px;}

.horse_purchase_content{padding-bottom: 17px;}

.horse_purchase_content h6{color: var(--light-white);font-weight: 600;font-size: 16px;line-height: 24px;margin-bottom: 4px;}

.horse_purchase_content p{font-size: 14px;line-height: 20px;color: var(--light-text);margin-bottom: 15px;}

.horse_purchase_content p.light-white{font-weight: 500;color: rgba(255, 255, 255, 0.65);}

.horse_purchase_content ol{margin-left: 16px;}

.horse_purchase_content ol li{font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);margin-bottom: 10px;}

.steps_heading ul{list-style: none;margin-bottom: 40px;}

.steps_heading li{width: 25%;float: left;position: relative;height: 67px;}

.steps_heading li h5{color: #fff;font-weight: 500;font-size: 20px;line-height: 30px;margin-bottom: 3px;}

.steps_heading li span{font-size: 12px;line-height: 18px;color: rgba(255, 255, 255, 0.45);}

.steps_heading li::after{content: '';width: calc(100% - 8px);height: 4px;background: rgba(244, 243, 232, 0.25);position: absolute;
    left: 0px;bottom: 0px;}

.steps_heading li:last-child::after{width: 100%;}

.steps_heading li.active::after{background: #F37020;}

.steps_form_container .form_fields_wrapper{width: 320px;float: left;min-height: calc(100vh - 355px);}

.steps_form_container .form-field input{background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(255, 255, 255, 0.15);}

.steps_form_container .form-field input:focus{border: 1px solid #fff;}

.steps_form_container .btn-field{border-top: 1px solid rgba(255, 255, 255, 0.25);padding: 16px;}

.form-field label.radiobtn{margin: 0px;padding-left: 30px;}

.steps_form_container .radio_field{width: 100%;float: left;height: 56px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);
    border: 1px solid rgba(244, 243, 232, 0.25);padding: 16px 12px;margin-top: 10px;}

.radio_field .radiobtn input{left: -12px;top: -17px;height: 56px;width: calc(100% + 25px);}

.or-field{position: relative;border-top: 1px solid rgba(244, 243, 232, 0.15);margin-top: 10px;margin-bottom: 24px;}

.or-field span{padding: 0px 10px;position: absolute;left: 50%;transform: translateX(-50%);top: -7px;background: #121212;
    font-weight: 500;font-size: 14px;color: rgba(255, 255, 255, 0.45);}

.link-field a{width: 100%;float: left;height: 56px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);
    border: 1px solid rgba(244, 243, 232, 0.25);padding: 0px 12px;display: flex;align-items: center;font-size: 16px;
    color: #F4F3E8;text-decoration: none;position: relative;}

.link-field a img{position: absolute;right: 12px;top: auto;}

.steps_form_container .user_form_fields_wrapper{width: 100%;}

.user_field_wrapper{border-bottom: 1px solid rgba(255, 255, 255, 0.15);padding: 16px 0px;}

.user_field{float: left;margin-right: 64px;}

.user_field span{width: 100%;float: left;font-weight: 500;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.user_field strong{width: 100%;float: left;font-weight: 400;font-size: 16px;line-height: 24px;color: #fff;}

.radio_field_wrapper{width: 320px;float: left;margin-top: 6px;}

.radio_field_wrapper .radiobtn{font-size: 16px;line-height: 20px;color: #F4F3E8;padding-left: 30px;}

.platinum_progress_container{padding-top: 20px;min-height: calc(100vh - 300px);}

.platinum_progress_container ul{list-style: none;position: relative;}

.platinum_progress_container ul li{width: 100%;float: left;padding: 30px 0px 30px 24px;display: flex;align-items: center;
    border-left: 8px solid rgba(255,255,255,0.15);position: relative;}

.platinum_progress_container ul li::after{content: '';width: 12px;height: 1px;background: rgba(255, 255, 255, 0.25);
    position: absolute;top: 0px;left: 30px;}

.platinum_progress_container ul::after{content: '';width: 12px;height: 2px;background: rgba(255, 255, 255, 0.25);position: absolute;
    bottom: 0px;left: 36px;}

.platinum_progress_container ul li svg{margin-right: 8px;}

.platinum_progress_container ul li span{font-size: 18px;line-height: 28px;color: rgba(255, 255, 255, 0.45);}

.platinum_progress_container ul li.active{border-color: #F37020;}

.platinum_progress_container ul li.active svg path{fill: #F37020;fill-opacity: 1;}

.platinum_progress_container ul li.active span{color: #F37020;}

.racing_concierge_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;padding-top: 40px;}

.racing_concierge_list{margin-bottom: 24px;position: relative;border-radius: 8px;overflow: hidden;
    background: rgba(255, 255, 255, 0.05);}

.racing_concierge_list .img_wrap img{width: 100%;height: auto;}

.racing_concierge_list h4{width: 100%;float: left;font-weight: 400;font-size: 16px;line-height: 24px;color: #fff;
    padding: 16px;}

.racing_concierge_list a,.racing_concierge_list .action_link{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;
    color: transparent;background: none;border: none;}

.datepicker_container{width: 480px;float: left;margin-bottom: 32px;}

.call_info_container{width: 480px;float: left;padding: 16px;background: rgba(255, 255, 255, 0.05);display: flex;
    align-items: center;margin-bottom: 24px;border-radius: 8px;}

.call_info_container svg{width: 32px;height: 32px;margin-right: 16px;}

.call_info_container img{margin-right: 16px;}

.call_info_container span{font-size: 16px;line-height: 24px;color: rgba(255, 255, 255, 0.45);}

.schedule_time_wrapper{position: relative;height: calc(100vh - 130px);min-height: 660px;}

.schedule_time_wrapper .btn_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.25);padding: 16px;position: absolute;
    left: 0px;bottom: 0px;}

.schedule_time_wrapper .btn_wrapper button{width: 100%;height: 56px;float: left;background: #F37020;color: #fff;font-weight: 400;
    font-size: 16px;line-height: 24px;text-align: center;border: none;}

.call_book_modal .modal-dialog{max-width: 380px;}

.giveaway_modal .modal-dialog{max-width: 400px;}

.schedule_time_modal .modal-dialog{max-width: 530px;}

.horse_video_modal .modal-dialog{max-width: 650px;}

.horse_pdf_modal .modal-dialog{max-width: 900px;}

.deposit_modal .modal-dialog {max-width: 400px;}

.schedule_time_modal.show{display: block;}

.deposit_modal.show{display: block;}

.horse_video_modal .modal-dialog .btn-danger{background-color: #F37020;border-color: #F37020;border-radius: 0px;
outline: none;box-shadow: none;}

.horse_video_modal .modal-dialog .btn-danger:focus{box-shadow: none;}

.horse_video_modal .modal-dialog .btn-danger:not(:disabled):not(.disabled).active, 
.horse_video_modal .modal-dialog .btn-danger:not(:disabled):not(.disabled):active, 
.horse_video_modal .modal-dialog .show>.btn-danger.dropdown-toggle{
    background-color: #F37020;border-color: #F37020;}

.call_book_modal .modal-content{background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(255, 255, 255, 0.25);
    padding: 24px;border-radius: 8px;}

.giveaway_modal .modal-content{background: #121212;border: 1px solid rgba(255, 255, 255, 0.25);}

.call_book_modal .modal-header{padding: 0px;border-bottom: 1px solid rgba(255, 255, 255, 0.15);}

.call_book_modal .modal-title{font-weight: 500;font-size: 24px;line-height: 32px;color: var(--light-white);margin-bottom: 8px;
    font-family: var(--playfair-font);}

.call_book_modal .modal-title.event_schedule_heading{font-size: 18px;line-height: 24px;}

.call_book_modal .event_close_modal{margin-top: -5px;}

.call_book_modal .modal-body{max-height: calc(100vh - 230px);overflow-y: auto;overflow-x: hidden;margin-bottom: 12px;margin-top: 12px;
    padding: 10px 0px;width: calc(100% + 16px);}

.call_book_modal .modal-body p{font-size: 16px;line-height: 24px;color: rgba(255, 255, 255, 0.45);margin-bottom: 16px;}

.concierge_services_content{padding: 24px 16px;border-radius: 8px;background: rgba(255, 255, 255, 0.05);width: 94%;
float: left;}

.concierge_services_content ul{width: 100%;float: left;}

.concierge_services_content ul li{width: 100%;float: left;margin-bottom: 12px;}

.concierge_services_content ul li:last-child{margin-bottom: 0px;}

.concierge_services_content ul li span{width: 100%;float: left;color: #FFF;font-size: 14px;line-height: 20px;}

.concierge_services_content ul li strong{color:  #F37020;font-size: 20px;font-weight: 700;line-height: 30px;}

.call_book_modal .modal-footer{padding: 16px 0px 0px 0px;border-top: 1px solid rgba(255, 255, 255, 0.25);}

.call_book_modal .modal-footer a,.call_book_modal .modal-footer button{background: #F37020;border: none;height: 56px;color: #fff;font-weight: 400;font-size: 16px;
    line-height: 24px;border-radius: 8px;width: 100%;display: flex;justify-content: center;align-items: center;text-decoration: none;}

.schedule_time_modal{background: rgba(0,0,0,0.7);}

.schedule_time_modal .schedule_time_wrapper{height: auto;min-height: initial;padding-right: 8px;}

.schedule_time_modal .datepicker_container{width: 480px;margin-bottom: 16px;}

.schedule_time_modal .datepicker_container img{width: 100%;height: auto;}

.schedule_time_modal .call_info_container{width: 100%;margin-bottom: 0px;}

.schedule_time_modal .contact_info_container{width: 100%;float: left;border-radius: 8px;border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(18, 18, 18, 0.90);backdrop-filter: blur(20px);padding: 16px 16px 2px 16px;margin-bottom: 16px;}

.schedule_time_modal .contact_info_container h4{color: rgba(255, 255, 255, 0.65);font-size: 14px;
    font-weight: 600;line-height: 20px;margin-bottom: 20px;}

.contact_info_container .checkmark{border-radius: 4px;border: 1px solid rgba(217, 217, 217, 0.60);
    background: rgba(217, 217, 217, 0.20);}

.contact_info_container .checkbox_type{color: rgba(255, 255, 255, 0.65);font-size: 14px;font-weight: 500;
    line-height: 20px;margin-bottom: 14px;}

.contact_info_container .checkbox_type .checkmark:after{border-color: #fff;}

.contact_info_container .checkbox_type input:checked ~ .checkmark{border: 1px solid transparent;}

.contact_info_container .checkbox_type.error{color: #F04438;}

.contact_info_container .checkbox_type.error .checkmark{border-color: #F04438;}

.contact_info_container.deposit_info_container .checkmark{border-radius: 50%;}

.contact_info_container.deposit_info_container .checkmark::after{content: '';top: 50%;left: 50%;
    transform: translate(-50%, -50%);width: 14px;height: 14px;border-radius: 50%;background: #F37020;
    border: 2px solid #121212;
}

.contact_info_container.deposit_info_container .checkbox_type input:checked ~ .checkmark{
    background-color: #fff;
}

.close_modal{background: none;border: none;}

.datepicker_container .react-calendar{width: 480px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);padding: 24px 24px 10px 24px;border-radius: 8px;}

.datepicker_container .react-calendar__navigation{height: 32px;margin-bottom: 8px;}

.datepicker_container .react-calendar__navigation button{color: #fff;min-width: initial;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__arrow{font-size: 24px;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__arrow:hover,
.datepicker_container .react-calendar__navigation button.react-calendar__navigation__arrow:focus{background: transparent!important;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__prev2-button{display: none;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__next2-button{display: none;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__label:hover,
.datepicker_container .react-calendar__navigation button.react-calendar__navigation__label:focus{background: transparent!important;}

.datepicker_container .react-calendar__year-view .react-calendar__tile, 
.datepicker_container .react-calendar__decade-view .react-calendar__tile, 
.datepicker_container .react-calendar__century-view .react-calendar__tile{
    padding: 1em 0.5em;
}

.datepicker_container .react-calendar button:enabled:hover{background-color: #F37020;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__label{font-size: 16px;}

.datepicker_container .react-calendar__month-view__weekdays{background: rgba(255, 255, 255, 0.05);color: rgba(255, 255, 255, 0.45);
    font-size: 16px;font-weight: 400;margin-bottom: 8px;border-radius: 8px;}

.datepicker_container .react-calendar__month-view__weekdays__weekday{padding: 16px 8px;}

.datepicker_container .react-calendar__tile:enabled:hover, .datepicker_container .react-calendar__tile:enabled:focus{
    background: #F37020;
}

.datepicker_container abbr[title], .datepicker_container dfn[title]{text-decoration: none;border-bottom: none;text-transform: capitalize;}

.datepicker_container .react-calendar__tile{padding: 12px 6px;color: #fff;border-radius: 4px;}

.datepicker_container .react-calendar__tile--active{background: #F37020;}

.datepicker_container .react-calendar__tile--now{background: #4a4a4a;}

.datepicker_container .react-calendar__tile--active:enabled:hover, 
.datepicker_container .react-calendar__tile--active:enabled:focus{background: #F37020;}

/* end of platinum styles */

/* start of racing styles */

.next_races_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 15px;}

.nexttogo_info{padding: 24px 0px;}

.nexttogo_info span{color:var(--light-white);font-size: 16px;font-weight: 600;line-height: 24px;margin-right: 8px;}

.nexttogo_info strong{color: var(--light-text);font-size: 14px;font-weight: 500;line-height: 20px;}

.nextraces_cards_wrapper{display: flex;overflow: auto;gap: 20px;padding-bottom: 10px;}

.nextraces_cards_wrapper::-webkit-scrollbar {width: 10px;height: 8px;}

.nextrace_card{display: flex;width: 242.6px;flex-direction: column;align-items: flex-start;
    gap: 8px;flex-shrink: 0;border-radius: 6px;border: 1px solid #343432;background: #121212;}

.nextrace_card a{display: block;text-decoration: none;width: 100%;padding: 12px 10px 12px 12px;}

.venue_head_info{display: flex;align-items: center;}

.venue_head_info img{margin-right: 8px;}

.venue_head_info span{color: var(--light-white);font-size: 16px;font-weight: 500;line-height: 24px;}

.race_time_info{display: flex;align-items: center;width: 100%;}

.race_time_info span{color: #7D7D7D;font-size: 14px;font-weight: 500;line-height: 20px;flex: 1 0 0;}

.race_time_info strong{display: flex;padding: 0px 7px;justify-content: center;align-items: center;border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.15);font-family: var(--inter-font);font-size: 12px;
    font-weight: 500;line-height: 19px;height: 21px;}

.race_time_info strong.orange_bg{background: #F37020;color: var(--light-white);}

.race_time_info strong.yellow_bg{background: #F4C455;color: #121212;}

.race_time_info strong.grey_bg{background: #2E2E2E;color: var(--light-white);}

.arion_racing_wrapper{display: flex;gap: 40px;padding: 20px 0px 40px 0px;}

.arion_racing_card{display: flex;flex-direction: column;align-items: flex-start;flex: 1 0 0;
    background: rgba(255, 255, 255, 0.05);border-radius: 0px 0px 6px 6px;}

.arion_racing_card_content{display: flex;flex-direction: column;padding-bottom: 12px;position: relative;width: 100%;}

.arion_racing_card_content img{margin-bottom: 12px;border-radius: 6px;}

.arion_racing_card_content img.hide_mobile{width: 100%;height: calc(100vh - 385px);object-fit: cover;min-height: 240px;}

.arion_racing_card_content img.arion_stable_img{object-position: top center;}

.arion_racing_card_content img.racing_data_img{object-position: 10% 0%;}

.arion_racing_card_content h4{padding-left: 12px;padding-bottom: 4px;color: var(--light-white);font-size: 16px;
    font-weight: 500;line-height: 24px;}

.arion_racing_card_content p{color: var(--light-text);font-size: 12px;font-weight: 500;line-height: 18px;padding: 0px 12px;}

.arion_racing_card_content a{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;}

/* end of racing styles */

/* start of stable styles */

.right_content .heading .back_link img{height: 38px;display: block;margin-right: 12px;}

.stable_wrapper{padding-top: 40px;border-top: 1px solid rgba(255, 255, 255, 0.15);}

.stable_wrapper .stable_list{margin-left: -10px;margin-right: -10px;}

.stable_wrapper .stable_list .col-6{padding-left: 16px;padding-right: 16px;}

.stable_list .col-12{padding-left: 15px;padding-right: 15px;margin-bottom: 24px;}

.stable_list .card_container{width: 100%;float: left;position: relative;background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);border-radius: 8px;margin-bottom: 20px;overflow: hidden;
    box-shadow: 0px 0px 6px #F37020;}

.member_tab_right_content .stable_list .card_container{box-shadow: none;}

.stable_list .col-lg-6{padding: 0px 16px 0px 36px;margin-bottom: 44px;position: relative;}

.stable_list .stable_link{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;color: #101419;}

.card_container .img_wrap{width: 140px;height: 140px;float: left;margin-right: 15px;background: #2A2A2A;}

.card_container .img_wrap img{width: 100%;height: 100%;float: left;object-fit: cover;border-radius: 8px;}

.stable_card_content{width: calc(100% - 155px);float: left;padding: 6px 0px 12px 0px;}

.stable_card_content h5{font-size: 18px;line-height: 30px;color: #fff;font-weight: 500;margin-bottom: 8px;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.stable_card_content h6{font-size: 16px;line-height: 24px;float: left;color: rgba(255, 255, 255, 0.45);font-weight: 500;}

.stable_card_content img{float: left;margin: 8px 8px 0px 0px;}

.stable_card_content ul{width: 100%;float: left;list-style: none;margin: 94px 0px 0px 0px;}

.stable_card_content ul li{float: left;margin-right: 8px;font-weight: 500;font-size: 16px;line-height: 28px;
    color: rgba(255, 255, 255, 0.45);}

.stable_card_content ul li span{height: 28px;float: left;color: #fff;text-align: center;line-height: 28px;padding: 0px 8px;
border-radius: 100px;}

.stable_card_content ul li span.first_pos{background: #22B074;}

.stable_card_content ul li span.secound_pos{background: #0086C9;}

.stable_card_content ul li span.third_pos{background: #71300B;}

.stable_card_content ul li span.run_pos{background: #7C7C7C;}

.stable_card_content ul li span.dnf_pos{background: #D92D20;}

.stable_card_content ul li span.pos_1st,.stable_card_content ul li span.pos_1ST{background: #22B074;}

.stable_card_content ul li span.pos_2nd,.stable_card_content ul li span.pos_2ND{background: #0086C9;}

.stable_card_content ul li span.pos_3rd,.stable_card_content ul li span.pos_3RD{background: #71300B;}

.stable_card_content ul li span.pos_run,.stable_card_content ul li span.pos_RUN{background: #7C7C7C;}

.stable_card_content ul li span.pos_dnf,.stable_card_content ul li span.pos_DNF{background: #D92D20;}

.stable_card_content ul li span.pos_na,.stable_card_content ul li span.pos_NA{background: rgba(255, 255, 255, 0.08);}

.horse_details_info{float: left;margin-bottom: 12px;}

.trainer_detl_info{width: calc(100% - 110px);}

.horse_details_info label{width: 100%;float: left;color: var(--light-text);font-family: var(--inter-font);font-size: 11px;
    font-weight: 500;line-height: 20px;}

.horse_details_info p{width: 100%;float: left;color: rgba(255, 255, 255, 0.70);font-family: var(--inter-font);font-size: 13px;
    font-weight: 500;line-height: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.horse_desc_info{border-right: 1px solid #424242;width: 90px;padding-right: 10px;margin-right: 10px;}

.top_static_action_links{position: relative;padding: 12px 0px;background: none;margin-bottom: 20px;height: auto;}

.card_container .img_wrap span{padding: 4px;position: absolute;bottom: 10px;left: 10px;background: #000;border-radius: 4px;
    font-size: 14px;line-height: 18px;color: #fff;font-weight: 500;}

.card_container .img_wrap span.rising_info{color: #F37020;}

.card_container .img_wrap span.rising_info img{
    filter: brightness(0) saturate(100%) invert(46%) sepia(100%) saturate(1537%) hue-rotate(349deg) brightness(100%) contrast(92%);
}

.card_container .img_wrap span img{width: auto;height: auto;float: none;padding: 0px 5px 0px 5px;position: relative;top: 2px;}

.stable_tabs_wrapper .row{margin-left: -15px;margin-right: -15px;}

.stable_tabs_container{margin-top: 40px;padding-left: 15px;padding-right: 15px;padding-bottom: 40px;}

.stable_tabs_container .nav-tabs{margin-bottom: 24px;border: 1px solid rgba(244, 243, 232, 0.15);height: 60px;padding: 0px 16px;
    border-radius: 100px;}

.stable_tabs_container .nav-tabs .nav-item{display: flex;flex: 1 1;justify-content: center;align-items: center;}

.stable_tabs_container .nav-tabs .nav-link{color: rgba(255, 255, 255, 0.45);display: flex;flex: 1;justify-content: center;
    border-bottom: 2px solid transparent;font-size: 16px;border-left: none;border-right: none;border-top: none;background: transparent;
transition: .3s all;}

.stable_tabs_container .nav-tabs .nav-item.show .nav-link, .stable_tabs_container .nav-tabs .nav-link.active{background: none;
    border-bottom: 2px solid #F37020;color: #F37020;border-left: none;border-top: none;border-right: none;
    filter: drop-shadow(0px 0px 30px #F37020);}

.stable_tabs_container .nav-tabs .nav-link:focus, .stable_tabs_container .nav-tabs .nav-link:hover{background: none;border-bottom: 2px solid #F37020;
    color: #F37020;border-left: none;border-top: none;border-right: none;margin: 0px 0px -1px 0px;}

.stable_details_container{margin-bottom: 24px;}

.horse_updates_container{border-top: 2px solid rgba(255, 255, 255, 0.08);padding-top: 20px;}

.horse_updates_heading h5{float: left;color: var(--light-white);font-size: 20px;font-weight: 600;line-height: 30px;}

.horse_updates_container .archive_link{float: right;border-radius: 20px;background: #1E1E1E;padding: 6px 12px;display: flex;
align-items: center;text-decoration: none;}

.horse_updates_container .archive_link svg{margin-right: 8px;}

.horse_updates_container .archive_link span{color: var(--light-white);font-size: 14px;font-weight: 600;line-height: 20px;}

.horse_updates_container .card_content{margin-top: 24px;width: 100%;float: left;padding: 0px;}

.stable_updates_container{margin-bottom: 16px;}

.stable_updates_container .card_content{margin-top: 24px;}

.trainer_details{margin-bottom: 24px;}

.trainer_about_video{padding: 0px;overflow: hidden;margin-bottom: 24px;border-radius: 8px;}

.trainer_update_video{width: calc(100% + 32px);height: 466px;float: left;margin-left: -16px;margin-bottom: 16px;position: relative;
    border-radius: 0px;border: none;}

.trainer_about_video .video_wrapper img{width: 100%;height: 100%;object-fit: cover;}

.trainer_about_video .img_wrap{width: 100%;margin: 0px;}

.trainer_about_text{padding: 16px;border-radius: 8px;background: rgba(255, 255, 255, 0.05);}

.trainer_about_text h5{color: var(--light-white);font-size: 16px;font-weight: 700;line-height: 24px;margin-bottom: 6px;}

.trainer_about_text p{color: var(--light-text);font-size: 14px;font-weight: 500;line-height: 20px;margin-bottom: 16px;}

.trainer_about_text p:last-child{margin-bottom: 0px;}

.all_posts_btn{margin-top: 32px;display: flex;align-items: center;justify-content: center;}

.all_posts_btn a{display: inline-flex;padding: 6px 15px;justify-content: center;align-items: center;gap: 8px;text-decoration: none;
    border-radius: 20px;background: #1E1E1E;color: var(--light-white);font-size: 14px;font-weight: 600;line-height: 20px;}

.horse_details h2{color: #fff;margin-bottom: 5px;font-weight: 500;font-size: 20px;line-height: 30px;}

.horse_details h6{font-weight: 500;font-size: 14px;color: rgba(255, 255, 255, 0.45);}

.horse_details h6 img{float: left;margin-right: 7px;}

.horse_details h6 span{float: left;line-height: 16px;}

.horse_rating{float: right;height: 28px;padding: 4px 6px;margin-top: -34px;border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 100px;opacity: 0.95;}

.horse_rating .rising_info{color: #F37020;font-weight: 500;font-size: 14px;line-height: 20px;}

.horse_rating .rising_info img{width: auto;height: auto;float: none;padding: 0px 4px 0px 4px;}

.loc_gender_info{margin-top: 12px;border-bottom: 2px solid rgba(255, 255, 255, 0.08);padding-bottom: 12px;}

.loc_gender_info .loc_info{margin-right: 20px;}

.loc_gender_info .loc_info span{font-weight: 500;font-size: 14px;}

.gender_info{float: right;}

.gender_info img{float: left;margin-right: 5px;}

.gender_info span{float: left;color: #fff;font-weight: 500;font-size: 14px;line-height: 16px;}

.runs_info{margin: 24px 0px;background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(255, 255, 255, 0.15);
border-radius: 8px;}

.runs_info ul{list-style: none;height: 100%;padding: 8px 16px;}

.runs_info ul li{width: 33.3%;float: left;text-align: center;border-right: 1px solid rgba(255, 255, 255, 0.15);}

.runs_info ul li:last-child{border-right: none;}

.runs_info ul li span{width: 100%;float: left;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.runs_info ul li strong{font-size: 36px;line-height: 44px;font-weight: normal;color: #fff;}

.places_info ul{list-style: none;}

.places_info ul li{float: left;margin-right: 8px;font-weight: 500;font-size: 14px;line-height: 24px;color: rgba(255, 255, 255, 0.45);}

.places_info ul li span{height: 24px;float: left;color: #fff;text-align: center;line-height: 22px;padding: 0px 8px;border-radius: 100px;
    background: rgba(255, 255, 255, 0.08);}

.places_info ul li:last-child{margin-right: 0px;}

/* .places_info ul li span.first_pos{background: #22B074;}

.places_info ul li span.secound_pos{background: #0086C9;}

.places_info ul li span.third_pos{background: #71300B;}

.places_info ul li span.run_pos{background: #7C7C7C;}

.places_info ul li span.dnf_pos{background: #D92D20;}

.places_info ul li span.pos_1st,.places_info ul li span.pos_1ST{background: #22B074;}

.places_info ul li span.pos_2nd,.places_info ul li span.pos_2ND{background: #0086C9;}

.places_info ul li span.pos_3rd,.places_info ul li span.pos_3RD{background: #71300B;}

.places_info ul li span.pos_run,.places_info ul li span.pos_RUN{background: #7C7C7C;}

.places_info ul li span.pos_dnf,.places_info ul li span.pos_DNF{background: #D92D20;}

.places_info ul li span.pos_NA,.places_info ul li span.pos_na{background: rgba(255, 255, 255, 0.08);} */

.nextrace_info{margin-top: 24px;}

.nextrace_info p{float: left;color: #fff;font-weight: 400;font-size: 14px;line-height: 29px;}

.entry_info{float: right;border: 1px solid rgba(255, 255, 255, 0.15);padding: 4px 8px;text-align: center;border-radius: 100px;}

.entry_info span{width: 12px;height: 12px;background: #F37020;display: inline-block;border-radius: 50%;margin-right: 5px;}

.entry_info strong{color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;}

.stable_updates_content{margin-left: -10px;}

.stable_updates_content .card{width: 50%;margin: 0px 10px 24px 10px;border: 1px solid rgba(255, 255, 255, 0.1);}

.stable_updates_content .card .card_heading{padding: 15px 15px;}

.trainer_img{float: left;width: 140px;height: 140px;overflow: hidden;}

.trainer_img img{width: 100%;height: 100%;object-fit: cover;border-radius: 8px;}

.trainer_info{float: left;margin-left: 16px;}

.trainer_info h4{color: #fff;font-weight: 500;font-size: 20px;line-height: 30px;margin-bottom: 4px;}

.trainer_info .loc_info{width: 100%;margin-bottom: 16px;}

.trainer_info .loc_info span{line-height: 16px;font-size: 14px;color: var(--light-text);}

.website_info{float: left;height: 30px;padding: 4px 8px;border: 1px solid rgba(255, 255, 255, 0.15);border-radius: 8px;}

.website_info img{float: left;margin-top: 2px;margin-right: 5px;}

.website_info span{float: left;font-weight: 500;font-size: 14px;color: #fff;line-height: 20px;}

.runs_info.postion_info li{width: 50%;}

.trainer_stats_info ul{list-style: none;}

.trainer_stats_info ul li{width: 100%;float: left;padding: 8px 0px;border-bottom: 1px solid rgba(244, 243, 232, 0.15);}

.trainer_stats_info ul li span,.trainer_stats_info ul li strong{color: #fff;font-weight: 400;font-size: 14px;line-height: 20px;}

.trainer_stats_info ul li span{float: left;}

.trainer_stats_info ul li strong{float: right;font-weight: normal;}

/* .trainer_video_info{margin-top: 24px;} */

.pedigree_details_container{background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(244, 243, 232, 0.15);
    backdrop-filter: blur(30px);padding: 16px 8px 8px 8px;border-radius: 8px;}

.pedigree_details_content{width: 300px;margin: 0px auto;}

.view_full_pedigree{margin-top: 24px;}

.main_horse img{display: inline-block;margin-bottom: 20px;border-radius: 50%;}

.main_horse span{display: block;color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;}

.first_tree_img{padding: 8px 0px;}

.name_info{width: 50%;float: left;text-align: center;}

.name_info span,.name_info strong{width: 100%;float: left;color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;}

.second_tree_img{width: 50%;float: left;padding: 8px 0px;}

.grand_parent_info{position: relative;min-height: 91px;}

.grand_parent_info span{font-weight: 500;font-size: 10px;line-height: 18px;color: #fff;position: absolute;left: 0px;
    bottom: 0px;transform: rotate(90deg);margin-left: -77px;height: 91px;width: 91px;}

.grand_parent_info span:nth-child(2){left: 106px;}

.grand_parent_info span:nth-child(3){left: 138px;}

.grand_parent_info span:nth-child(4){left: 245px;}

.view_pedigree{width: 100%;height: 56px;color: #fff;font-size: 16px;font-weight: 400;background: #F37020;border: 1px solid rgba(255, 255, 255, 0.15);
border-radius: 8px;}

/* end of stable styles */

/* start of racing data styles */

.racing_data_wrapper{padding-top: 24px;}

.racing_data_tabs_container .nav-tabs .nav-item{width: 100%;}

.racing_data_tabs_container .nav-tabs{background: none;border: 1px solid rgba(244, 243, 232, 0.15);border-radius: 100px;}

.racing_data_tabs_container .nav-tabs .nav-link.active,.racing_data_tabs_container .nav-tabs .nav-item.show .nav-link{
    background: none;color: #F37020;filter: drop-shadow(0px 0px 30px #F37020);transition: .3s all;}

.racing_data_tabs_container .nav-tabs .nav-link{position: relative;}

.racing_data_tabs_container .nav-tabs .nav-link.active::after,.racing_data_tabs_container .nav-tabs .nav-item.show .nav-link::after{
    content: '';width: 74px;height: 2px;background: #F37020;position: absolute;left: 50%;bottom: 5px;transform: translateX(-50%);}

.tabs_content{padding: 64px 0px 0px 0px;}

.tabs_content ul{list-style: none;}

.tabs_content ul li{width: 100%;float: left;border-bottom: 1px solid rgba(255, 255, 255, 0.25);margin-bottom: 16px;padding-bottom: 16px;}

.nexttogo_content ul li:first-child{background: rgba(255, 255, 255, 0.05);border-radius: 12px;padding: 12px;
border-bottom: none;}

.venue_name{float: left;}

.venue_name img{float: left;margin-right: 8px;}

.venue_name span{float: left;font-size: 16px;line-height: 20px;color: #fff;}

.race_time{float: right;}

.race_time span{float: left;font-size: 12px;line-height: 26px;color: #fff;padding-right: 5px;font-weight: 500;}

.race_time strong{float: left;height: 26px;background: #475569;text-align: center;color: #fff;line-height: 26px;padding: 0px 8px;
    font-size: 12px;font-weight: normal;border-radius: 100px;}

.race_time strong.active{background: #F37020;}

.race_info p{font-size: 12px;line-height: 18px;float: left;color: rgba(255, 255, 255, 0.45);font-weight: 500;}

.race_info p span{float: left;margin-right: 5px;}

.race_info p span.circle_icon{width: 4px;height: 4px;background: rgba(255, 255, 255, 0.5);border-radius: 50%;margin-top: 8px;}

.full_calender_wrap{padding: 16px;border-top: 1px solid rgba(255, 255, 255, 0.25);}

.full_calender_wrap a{height: 56px;width: 100%;float: left;background: #121212;
    border: 1px solid rgba(255, 255, 255, 0.15);color: #fff;font-weight: 400;font-size: 16px;line-height: 54px;text-align: center;
    text-decoration: none;border-radius: 8px;}

.full_calender_wrap button{height: 56px;width: 100%;float: left;background: linear-gradient(180deg, #101419 0%, #131820 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);color: #fff;font-weight: 400;font-size: 16px;line-height: 24px;text-align: center;}

.goto_race_video{padding-top: 12px;}

.goto_race_video button{width: 100%;float: left;border: 1px solid rgba(244, 243, 232, 0.15);background: none;height: 32px;
    color: #fff;display: flex;justify-content: center;align-items: center;border-radius: 8px;}

.goto_race_video button img{margin-right: 4px;}

.goto_race_video button span{font-weight: 500;font-size: 12px;}

/* end of racing data styles */

/* start of full race calendar styles */

.race_calendar_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;}

.race_calendar_wrapper h3{font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;}

.race_calendar_wrapper h3 span{font-weight: 400;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);margin-left: 8px;}

.todays_races_wrapper{padding-top: 16px;padding-bottom: 24px;border-top: 1px solid #222;margin-top: 24px;}

.todays_races_content{margin-bottom: 16px;}

.venue_name_info{float: left;display: flex;align-items: center;}

.venue_name_info img{float: left;margin-right: 8px;}

.venue_name_info span{float: left;color: var(--light-white);font-size: 16px;font-weight: 500;line-height: 24px;}

.total_races_info{float: right;}

.total_races_info span{color: #7D7D7D;font-family: var(--inter-font);font-size: 14px;font-weight: 500;line-height: 20px;}

.todays_races_wrapper ul{list-style: none;display: flex;gap: 12px;    padding-bottom: 16px;border-bottom: 1px solid #222;
    margin-bottom: 16px;overflow: auto;}

.todays_races_wrapper ul li{min-width: 90px;float: left;position: relative;border-radius: 6px;border: 1px solid #343432;
    background: #121212;padding: 4px 14px 8px 14px;}

.todays_races_wrapper ul li h6{color: #7D7D7D;text-align: center;font-size: 16px;font-weight: 600;line-height: 24px;}

.todays_races_wrapper ul li a{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;color: #060F1F;}

.todays_races_wrapper ul li .venue_info{float: right;}

.todays_races_wrapper ul li .venue_info img{float: left;border-radius: 2px;margin-right: 8px;}

.todays_races_wrapper ul li .venue_info span{float: left;font-weight: 600;font-size: 14px;line-height: 16px;color: #fff;}

.todays_races_wrapper ul li .result_info{text-align: center;}

.todays_races_wrapper ul li .result_info span{color: #7D7D7D;font-size: 12px;font-weight: 500;line-height: 18px;}

.todays_races_wrapper ul li .result_info small{float: left;font-weight: 600;font-size: 14px;
    line-height: 20px;color: #fff;}

.todays_races_wrapper ul li .result_info span.second_pos{color: #A15C07;}

.todays_races_wrapper ul li .result_info span.third_pos{color: #98A2B3;}

.todays_races_wrapper ul li .result_info span.fourth_pos{color: #F99D2A;}

.todays_races_wrapper ul li .result_info img{float: left;margin-left: 5px;margin-top: 1px;}

/* end of full race calendar styles */

/* start of race results styles */

.race_venue_heading{float: left;}

.heading .race_venue_heading img{display: inline-block;height: auto;margin: 13px 10px 0px 0px;}

.race_venue_heading span{color: #fff;font-weight: 400;font-size: 30px;line-height: 38px;font-family: var(--playfair-font);}

.race_date_info{float: right;}

.race_date_info span{color: var(--light-text);font-size: 18px;font-weight: 500;line-height: 38px;}

.race_results_wrapper{padding-top: 24px;border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;}

.total_races_wrapper{margin-bottom: 24px;}

.total_races_wrapper ul{list-style: none;display: flex;overflow: auto;gap: 12px;padding-bottom: 10px;}

.total_races_wrapper ul::-webkit-scrollbar {height: 10px;}

.todays_races_wrapper ul::-webkit-scrollbar {height: 10px;}

.total_races_wrapper ul a{color: #7D7D7D;}

.total_races_wrapper ul a:hover{ text-decoration: none; color: #7D7D7D;}

.total_races_wrapper ul li.active{border: 1px solid #824119;background: #121212;}

.total_races_wrapper ul li{display: flex;padding: 4px 14px 8px 14px;flex-direction: column;align-items: center;
    border-radius: 6px;border: 1px solid #343432;background: #121212;min-width: 90px;}

.total_races_wrapper ul li strong{color: #7D7D7D;text-align: center;font-size: 16px;font-weight: 600;line-height: 24px;}

.total_races_wrapper ul li span{color: #7D7D7D;text-align: center;font-size: 12px;font-weight: 500;line-height: 18px;}

.total_races_wrapper ul li.active strong,.total_races_wrapper ul li.active span{color: #F37020;}

.racename_info_content{margin-bottom: 24px;position: relative;}

.racename_info_content h5{color: #FFF;font-size: 16px;font-weight: 600;line-height: 30px;text-transform: capitalize;}

.racename_info_content p{color: var(--light-text);font-size: 14px;font-weight: 500;line-height: 20px;}

.racename_info_content .live_race_text{position: absolute;right: 0px;top: 0px;display: flex;align-items: center;
    justify-content: center;height: 50px;animation: blink 1s linear infinite;}

.racename_info_content .live_race_text span{width: 10px;height: 10px;background: #F37020;border-radius: 50%;float: left;
    margin-right: 6px;}

.racename_info_content .live_race_text strong{color: #F37020;font-size: 14px;font-weight: 600;line-height: 20px;}

@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

@-webkit-keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

.racevideo_wrapper{margin-bottom: 24px;}

.racevideo_container{position: relative;height: 430px;border-radius: 12px;background: rgba(255, 255, 255, 0.05);
overflow: hidden; cursor: pointer;}

.racevideo_container .videoview iframe{
    height:435px;
}

.racevideo_container img{width: 100%;height: 100%;object-fit: cover;}

.racevideo_container .replay_btn{display: inline-flex;padding: 4px 10px 4px 4px;justify-content: center;align-items: center;
    gap: 4px;border-radius: 8px;border: 2px solid rgba(255, 255, 255, 0.40);background: rgba(0, 0, 0, 0.90);
    box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.80);position: absolute;left: 50%;top: 50%;
    transform: translate(-50%, -50%);}

.racevideo_container .replay_btn span{color: var(--light-white);font-size: 12px;font-weight: 600;line-height: 18px;}

.race_results_content{background: rgba(255, 255, 255, 0.05);border-radius: 12px;padding: 12px;}

.race_results_content h5{font-weight: 600;font-size: 16px;line-height: 24px;color: #fff;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.25); */
    padding-bottom: 8px; text-transform: uppercase;}

.race_results_content ul{list-style: none;margin: 12px 0px 0px 0px; float: left; width: 100%;}

.race_results_content ul li{width: 100%;float: left;padding: 12px 0px;border-bottom: 1px solid rgba(255, 255, 255, 0.25);}

.race_results_content ul li:last-child{ border-bottom: 0px;}

.race_results_content ul li .position{width: 45px;float: left;font-weight: 600;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.race_results_content ul li .horse_no{float: left;background: #0086C9;width: 48px;height: 24px;color: #fff;text-align: center;
    line-height: 24px;font-weight: 500;font-size: 14px;margin-right: 8px;border-radius: 100px;}

.race_results_content ul li .horse_name{float: left;color: #fff;font-weight: 600;font-size: 14px;line-height: 24px;}

.race_card_content{margin-top: 24px;background: rgba(255, 255, 255, 0.05);border-radius: 12px;padding: 12px 12px 0px 12px;
margin-bottom: 24px;}

.race_card_content h5{font-weight: 500;font-size: 16px;line-height: 24px;color: #fff;border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    padding-bottom: 8px; text-transform: uppercase;}

.race_card_content ul{margin-top: 32px;list-style: none;}

.race_card_content ul li{margin-bottom: 24px;width: 100%;float: left;}

.jockeysuit_info{width: 35px;float: left;margin-right: 16px;border: 1px solid #7F56D9;text-align: center;padding-bottom: 5px;
border-radius: 4px;}

.race_card_content ul li:nth-child(2) .jockeysuit_info {border-color: #F79009;}

.race_card_content ul li:nth-child(2) .jockeysuit_info span{background: #F79009;}

.race_card_content ul li:nth-child(3) .jockeysuit_info {border-color: #12B76A;}

.race_card_content ul li:nth-child(3) .jockeysuit_info span{background: #12B76A;}

.race_card_content ul li:nth-child(4) .jockeysuit_info {border-color: #06AED4;}

.race_card_content ul li:nth-child(4) .jockeysuit_info span{background: #06AED4;}

.jockeysuit_info span{width: 100%;float: left;height: 20px;background: #7F56D9;text-align: center;color: #fff;
    line-height: 20px;font-size: 14px;margin-bottom: 6px;}

.jockeysuit_info img{margin-bottom: 6px;}

.jockeysuit_info small{width: 100%;float: left;color: #fff;font-weight: 400;font-size: 14px;}

.horse_riding_info{float: left;width: calc(100% - 300px);}

.horse_riding_info h6{width: 100%;float: left;color: #fff;font-weight: 500;font-size: 16px;line-height: 24px;
    margin-bottom: 4px;}

.horse_riding_info span{width: 100%;float: left;margin-bottom: 4px;}

.horse_riding_info span strong{float: left;color: #fff;font-weight: 500;font-size: 14px;line-height: 20px;width: 15px;}

.horse_riding_info span small{float: left;font-weight: 400;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.horse_age_info{float: right;}

.horse_age_info span{float: left;font-weight: 500;font-size: 14px;line-height: 20px;color: rgba(255, 255, 255, 0.45);}

.horse_age_info small.cirlce{width: 4px;height: 4px;background: rgba(255, 255, 255, 0.45);float: left;margin: 8px 8px;
    border-radius: 50%;}

/* end of race results styles */

/* start of partners styles */

.partners_wrapper{border-top: 1px solid rgba(255, 255, 255, 0.15);margin-top: 16px;padding-top: 40px;}

.partners_container h5{font-size: 20px;line-height: 30px;font-weight: 500;color: #fff;margin-bottom: 16px;}

.partners_container ul{list-style: none;width: calc(100% + 16px);margin-bottom: 16px;}

.partners_container ul li{width: 33.3%;float: left;padding: 0px 16px 16px 0px;}

.partners_container ul li .partner_logo_wrapper{width: 100%;float: left;border: 1px solid rgba(255, 255, 255, 0.25);
    height: 163px;font-size: 16px;line-height: 24px;color: #fff;display: flex;justify-content: center;align-items: center;
border-radius: 8px;}

.partners_container ul li .partner_logo_wrapper a{width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;}

.partners_card_wrapper{padding: 24px 0px;}

.partners_card_wrapper h3{color: var(--light-white);font-size: 20px;font-weight: 600;line-height: 30px;margin-bottom: 16px;}

.partner_list_content{margin-bottom: 24px;position: relative;}

.partner_list_content img{width: 100%;height: 215px;margin-bottom: 8px;}

.partner_list_content h5{color: var(--light-white);font-size: 18px;font-weight: 700;line-height: 28px;}

.partner_list_content p{color: rgba(255, 255, 255, 0.60);font-size: 14px;font-weight: 500;line-height: 20px;}

.partner_list_content a{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;}

.event_short_info h6{color: var(--light-white);font-family: var(--inter-font);font-size: 16px;
    font-weight: 600;line-height: 22px;}

.partner_full_info{padding-top: 24px;}

.partner_heading_info{padding-bottom: 32px;}

.partner_full_info h5{color: var(--light-white);font-size: 14px;font-weight: 700;line-height: 20px;margin-bottom: 10px;}

.partner_full_info ul{margin-left: 16px;}

.partner_full_info ul li{color: var(--light-text);font-size: 14px;font-weight: 500;line-height: 20px;margin-bottom: 16px;}

.partner_email_info{margin-top: 8px;padding: 16px;border-radius: 8px;border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(18, 18, 18, 0.90);backdrop-filter: blur(20px);}

.partner_email_info label{display: block;}

.partner_email_info label span{color: var(--light-text);font-size: 13px;font-weight: 600;line-height: 16px;display: block;}

.partner_email_info label strong{color: var(--light-text);font-size: 13px;font-weight: 600;line-height: 16px;}

.partner_email_info .copy_icon{float: right;margin-top: -28px;background: none;border: none;}

.partner_email_info .copy_icon svg path{fill: white;fill-opacity:0.45;}

.partner_email_info .copy_icon.active svg path{fill: #F37020;fill-opacity:1;}

.partner_heading_info .partner_link{float: right;margin-top: -55px;}

.partners_details_container  .blog_carousel{min-height: 448px;}

.partners_details_container .event_short_info{margin-top: 16px;}

/* end of partners styles */

/* start of calender css  */

.video-js .vjs-tech{     cursor: pointer;}

.datepicker_container .react-calendar{background-color: #F37020 !important; }

.schedule_time_wrapper .react-calendar{width: 480px;margin-bottom: 16px;width: 480px;
    background: linear-gradient(180deg, #121212 0%, #1A1A1A 100%);border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 24px 24px 10px 24px;border-radius: 8px;}

 .react-calendar__navigation {height: 32px;margin-bottom: 8px;}

.react-calendar__navigation__label{color: #fff;min-width: initial;}

.react-calendar__navigation button{ color: #fff; font-size: 16px;}

.schedule_time_wrapper  .react-calendar__month-view__weekdays{background: rgba(255, 255, 255, 0.05);color: rgba(255, 255, 255, 0.45);
    font-size: 16px;font-weight: 400;margin-bottom: 8px;border-radius: 8px;}

.react-calendar__month-view__weekdays__weekday {padding: 16px 8px !important;}

.schedule_time_wrapper abbr[title], .schedule_time_wrapper dfn[title] {text-decoration: none;border-bottom: none;
    text-transform: capitalize;cursor: auto;}

.schedule_time_wrapper .react-calendar__tile{padding: 12px 6px;color: #fff;border-radius: 4px;border: 1px solid transparent;}

.schedule_time_wrapper.regular_events_calendar .react-calendar__tile{margin-bottom: 5px;padding: 8px 4px;pointer-events: none;}

.schedule_time_wrapper.regular_events_calendar .react-calendar__tile--active {
    background-color: transparent;}

.schedule_time_wrapper.regular_events_calendar .react-calendar__tile:enabled:hover, 
.schedule_time_wrapper.regular_events_calendar .react-calendar__tile:enabled:focus {
    background-color: transparent;}

.schedule_time_wrapper.regular_events_calendar .react-calendar{padding: 20px 20px 10px 20px;}

.regular_events_calendar .react-calendar__navigation__label{pointer-events: none;}

.calendar_nav_btns{position: absolute;top: 22px;left: 0px;height: 32px;}

.calendar_nav_btns button{width: 8px;height: 32px;position: absolute;background: none;top: 0px;left: 0px;
text-indent: -1000000px;border: none;}

.calendar_nav_btns button.cal_prev_btn{left: 22px;}

.calendar_nav_btns button.cal_next_btn{right: 22px;left: auto;}

.schedule_time_wrapper .react-calendar__tile.highlight{border: 1px solid #fff;}

.schedule_time_wrapper  .react-calendar__tile--active{background-color: #F37020;}

.schedule_time_wrapper .react-calendar__tile:enabled:hover,  .schedule_time_wrapper .react-calendar__tile:enabled:focus{
    background-color: #F37020;}

.schedule_time_wrapper .react-calendar__tile--now{ background: #4a4a4a;}

.schedule_time_wrapper .react-calendar__navigation__prev2-button{ display: none;}

.schedule_time_wrapper .react-calendar__navigation__next2-button{ display: none;}

.schedule_time_wrapper .react-calendar__navigation__arrow.react-calendar__navigation__next-button{ font-size: 24px; color: #fff;
    min-width: initial}

.schedule_time_wrapper .react-calendar__navigation__arrow.react-calendar__navigation__prev-button{font-size: 24px; color: #fff;
    min-width: initial}

.schedule_time_wrapper .react-calendar__navigation{ height: 32px; margin-bottom: 8px;}

.schedule_time_wrapper .react-calendar__navigation button:enabled:hover,  .schedule_time_wrapper .react-calendar__navigation button:enabled:focus{
    background: transparent !important;}

.schedule_time_wrapper .react-calendar__navigation button:disabled{background: transparent !important;}

.schedule_time_wrapper .react-calendar__year-view .react-calendar__tile, .schedule_time_wrapper .react-calendar__decade-view .react-calendar__tile, .schedule_time_wrapper .react-calendar__century-view .react-calendar__tile{
    padding: 1em 0.5em;}

.video-js .vjs-tech{cursor: pointer;}

.video-js{cursor: pointer;}

.player_one-dimensions.vjs-fluid{cursor: pointer;}

.calenderview{ height: auto; min-height:auto; padding-bottom: 30px;;}

.messageview{display: flex;align-items: center;justify-content: center;padding-top: 25%;font-size: 16px;color: #fff;}

.pointerevents{ pointer-events: none;}

.editicon{ position: absolute; right: 10px; top: 37px;}

.editicon img{ width: 18px;}

/* end of calender css  */

/*  Resolutions above 1200px  */

@media (min-width: 1200px) {

.hide_desk{display: none!important;}

.left_menu{min-height: 300px;}

.login_horse_img{min-height: 550px;}

.login_right_container .alert{width: 310px;}

.event_img{height: 480px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;}

.blog_img{height: 480px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;}

.blog_img img{width: 100%;height: 100%;object-fit: cover;}

}

/*  Resolutions below 1200px  */

@media (min-width: 992px) and (max-width: 1199px) {

.hide_desk{display: none!important;}

.left_container{width: 280px;}

.left_logo img{width: 100%;height: auto;}

.right_container{padding: 40px 30px 0px 30px;width: calc(100% - 280px);margin-left: 280px;}

.right_content{width: 100%;}

.search_field_wrapper .search_input{width: calc(100% - 94px);}

.search_field_wrapper .dropdown{padding-left: 10px;}

.search_field_wrapper .dropdown .dropdown-toggle{width: 100%;}

.search_field_wrapper .dropdown .dropdown-toggle span{padding-right: 5px;}

.blog_wrapper {padding-top: 0px;}

.blog_list{padding-bottom: 16px;}

.blog_img img{width: 100%;height: auto;}

}

/*  Resolutions below 991px  */

@media (max-width: 991px) {

.hide_mobile{display: none!important;}

.show_mobile{display: block!important;}

.left_container{width: 100%;min-height: 63px;bottom: 0px;height: 63px;top: auto;padding: 0px;border-top: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(18, 18, 18, 0.9);backdrop-filter: blur(20px);border-radius: 12px 12px 0px 0px;}

.left_logo{display: none;}

.left_menu ul li{width: 20%;margin: 0px;}

.left_menu ul li a{display: block;text-align: center;font-size: 12px;line-height: 18px;padding: 10px 6px;height: 64px;
transition: .3s all;position: relative;}

.left_menu ul li a img{float: none;margin-right: 0px;display: inline-block;width: 24px;}

.left_menu ul li a svg{float: none;margin-right: 0px;display: inline-block;margin-bottom: 3px;}

.left_menu ul li a span{display: inline-block;float: none;width: 100%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

.left_menu ul li a.active{background: none;color: #F37020;}

.left_menu ul li a.active svg{filter: drop-shadow(0px 0px 30px #F37020);}

.left_menu ul li a.active svg path{fill: #F37020;}

.left_menu ul li a small{width: 8px;height: 8px;background: #F04438;border-radius: 50%;position: absolute;top: 12px;right: 25px;}

.right_container{width: 100%;margin-left: 0px;padding: 0px 16px 0px 16px;}

.right_container.raceresult{padding: 0px 0px 0px 0px;}

.notification_container{z-index: 2;padding: 0px;min-height: calc(100vh - 64px);}

.profile_container{z-index: 2;min-height: initial;height: 100vh;}

.profile_container .alert{width: calc(100% - 32px);left: 0px;margin: 0px 16px;top: 12px;}

.right_content{width: 100%;float: left;}

.right_content .heading{border-bottom: 1px solid rgba(255, 255, 255, 0.15);width: calc(100% + 32px);margin-left: -16px;
    padding-left: 16px;padding-top: 16px;padding-bottom: 16px;}

.right_container.raceresult .heading{margin-left:0px; padding-right: 16px;  width: 100%; }

.raceresult .race_calendar_wrapper h3{padding-left: 16px; padding-right: 16px;}

.raceresult .main_tabs_container .nav-tabs{margin-left: 16px; margin-right: 16px; width: calc(100% - 32px);}

.raceresult .todays_races_content{padding-left: 16px; padding-right: 16px;}

.raceresult .todays_races_wrapper ul{ padding-left: 16px; padding-right: 16px}

.right_container.raceresult .racename_info_content{padding-left: 16px; padding-right: 16px;}

.right_container.raceresult .racevideo_wrapper{padding-left: 16px; padding-right: 16px;margin-bottom: 12px;}

.right_container.raceresult .race_results_content{margin-left: 16px; margin-right: 16px; width: calc(100% - 32px);}

.right_container.raceresult .race_card_content{margin-left: 16px; margin-right: 16px; width: calc(100% - 32px);}

.right_container.raceresult .total_races_wrapper ul{ padding-left: 16px; padding-right: 16px;}

.notification_container .right_content .heading{width: 100%;margin-left: 0px;padding-right: 16px;}

.more_info_dropdown .dropdown-menu{transform: translate3d(0px, 48px, 0px)!important;}

.right_content .heading h1{font-size: 24px;line-height: 32px;font-weight: 500;}

.right_content .heading .back_link img{height: 24px;width: 24px;margin-top: 5px;margin-right: 8px;}

.right_content .heading .back_home{margin-top: 6px;}

.race_venue_heading span{font-size: 18px;line-height: 32px;font-weight: 500;}

.heading .race_venue_heading img{margin: 10px 10px 0px 0px;}

.race_date_info span{font-size: 16px;line-height: 36px;padding-right: 0px;}

.right_content .top_nav_links{float: right;margin-right: 16px;margin-top: 3px;}

.right_content .top_nav_links a{display: inline-block;width: 42px;text-align: center;}

.right_content .top_nav_links a svg path{fill: #fff;fill-opacity: 1;}

.right_content .top_nav_links a:last-child{display: none;}

.search_field_wrapper{width: calc(100% + 32px);margin-left: -16px;padding: 16px 16px;}

.home_tabs_container .nav-tabs .nav-item{padding: 4px;}

.home_tabs_container .nav-tabs .nav-link{padding: 8px 0px;}

.search_field_wrapper .search_input{width: calc(100% - 94px);}

.search_field_wrapper .dropdown{padding-left: 10px;}

.search_field_wrapper .dropdown .dropdown-toggle{width: 100%;}

.search_field_wrapper .dropdown .dropdown-toggle span{padding-right: 5px;}

.search_field_wrapper .dropdown-menu{right: -73px;left: 0px!important;transform: translate3d(-67px,40px,0px)!important;}

.blog_wrapper{margin-top: 16px;padding-top: 0px;padding-bottom: 0px;overflow-y: auto;max-height: calc(100vh - 209px);
overflow-x: hidden;}

.hidden_scrollbar{-ms-overflow-style: none;scrollbar-width: none;}

.hidden_scrollbar::-webkit-scrollbar{display: none;}

.blog_wrapper,.racing_tab_wrapper,.notification_wrapper,.profile_wrapper,.join_platinum_wrapper {
    -ms-overflow-style: none; scrollbar-width: none;}

.blog_wrapper::-webkit-scrollbar,.racing_tab_wrapper::-webkit-scrollbar,.notification_wrapper::-webkit-scrollbar,
.profile_wrapper::-webkit-scrollbar,.join_platinum_wrapper::-webkit-scrollbar{ 
    display: none; }

.blog_list_wrapper{padding-top: 0px;}

.blog_list{padding-bottom: 24px;}

.blog_img{margin: 12px 0px;}

.blog_img img{width: 100%;height: auto;}

.short_text{padding-top: 12px;}

.short_text p{display: -webkit-box;max-width: 100%;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;}

.event_list_wrapper{padding-top: 16px;}

.event_list{padding-bottom: 0px;margin-bottom: 24px;}

.giveaway_list .short_text p{-webkit-line-clamp: 4;}

.giveaway_list{padding-bottom: 0px;margin-bottom: 24px;}

.giveaway_heading{padding: 12px;}

.giveaway_list .short_text .enter_draw_link,.giveaway_list .short_text .check_now_link{width: 100%;}

.giveaway_list .short_text{padding: 12px;}

.giveaway_modal .modal-content{padding: 16px;}

.giveaway_results_content .btn-field{margin-top: 0px;position: fixed;left: 0px;bottom: 0px;}

.top_action_links{position: fixed;top: 0px;}

.event_details_container{overflow: auto;height: calc(100vh - 64px);padding-bottom: 90px;}

.event_info{margin-bottom: 24px;padding-top: 0px;}

.event_heading label{font-size: 13px;line-height: 20px;}

.event_heading span{font-size: 14px;line-height: 20px;}

.event_loc_date{padding-top: 12px;margin-top: 10px;}

.event_date_time{width: 100%;margin-bottom: 12px;}

.event_location{width: 100%;}

.event_heading label svg{margin-top: 1px;}

.event_day_enter{width: 114px;margin-right: 32px;}

.event_announcement{width: calc(100% - 146px);}

.racing_tab_wrapper{margin-top: 16px;padding-top: 0px;position: relative;
    overflow-x: hidden;
    /* overflow: auto; */
    max-height: calc(100vh - 230px);}

.racing_tab_wrapper .card_content{margin-bottom: 16px;}

.horse_info_container{padding: 12px;}

.card_head{padding-bottom: 12px;}

.card_subhead{padding-bottom: 12px;margin-bottom: 12px;}

.loc_info img{margin-right: 4px;}

.horse_list_info {height: auto;}

.horse_declared_info{height: auto;}

.horse_pos_info{height: auto;}

.horse_pos_info li.dis_type{width: 50%;}

.race_info_list li{width: 33.3%;}

.race_info_list li.race_trainer_list{width: calc(100% - 140px);margin-right: 0px;padding-right: 0px;}

.race_info_list li.race_time_list{border-right: none;}

.trnr_jcky_info_list li{margin-bottom: 12px;}

.trnr_jcky_info_list li.race_type{width: calc(100% - 104px);}

.trnr_jcky_info_list li.race_num{width: 90px;margin-right: 0px;border-right: none;padding-right: 0px;}

.trnr_jcky_info_list li.dis_type,.trnr_jcky_info_list li.purse_type{width: 31.7%;}

.trnr_jcky_info_list li.no_of_entrs{border-right: none;width: 90px;padding-right: 0px;margin-right: 0px;}

.card_content .like_info{padding: 0px 12px;bottom: 12px;}

.horse_thumb_img{width: 48px;height: 48px;}

.horse_thumb_img img{width: 100%;height: 100%;}

.horse_short_info{height: 48px;}

.horse_text{margin-bottom: 8px;}

.horse_short_info .loc_info svg{margin-right: 4px;}

.video_update_container{padding: 12px;}

.video_update_container .video_wrapper{height: 192px;}

.video_update_container .img_wrap{width: calc(100% + 24px);height: auto;margin-left: -12px;margin-bottom: 8px;}

.trainer_about_video{padding: 0px;}

.video_update_container .video_update_text h4{padding: 12px;}

.trainer_update_video{height: 239px;width: calc(100% + 22px);margin-left: -12px;}

.trainer_about_text h5{font-size: 14px;line-height: 20px;}

.trainer_about_video .img_wrap{width: 100%;margin: 0px;}

.video_update_container .text_info{min-height: 60px;}

.status_update_container .text_info{height: 74px;}

.status_update_container .text_info p{display: -webkit-box;max-width: 100%;-webkit-line-clamp: 3;-webkit-box-orient: vertical;
    overflow: hidden;}

.audio_update_container .text_info{height: auto;}

/* .audio_player_container{height: 54px;} */

.audio_player_container img{width: 100%;height: auto;}

.blog_carousel{width: calc(100% + 32px);margin-left: -16px;}

.stable_carousel{min-height: 300px;}

.stable_carousel.home_feed_carousel{min-height: 280px;}

.stable_carousel .carousel.carousel-slider{min-height: 235px;}

.carousel .slider-wrapper{height: 235px;}

.home_feed_carousel .carousel .slider-wrapper {height: 258px;}

.partners_details_container .carousel .slider-wrapper{height: 220px;}

.stable_carousel .carousel .slide{height: 100%;}

.horse_details h2{font-size: 18px;line-height: 26px;width: calc(100% - 90px);overflow: hidden;
    white-space: nowrap;text-overflow: ellipsis;}

.blog_heading h3{font-size: 24px;line-height: 32px;}

.blog_heading p,.blog_subheading p,.carousel_subheading p,.blog_final_subheading p{font-size: 14px;line-height: 20px;}

.blog_subheading h6,.blog_image_carousel h6,.blog_final_subheading h6{font-size: 14px;line-height: 20px;}

.blog_video_wrapper{margin-top: 56px;}

.blog_video_wrapper iframe{width: 100%;height: 205px;}

.booknow_btn{position: fixed;left: 0px;bottom: 0px;z-index: 100;backdrop-filter: blur(20px);border-radius: 12px 12px 0px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);padding: 16px;}

.favourites_alert{bottom: 100px;left: 50%;transform: translateX(-50%);}

.blog_subheading{padding-bottom: 24px;}

.blog_subheading .content_editor{width: 100%;float: left;padding-bottom: 70px;}

.blog_final_subheading{margin-top: 24px;padding-top: 24px;padding-bottom: 100px;}

.stable_carousel .top_static_action_links{padding-bottom: 0px;}

.stable_carousel .carousel-root{margin-top: 65px;}

.stable_carousel.home_feed_carousel .carousel-root{margin-top: 20px;}

.cal_events_wrapper{padding-top: 0px;overflow: auto;max-height: calc(100vh - 242px);margin-top: 36px;}

.cal_events_list{padding-bottom: 0px;margin-bottom: 44px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;
    border-bottom: none;}

.cal_events_list:last-child{border-bottom: none;padding-bottom: 0px;margin-bottom: 0px;}

.cal_events_list .img_wrap{margin-right: 12px;height: 106px;}

.cal_events_list .img_wrap img{width: 124px;height: 106px;object-fit: cover;object-position: top center;
    border-radius: 8px 0px 0px 8px;}

.cal_events_info{width: calc(100% - 136px);height: 106px;padding: 12px 0px;}

.cal_events_info h5{font-size: 14px;line-height: 20px;margin-bottom: 2px;white-space: nowrap;overflow: hidden;
    text-overflow: ellipsis;}

.cal_events_info p{font-size: 14px;line-height: 20px;display: -webkit-box;max-width: 100%;-webkit-line-clamp: 3;
    -webkit-box-orient: vertical;overflow: hidden;}

.cal_events_bottom{top: -26px;left: -136px;width: auto;}

.cal_event_date_time span{font-size: 14px;line-height: 20px;color: #fff;}

.cal_event_platinum svg{height: 18px;margin-right: 4px;}

.cal_event_platinum span{font-size: 12px;line-height: 18px;}

.event_short_info,.event_full_desc{padding: 8px;}

.event_heading .content_editor p{font-size: 14px;line-height: 20px;}

.notification_wrapper{border-top: none;margin-top: 0px;padding-top: 8px;max-height: calc(100vh - 65px);overflow: auto;}

.notification_wrapper h5{margin-bottom: 8px;font-size: 14px;line-height: 20px;padding-left: 16px;}

.notification_wrapper h5.older_notification{padding-top: 4px;padding-bottom: 8px;}

.notification_list{padding: 8px 8px;margin-bottom: 16px;width: calc(100% - 16px);margin-left: 8px;}

.notification_list .img_wrap{width: 114px;height: 108px;margin-right: 12px;}

.notification_list .img_wrap img{width: 100%;height: 100%;object-fit: cover;border-radius: 8px;}

.notification_text_info{width: calc(100% - 126px);height: 108px;}

.notification_text_info h6,.notification_text_info p{font-size: 14px;line-height: 20px;}

.notification_text_info p{display: -webkit-box;max-width: 100%;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;}

.notification_date_time span{font-size: 12px;line-height: 18px;}

.notification_date_time .circle{margin: 7px 4px 0px 4px;}

.profile_wrapper{padding: 16px 0px 0px 0px;border-top: none;margin-top: 0px;height: calc(100vh - 154px);overflow-x: hidden;
    overflow-y: auto;}

.giveaway_results_wrapper{max-height: calc(100vh - 154px);padding-bottom: 0px;}

.usersettings_details{margin-bottom: 24px;}

.userinfo_details{padding-bottom: 32px;}

.profile_img{width: 80px;height: 80px;}

.profile_img img{width: 100%;height: 100%;object-fit: cover;}

.profile_text h3{margin-bottom: 3px;}

.profile_text h4{margin-bottom: 7px;}

.profile_text .edit_photo{width: 76px;height: 26px;font-size: 12px;line-height: 18px;}

.settings_heading{margin-bottom: 5px;}

.settings_list ul li button{padding: 12px 0px;}

.userlogout_link{margin-top: 0px;position: fixed;bottom: 0px;left: 0px;padding: 16px;border-top: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(18, 18, 18, 0.9);backdrop-filter: blur(20px);border-radius: 12px 12px 0px 0px;}

.profile_content .form_container{width: 100%;}

.profile_content .form_container .btn-field{position: fixed;width: 100%;padding: 16px;bottom: 0px;left: 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);background: rgba(18, 18, 18, 0.9);backdrop-filter: blur(20px);
    border-radius: 12px 12px 0px 0px;}

.favourites_wrapper{height: calc(100vh - 66px);padding-bottom: 0px;}

.favourites_container .search_field_wrapper{padding: 0px 16px 24px 16px;}

.join_platinum_wrapper{border-top: none;padding-top: 16px;overflow-y: auto;overflow-x: hidden;height: calc(100vh - 218px);
    margin-top: 0px;}

.platinum_progress_wrapper .join_platinum_content h3{width: 95%;}

.platinum_video iframe{width: 100%;height: 185px;}

.benefits_wrapper{margin-left: -6px;margin-right: -6px;}

.benefits_wrapper .col-lg-6{width: 50%;padding-left: 6px;padding-right: 6px;}

.benefit_list{min-height: 238px;}

.benefit_list h6{padding: 12px 20px;}

.experience_plans_wrapper ul li{padding: 16px;min-height: initial;margin-bottom: 16px;}

.experience_name{padding-bottom: 16px;margin-bottom: 16px;}

.platinum_btn_wrapper{position: fixed;bottom: 63px;left: 0px;background: #121212;}

.platinum_progress_container ul li{padding: 24px 0px 24px 24px;}

.platinum_btn_wrapper.enter_platinum_btn_wrapper{width: 100%;margin-left: 0px;position: fixed;left: 0px;bottom: 0px;padding: 16px;}

.main_tabs_container .nav-tabs{position: relative;}

.main_tabs_container .nav-tabs::after{content: '';width: calc(100% + 32px);margin-left: -16px;height: 1px;background: rgba(255, 255, 255, 0.15);
    position: absolute;left: 0px;bottom: -16px;}

.racing_tabs_container .nav-tabs,.calender_tabs_container .nav-tabs{position: relative;}

.racing_tabs_container .nav-tabs::after,.calender_tabs_container .nav-tabs::after{content: '';width: calc(100% + 32px);margin-left: -16px;
    height: 1px;background: rgba(255, 255, 255, 0.15);position: absolute;left: 0px;bottom: -16px;}

.platinum_experience_wrapper{max-height: calc(100vh - 210px);}

.platinum-tab-content{padding-top: 32px;}

.platinum_short_info h5{font-size: 14px;line-height: 20px;margin-bottom: 2px;}

.platinum_short_info p{font-size: 14px;line-height: 20px;}

.platinum_action_wrap{margin-top: 10px;}

.platinum_action_wrap .day_text{height: 26px;}

.platinum_action_wrap .day_text small{font-size: 12px;line-height: 18px;}

.platinum_action_wrap .act_btns button svg,.platinum_action_wrap .act_btns button img{width: 24px;height: 24px;}

.racing_concierge_wrapper{max-height: calc(100vh - 128px);overflow: auto;border-top: none;margin-top: 0px;padding-top: 16px;}

.schedule_time_modal .schedule_time_wrapper{margin-top: 0px;}

.call_book_modal .event_close_modal{margin-top: 0px;}

.schedule_time_wrapper{min-height: initial;margin-top: 16px;height: auto;}

.schedule_time_modal .datepicker_container{margin-bottom: 16px;}

.datepicker_container{margin-bottom: 16px;}

.datepicker_container,.call_info_container,.schedule_time_modal .datepicker_container{width: 100%;}

.datepicker_container img{width: 100%;height: auto;}

.schedule_time_wrapper .btn_wrapper{position: fixed;}

.call_info_container svg{width: 24px;height: 24px;margin-right: 8px;margin-bottom: 6px;float: left;}

.call_info_container{display: block;float: left;padding: 12px;}

.call_info_container span{font-size: 14px;line-height: 20px;width: calc(100% - 32px);float: left;}

.horse_purchase_wrapper{max-height: calc(100vh - 84px);padding-bottom: 0px;}

.horse_purchase_container{padding-bottom: 0px;}

.horse_purchase_container .video_wrapper{height: 183px;margin-bottom: 12px;}

.platinum_application_wrapper{max-height: calc(100vh - 64px);padding-bottom: 0px;}

.steps_heading li span{white-space: nowrap;}

.steps_form_container .btn-field{position: fixed;left: 0px;bottom: 0px;}

.steps_form_container .form_fields_wrapper{width: 100%;min-height: calc(100vh - 308px);}

.user_field_wrapper{padding: 12px 0px;}

.user_field{margin-right: 32px;display: flex;flex-direction: column;}

.user_field:last-child{margin-right: 0px;}

.radio_field_wrapper{width: 100%;}

.racing_wrapper{overflow: hidden;}

.live_race_container{width: 100%;height: auto;}

.content_container{padding-top: 10px;padding-bottom: 70px;}

.racing_wrapper,.concierge_wrapper{padding-top: 20px;}

.racing_tabs_container{padding-bottom: 0px;}

.stable_wrapper{padding-top: 6px;overflow-y: auto;overflow-x: hidden;max-height: calc(100vh - 154px);
border-top: none;}

.right_container .carousel-item img{border-radius: 0px;}

.bottom_shadow{display: none;}

.product_wrapper .col-6{-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}

.stable_list .col-6{flex: 0 0 100%;max-width: 100%;}

.stable_list .col-12{margin-bottom: 16px;}

.stable_list .card_container{margin-bottom: 24px;}

.stable_card_content{width: calc(100% - 160px);height: 140px;position: relative;}

.stable_card_content h6{font-size: 14px;line-height: 16px;}

.stable_card_content ul{margin-top: 0px;position: absolute;bottom: 0px;}

.stable_card_content ul li{font-size: 14px;line-height: 24px;}

.stable_card_content ul li span{height: 24px;line-height: 24px;}

.video_wrapper{margin-bottom: 20px;}

.btn_wrap .update_password{width: 100%;}

.schedule-time-modal-dialog{max-width: 100%;}

.calendar_container img{width: 100%;height: auto;}

.schedule_details_wrapper h6{margin: 20px 0px 0px 0px;}

.stable_wrapper.stable_details_wrapper{padding-top: 20px;}

.breadcrumb_wrapper ul{position: relative;top: 4px;}

.breadcrumb_wrapper ul li a, .breadcrumb_wrapper ul li strong{font-size: 14px;line-height: 22px;}

.stable_container{min-height: calc(100vh - 64px);z-index: 2;}

.top_static_action_links{background: #121212;padding: 16px;margin: 0px;z-index: 100;}

.stable_carousel .carousel{margin-top: 66px;}

.stable_tabs_container {margin-top: 16px;padding-bottom: 24px;min-height: calc(100vh - 310px);}

.stable_tabs_container .nav-tabs{margin-bottom: 15px;}

.stable_details_container .horse_details{position: relative;}

.stable_details_container .horse_details .horse_rating{margin-top: 0px;position: absolute;top: 0px;right: 0px;}

.stable_updates_content .card{width: 100%;}

.trainer_info{width: calc(100% - 122px);}

.trainer_img{width: 106px;height: 106px;}

.trainer_video_container{height: auto;background: transparent;}

.trainer_info .loc_info{margin-bottom: 26px;}

.horse_pdf_modal .modal-content{min-height: calc(100vh - 16px);}

.full_pedigree_wrapper iframe{width: 100%;height: calc(100vh - 215px);}

.racing_data_wrapper{padding-top: 46px;}

.racing_data_tabs_container .nav-tabs::after{display: none;}

.tabs_content{padding-top: 0px;overflow: auto;max-height: calc(100vh - 410px);margin-top: 16px;}

.tabs_content ul li:last-child{border-bottom: none;}

.nexttogo_content ul li:first-child{padding: 8px;}

.full_calender_wrap{width: calc(100% + 32px);margin-left: -16px;}

.race_calendar_wrapper{border-top: none;padding-top: 0px;}

.racing_data_tabs_container .nav-tabs .nav-link{padding: 16px 0px;}

.racing_data_tabs_container .nav-tabs .nav-link.active::after, .racing_data_tabs_container .nav-tabs .nav-item.show .nav-link::after{
    bottom: 10px;}

.todays_races_wrapper{overflow: auto;max-height: calc(100vh - 282px);padding-top: 12px;padding-bottom: 0px;margin-top: 12px;}

.race_results_wrapper{padding: 0px;border: none;overflow: auto;max-height: calc(100vh - 160px);}

.racevideo_container{height: 210px;}

.racevideo_container .videoview iframe {height: 215px;}

.racename_info_content .live_race_text{height: 20px;top: auto;bottom: 0px;}

.race_results_content ul{margin: 8px 0px;}

.race_results_content ul li{padding: 8px 0px;}

.race_card_content{margin-top: 20px;}

.race_card_content ul{margin-top: 16px;}

.horse_riding_info{width: calc(100% - 51px);}

.race_card_content ul li{margin-bottom: 16px;position: relative;}

.jockeysuit_info{padding-bottom: 3px;height: 84px;}

.jockeysuit_info span{font-size: 12px;line-height: 18px;margin-bottom: 4px;}

.jockeysuit_info img{margin-bottom: 5px;margin-top: 5px;}

.horse_riding_info h6{font-size: 14px;line-height: 20px;}

.horse_riding_info span.horse_jck_info{margin-top: 20px;}

.jockeysuit_info small{font-size: 12px;}

.horse_riding_info span strong,.horse_riding_info span small{font-size: 12px;line-height: 18px;}

.horse_age_info{position: absolute;left: 50px;top: 24px;}

.horse_age_info span{font-size: 12px;line-height: 18px;}

.horse_age_info small.cirlce{margin: 6px 4px;}

.upgrade_membership_container{height: calc(100vh - 72px);padding-bottom: 0px;}

.partners_wrapper{border-top: none;margin-top: 0px;padding-top: 32px;overflow-y: auto;height: calc(100vh - 128px);
overflow-x: hidden;padding-bottom: 16px;}

.partners_card_wrapper{margin-top: 32px;padding-top: 0px;padding-bottom: 0px;overflow-y: auto;max-height: calc(100vh - 230px);
    overflow-x: hidden;}

.calendar_wrapper{max-height: calc(100vh - 128px);overflow: auto;border-top: none;margin-top: 0px;padding-top: 32px;
padding-bottom: 24px;}

.calendar_events_wrapper{padding-top: 32px;}

.racing_page_wrapper{overflow-y: auto;max-height: calc(100vh - 124px);overflow-x: hidden;}

.next_races_wrapper{border-top: none;margin-top: 0px;}

.arion_racing_wrapper{flex-direction: column;}

.arion_racing_card_content img{width: 100%;height: auto;}

.total_races_wrapper {margin-bottom: 8px;}

.racevideo_container .messageview{text-align: center;font-size: 14px;line-height: 18px;padding: 25% 12px 0px 12px;}

}

/*  Resolutions below 991px  */
@media (min-width: 768px) and (max-width: 991px) {

.horse_list_info{height: 126px;}

.horse_list_info ul{display: flex;}

.trnr_jcky_info_list li.dis_type, .trnr_jcky_info_list li.purse_type{width: 20%;}

.trnr_jcky_info_list li.purse_type,.race_info_list li.race_time_list{border-right: 1px solid rgba(255, 255, 255, 0.25);}

}

/*  Resolutions below 699px  */
@media (min-width: 480px) and (max-width: 767px) {
    

}

/*  Resolutions below 699px  */
@media (min-width: 320px) and (max-width: 580px) {

}

/*  Resolutions below 479px  */
@media (max-width: 479px) {	

.call_book_modal .modal-dialog{max-width: initial;padding: 0px 8px;}

.horse_video_wrapper iframe{width: 100%;height: 190px;}

.horse_video_wrapper video{width: 100%;height: 190px;}

.partners_container ul li{width: 50%;}

.partners_container ul li .partner_logo_wrapper a img{width: 140px;height: auto;}

.datepicker_container .react-calendar{width: 100%;padding: 8px;}

.call_book_modal .modal-content{padding: 24px 16px;}

.datepicker_container .react-calendar__navigation button.react-calendar__navigation__label{font-size: 14px;}

.datepicker_container .react-calendar__month-view__weekdays{font-size: 14px;}

.datepicker_container .react-calendar__month-view__weekdays__weekday{padding: 12px 6px;}

.blog_img{height: 240px;}

.blog_img img{height: 100%;}

.event_img{height: 239px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;}

.event_details_container .blog_carousel{min-height: 260px;}

.partners_details_container .blog_carousel{min-height: 276px;height: 276px;}

.partner_details_carousel .carousel{margin-top: 56px;}

.partner_list_content img{height: 223px;}

}

/*  Resolutions below 400px  */
@media (max-width: 400px) {	

.stable_card_content ul li:last-child{margin-right: 0px;}

.horse_video_wrapper iframe{width: 100%;height: 180px;}

.stable_card_content h5{margin-bottom: 4px;font-size: 14px;line-height: 32px;}

.horse_details_info p{font-size: 12px;}

.blog_video_wrapper iframe{height: 180px;}

.blog_img{height: 216px;}

.blog_img img{height: 100%;}

.event_img{height: 215px;background: rgba(255, 255, 255, 0.05);border-radius: 8px;}

.event_details_container .blog_carousel{min-height: 235px;}

.partners_details_container .blog_carousel{min-height: 275px;height: 275px;}

.partner_list_content img{height: 200px;}

.racename_info_content h5{font-size: 14px;line-height: 28px;}

.racevideo_container{height: 204px;}

.racevideo_container .videoview iframe{height: 204px;}

.racevideo_container .messageview{font-size: 12px;line-height: 16px;}

.stable_carousel.home_feed_carousel{min-height: 255px;}

.stable_carousel .carousel.carousel-slider{min-height: 235px;}

.home_feed_carousel .carousel .slider-wrapper{height: 234px;}

}

/*  Resolutions below 360px  */
@media (max-width: 360px) {	

.stable_list .col-lg-6{padding: 0px 16px 0px 26px;}

.card_container .img_wrap{left: -12px;width: 110px;height: 110px;float: left;margin-right: 10px;margin-top: 14px;}

.stable_card_content{width: calc(100% - 125px);}

.stable_card_content ul li span{width: 43px;}

.stable_card_content h5{font-size: 18px;}

.places_info ul li span {width: 44px;}

.horse_desc_info{padding-right: 5px;margin-right: 5px;}

.trainer_detl_info{width: calc(100% - 100px);}

.stable_tabs_container .nav-tabs .nav-link{padding: .5rem .5rem;}

.stable_carousel .carousel.carousel-slider{min-height: 225px;}

.carousel .slider-wrapper{height: 225px;}

.home_feed_carousel .carousel .slider-wrapper {height: 225px;}

.trnr_jcky_info_list li.purse_type{width: 90px;}

.partners_details_container .blog_carousel{min-height: 265px;height: 265px;}

.partners_details_container .carousel .slider-wrapper {height: 210px;}

.racevideo_container{height: 175px;}

.racevideo_container .videoview iframe{height: 185px;}

}

/*  Resolutions below 320px  */
@media (max-width: 320px) {	

.stable_carousel.home_feed_carousel{min-height: 220px;}

.stable_carousel .carousel.carousel-slider{min-height: 200px;}

.home_feed_carousel .carousel .slider-wrapper{height: 200px;}

}